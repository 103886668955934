.Testimonials-card {
  height: 261px;
}

@media (max-width: 768px) {
  .Testimonials-card {
    flex-shrink: 0;
  }
}

@media (min-width: 769px) and (max-width: 1199px) {
  .Testimonials-card {
    flex-shrink: 0;
  }
}

@media (min-width: 1200px) {
  .Testimonials-card {
    flex-shrink: 0;
  }
}

.Testimonials-image {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 261px;
  width: 273px;
}

@media (min-width: 769px) {
  .Testimonials-image {
    width: 50%;
  }

  .Testimonials-text {
    width: 50%;
  }
}

.Testimonials-cards {
  transition-timing-function: linear;
}

.ATFHeroSection-rating-stars {
  bottom: 0.062rem;
}

.ATFHeroSection-rating-star {
  height: 0.875rem;
  width: 0.875rem;
}
