.QuizApp {
  --fixed-footer-height: 58px;
  --fixed-cta-container-height: 88px;
}

.QuizApp.is-page {
  min-height: calc(100vh - var(--navbar-height));
}

.QuizApp__checkout-wrapper {
  display: flex;
  margin: 56px auto 40px auto;
  padding: 24px 80px 0 80px;
  max-width: 1440px;
  width: 100%;
}

.QuizApp--checkout-with-image {
  position: relative;
  min-height: 615px !important;
  width: 50%;
  box-shadow: 0 15px 30px rgba(59, 71, 104, 0.15);
  background: var(--colour-white);
  border-radius: var(--border-radius-card-large) 0 0
    var(--border-radius-card-large);
  padding-top: 16px;
}

.QuizApp__background {
  position: relative;
  width: 50%;
  background-position-x: 100%;
  background-size: cover;
  box-shadow: 15px 15px 30px rgb(59 71 104 / 15%);
  border-radius: 0 var(--border-radius-card-large)
    var(--border-radius-card-large) 0;
}

.QuizApp__background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  z-index: 100;
  height: 100%;
  width: 50%;
  background: linear-gradient(90deg, #3b4768 5.05%, rgba(59, 71, 104, 0) 100%);
  mix-blend-mode: multiply;
}

.QuizApp-checkout-image {
  min-height: 99px;
  background: var(--colour-primary-600);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
}

.QuizApp-checkout-image p {
  margin: 0;
}

.QuizApp-checkout-image--text {
  color: var(--text-accent-on-dark);
}

.QuizApp-checkout-bottom--mobile {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 10;
  height: var(--fixed-footer-height);
}
.QuizApp-checkout-bottom--mobile p {
  line-height: 1.33;
}

.QuizApp .quiz-validation-cta {
  position: relative;
}

@media (max-width: 901px) {
  .QuizApp-fixed-cta-wrapper {
    position: fixed;
    bottom: var(--fixed-footer-height);
    left: 0;
    z-index: 10;
    width: 100%;
    padding: 1rem 1rem 1.5rem 1rem;
    background: var(--colour-white);
    box-shadow: var(--action-card-box-shadow);
  }

  .QuizApp-fixed-cta-wrapper button {
    max-width: 480px;
    margin: 0;
  }
}

.QuizApp-long-card {
  max-width: 600px;
}

.QuizApp-long-card .FormSlider {
  margin: 12px 56px -1px 52px;
}

.QuizApp-long-card .rc-slider-rail {
  background: linear-gradient(
    90deg,
    var(--colour-neutral-025) 96%,
    var(--colour-neutral-100) 98%
  );
  background-size: 4.18% 100%;
}

.QuizApp .FormSlider-value-tooltip-range {
  margin-left: 52px;
  margin-right: 56px;
}

.QuizApp .FormSlider-value-tooltip {
  background: var(--colour-tertiary-400);
  border-radius: var(--border-radius-card-large);
  margin-right: -4px;
  right: 0;
  top: 16px;
  white-space: nowrap;
  transform: translateX(50%);
}

.QuizApp .FormSlider-value-tooltip:before {
  border-color: var(--colour-tertiary-400) transparent transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  content: '';
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  pointer-events: none;
  top: 100%;
  width: 0;
}

.QuizApp .FormSlider .rc-slider-handle {
  margin-left: 4px;
}

.QuizApp-card-short {
  max-width: 364px;
}

.QuizApp-wrap {
  max-width: 60rem;
  width: 100%;
  z-index: 1;
}

@media (min-width: 52rem) {
  .QuizApp .QuizApp-overflow {
    padding-bottom: 130px;
    margin-bottom: -130px;
  }

  .QuizApp--checkout-with-image .QuizApp-overflow {
    padding-bottom: 80px !important;
    margin-bottom: unset !important;
    overflow: unset;
  }

  /* This should belong to shame.css... I cannot do this because of above !important. This is due to differences in checkout between new design (with bottom bar with stars) + marketing consent vs previous design that should still be displayed in finanzierungsberatung flow */
  .QuizApp-reset-bottom-spacing.QuizApp-reset-bottom-spacing.QuizApp-reset-bottom-spacing {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}

.QuizApp-steps {
  height: auto;
  transition: transform 0.4s ease, height 0.2s ease;
}

.QuizApp-step {
  --next-step-total-animation-time: 4.4s;
  height: min-content;
  flex: 1 0 100%;
  visibility: hidden;
  transition: visibility var(--next-step-total-animation-time) ease;
}

.QuizApp-step.is-active {
  visibility: visible;
}

.QuizApp-step .ActionCardTemplate.is-active:after {
  border-width: 0 !important;
}

/* partials/QuizProgress.jsx: START */
.QuizProgress-bar-item {
  height: var(--space-010);
}

.QuizProgress-bar.QuizProgress-bar--compact {
  padding: var(--space-020) var(--space-020) 0;
}

.QuizProgress-bar__label.QuizProgress-bar__label--compact {
  color: var(--text-main-on-light);
}

.QuizProgress-bar-item.QuizProgress-bar-item--compact {
  height: var(--space-005);
  width: var(--space-100);
  border-radius: 35px;
  margin: 0 var(--space-003);
}

.QuizProgress-bar-item.QuizProgress-bar-item--compact:first-child {
  margin-left: 0;
}

.QuizProgress-bar-item.QuizProgress-bar-item--compact:last-child {
  margin-right: 0;
}

.QuizProgress-bar-item.is-active {
  background: var(--colour-success-400);
}

.QuizProgress-bar-item.is-visited {
  background: var(--colour-success-050);
}

.QuizProgress-bar-item.QuizProgress-bar-item--compact.is-active {
  background: var(--colour-success-100);
}

.QuizProgress-bar-item.QuizProgress-bar-item--compact.is-visited {
  background: var(--colour-success-050);
}

.QuizProgress-bar-item.QuizProgress-bar-item--compact.is-unvisited {
  background: var(--colour-neutral-100);
}

/* partials/QuizProgress.jsx: END */

/* partials/QuizNavigation.jsx: START */
.QuizNavigation {
  flex-direction: row-reverse;
  min-height: 4rem;
  max-width: 56rem;
}

@media (max-width: 767px) {
  .QuizNavigation .QuizNavigation-back-button {
    margin: 0 auto;
  }
}

.QuizNavigation .QuizNavigation-back-button {
  margin-right: auto;
}
/* partials/QuizNavigation.jsx: END */

/* questions/QuestionRadio.jsx: START */
.QuestionRadio-options {
  max-width: 100vw;
}

@media (max-width: 767px) {
  .QuestionRadio-options {
    margin-bottom: calc(var(--space-005) * -1);
    margin-top: calc(var(--space-005) * -1);
  }
}

@media (min-width: 768px) {
  .QuestionRadio-options {
    margin-bottom: calc(var(--space-010) * -1);
    margin-top: calc(var(--space-010) * -1);
  }

  .QuizApp-step__skeleton {
    height: 420px;
  }
}

.QuestionRadio-options > div {
  margin-left: -4px;
  margin-right: -4px;
}

@media (max-width: 767px) {
  .QuestionRadio-wrap.has-four-elements {
    max-width: 520px;
  }
}

.QuestionRadio-bar-tiles {
  margin-top: var(--space-040);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 8px;
  padding: 0 var(--space-020);
}

.QuestionRadio-bar-tiles .QuestionRadio-button {
  max-width: 360px;
  width: 100%;
}

.QuestionRadio-card-tiles {
  margin-top: var(--space-030);
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.QuestionRadio-card-tiles .QuestionRadio-button {
  margin: var(--space-005);
}

@media (min-width: 768px) {
  .QuestionRadio-card-tiles .QuestionRadio-button {
    margin: var(--space-010);
  }
}

.QuizApp .QuestionRadio-supplementary-button {
  width: 100%;
  text-align: center;
}

.QuestionRadio-wrap.has-more-than-four-elements {
  max-width: 620px;
}
/* questions/QuestionRadio.jsx: END */

/* questions/QuestionCheckout.jsx: START */
.QuizApp-checkout-wrap {
  max-width: 30rem;
}

.QuizApp-checkout-wrap__exclusive .QuizApp-fixed-cta-wrapper {
  bottom: 0;
}

.QuizApp-checkout-rounded {
  position: relative;
  bottom: 3px;
  background: white;
  border-radius: var(--border-radius-card-large);
  padding-bottom: calc(
    var(--fixed-footer-height) + var(--fixed-cta-container-height)
  );
}

.QuizApp-dimmed-CTA {
  color: inherit !important;
}

.QuizApp-animation-wrapper {
  max-width: 34rem;
}

.QuizApp-transition-multi-points {
  max-width: 375px;
  animation: fadeIn 0.4s ease;
}

.QuizApp-checkout-bottom {
  margin-top: auto;
}

.QuizApp-transition {
  height: calc(100vh - var(--navbar-height));
  left: 0;
  right: 0;
  z-index: 10;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.QuizApp-transition-fadeIn {
  animation: fadeIn 0.4s ease;
}

.QuizApp-transition-fadeOut {
  animation: fadeOut 0.4s ease;
}

.QuizApp-transition-loader-wrap {
  animation: fadeIn 0.2s linear forwards;
  background: var(--quiz-step-transition-background);
  height: 100%;
  left: 0;
  right: 0;
  z-index: 100;
}

.QuizApp-transition-loader-wrap.is-reversed {
  animation: fadeOut 0.2s linear forwards;
}

.QuizApp-transition-loader {
  animation-delay: 0.75s;
  display: block;
  height: 48px;
  position: relative;
  width: 48px;
}

@media (max-width: 459px) {
  .QuizApp-transition-loader {
    margin-top: 260px;
  }
}

@media (min-width: 460px) {
  .QuizApp-transition-loader {
    margin-top: 120px;
  }
}

@keyframes quizLoader {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.QuizApp-transition-loader::after,
.QuizApp-transition-loader::before {
  animation: quizLoader 1.5s linear infinite;
  background: var(--colour-primary-600);
  border-radius: 50%;
  content: '';
  height: 48px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 48px;
}

.QuizApp-transition-loader::after {
  animation-delay: 0.75s;
}

.QuizApp-transition-loader-copy {
  font-size: 1rem !important;
}

.QuizApp-vendors {
  overflow: auto;
}

.QuizApp-vendors.is-animating {
  overflow: hidden;
}

.QuizApp-vendors.is-animating:before {
  background-image: linear-gradient(
    90deg,
    rgba(245, 249, 252, 1),
    rgba(245, 249, 252, 0)
  );
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  top: 0;
  width: 10%;
  z-index: 1;
}

.QuizApp-vendors.is-animating:after {
  background-image: linear-gradient(
    90deg,
    rgba(245, 249, 252, 0),
    rgba(245, 249, 252, 1)
  );
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 10%;
  z-index: 1;
}

.QuizApp-vendor {
  align-self: center;
  box-sizing: content-box;
  filter: grayscale(1);
  flex: 0 0 auto;
  margin: auto 8px;
  opacity: 0.5;
}

.QuizHelpBlock-description {
  min-width: 314px;
}

@media (min-width: 480px) {
  .QuizHelpBlock-description {
    min-width: 332px;
  }
}

.QuizApp-vendors.is-animating .QuizApp-vendor {
  animation-name: vendorLogoMove;
  animation-duration: 7s;
  animation-timing-function: linear;
}

@keyframes vendorLogoMove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200px);
  }
}

.QuizApp-in-animation {
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.QuizApp-in-animation.is-backdrop {
  height: 90px;
  margin: 144px auto 54px;
  top: 10px;
  width: 300px;
}

.QuizApp-in-animation.is-tick {
  height: 137px;
  left: 50%;
  margin-left: 10px;
  top: -96px;
  transform: translateX(-50%);
  width: 149px;
}

.QuizApp-in-animation.is-animating {
  opacity: 1;
}

.QuizApp-in-animation.is-backdrop.is-animating {
  top: 0;
}

.QuizApp-in-animation.is-tick.is-animating {
  top: -86px;
}

.QuizApp-transition-ring-wrap {
  display: inline-flex;
}

@media (max-width: 767px) {
  .QuizApp-transition-ring-wrap {
    flex-direction: column-reverse;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .QuizApp-transition-ring-wrap {
    flex-direction: column;
    width: 368px;
  }
}

.QuizApp-transition-ring,
.QuizApp-transition-ring-svg,
.QuizApp-transition-ring circle {
  width: 300px;
  height: 300px;
}

.QuizApp-transition-ring-svg circle {
  position: absolute;
  fill: none;
  stroke-width: 20;
  stroke-dasharray: 1000;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50%;
}

.QuizApp-transition-ring-svg circle:nth-child(1) {
  stroke-dashoffset: 0;
  stroke: var(--colour-neutral-100);
  opacity: 0.25;
}

.QuizApp-transition-ring-svg circle:nth-child(2) {
  stroke-dashoffset: 1000;
  stroke: var(--colour-success-400);
}

.QuizApp-transition-ring.is-animating
  .QuizApp-transition-ring-svg
  circle:nth-child(2) {
  animation: transitionRing 2s ease-in forwards;
  animation-delay: 1s;
}

.QuizApp-transition-ring-icon {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes transitionRing {
  100% {
    stroke-dashoffset: 0;
  }
}

@media (max-width: 767px) {
  .QuizApp-transition-ring-text {
    margin-bottom: var(--space-040);
  }
}

@media (min-width: 768px) {
  .QuizApp-transition-ring-text {
    margin-top: var(--space-040);
  }
}

.QuizApp-summary-arrow-down:before {
  border-color: var(--colour-white) transparent transparent transparent;
  border-style: solid;
  border-width: 12px 12px 0 12px;
  content: '';
  height: 0;
  left: 50%;
  margin-left: -12px;
  position: absolute;
  pointer-events: none;
  top: 100%;
  width: 0;
}

.QuizApp-summary-arrow-right:before {
  border-color: transparent transparent transparent var(--colour-white);
  border-style: solid;
  border-width: 12px 0 12px 12px;
  content: '';
  height: 0;
  left: 104%;
  margin-left: -12px;
  position: absolute;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
}

.QuizApp-agent-avatar {
  width: 68px;
}

.QuizApp-agent-avatar-img {
  height: 68px;
  width: 68px;
}
/* questions/QuestionCheckout.jsx: END */

/* questions/QuestionSuccess.jsx: START */
.QuestionSuccess-wrap {
  max-width: 35rem;
  width: 100%;
}
/* questions/QuestionSuccess.jsx: END */

/* QuizModal: START */
.Quiz-modal {
  max-width: 500px;
}

@media (max-width: 768px) {
  .Quiz-modal {
    margin-left: var(--space-030);
    margin-right: var(--space-030);
  }
}
/* QuizModal: END */

.QuizTrustElements_container {
  border-top: 1px solid var(--colour-neutral-100);
  padding: var(--space-030) var(--space-020) var(--space-020);
}

.QuizTrustElements_container_img {
  display: inline-block;
  vertical-align: middle;
}

.QuizTrustElements_container_img:first-of-type {
  margin-right: var(--space-060);
  width: 150px;
}

.QuizTrustElements_container_img:last-of-type {
  margin-left: var(--space-060);
  width: 80px;
}

.QuizTrustElements_list {
  list-style: none;
  padding-inline-start: 0;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  text-align: left;
  margin-block-start: 0;
  margin-block-end: 0;
}

.QuizTrustElements_list > li {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.QuizTrustElements_list > li > span {
  margin-right: 8px;
}

.QuizTrustElements_item-container .HLP-hero-rating-star {
  fill: var(--colour-award);
}

.QuizTrustElements_item-container > p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.QuizTrust_star-icon {
  width: 20px;
  height: 20px;
}

.QuizTrust_heart-icon {
  width: 26px;
  height: 24px;
  display: inline-block;
}

.QuizTrust_budget-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.QuizTrust_budget-title {
  margin-bottom: var(--space-010);
  margin-block-start: 0;
  margin-block-end: 0;
}

.QuizTrust_budget-badges {
  flex-wrap: wrap;
}

.QuizTrust_budget-badges > img {
  margin-top: 24px;
}

.QuizTrust-testimonial_image-container {
  flex: 0 0 40px;
  text-align: right;
}

.QuizTrust-testimonial_image {
  height: 40px;
  width: 40px;
}

@media (min-width: 768px) {
  .QuizTrust_budget-title {
    margin-bottom: 0;
  }

  .QuizTrust_budget-badges {
    flex-wrap: nowrap;
    margin-left: var(--space-020);
    margin-top: 24px;
    align-items: center;
    width: 100%;
  }
  .QuizTrust_budget-badges > img {
    margin-top: 0;
  }

  .QuizTrust_testimonial-text {
    width: 45%;
  }
}

/* questions/QuestionHouseholdIncome.tsx: START */
.HouseholdIncome-InputWrapper {
  max-width: 440px;
}

.HouseholdIncome-InputWrapper .FormError-wrapper {
  margin-top: 8px;
}

.HouseholdIncome-Input .FormTextLabel.f-t-body {
  font-size: 0.875rem; /* 14px - apply f-t-mini */
}

.HouseholdIncome-Input .FormTextLabel {
  top: 12px;
}
/* questions/QuestionHouseholdIncome.tsx: END */
