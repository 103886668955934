.exclusive-leads-page {
  margin-top: calc(var(--space-070) + var(--space-040));
  display: grid;
  grid-template-areas:
    'banner-top sidebar'
    'vendor-contact sidebar'
    'vendor-promo sidebar'
    'vendor-video sidebar'
    'vendor-statistics sidebar'
    'vendor-services sidebar'
    'house-preview sidebar'
    'faq sidebar';
  grid-template-columns: minmax(0, 2fr) minmax(320px, 1fr);
  grid-column-gap: var(--space-040);
  grid-row-gap: var(--space-030);
  margin-bottom: var(--space-040);
}

.exclusive-leads-page-content {
  background: var(--colour-background-010);
  padding: var(--space-020);
  border-radius: var(--border-radius-card-large);
}

.exclusive-leads-page-content__banner-top {
  position: relative;
}

.exclusive-leads-page-content__banner-top--standalone {
  grid-area: banner-top;
  border-radius: var(--border-radius-card-large);
  overflow: hidden;
}

.exclusive-leads-page-content__banner-top--full-bleed {
  margin: -16px -32px 16px -32px;
}

.exclusive-leads-page-content__hero-image.exclusive-leads-page-content__hero-image {
  width: 100%;
  object-fit: cover;
  padding: 0;
}

.exclusive-leads-page-content__hero-image-logo {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.exclusive-leads-page-content__vendor-contact {
  grid-area: vendor-contact;
}

.exclusive-leads-page-content__vendor-promo {
  grid-area: vendor-promo;
}

.exclusive-leads-page-content__awards {
  grid-area: awards;
}

.exclusive-leads-page-content__vendor-video {
  grid-area: vendor-video;
}

.exclusive-leads-page-content__vendor-statistics {
  grid-area: vendor-statistics;
}

.exclusive-leads-page-content__vendor-services {
  grid-area: vendor-services;
}

.exclusive-leads-page-content__house-preview {
  grid-area: house-preview;
}

.exclusive-leads-page-content__faq {
  grid-area: faq;
}

.exclusive-leads-page-content__sidebar {
  grid-area: sidebar;
  margin-bottom: 16px;
}

.exclusive-leads-page-content__sidebar-content {
  border-radius: var(--border-radius-card-large);
  background: var(--colour-white);
  padding: var(--space-040) var(--space-020) var(--space-020) var(--space-020);
}

.exclusive-leads-page-content__sidebar-content-image {
  max-height: 120px;
}

.exclusive-leads-page-content__sidebar-content-awards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

.exclusive-leads-page-content__sidebar-content-awards img {
  max-width: 74px;
}

.exclusive-leads-page-content.exclusive-leads-page-content__faq {
  --marginForTransition: 10px;
}
.exclusive-leads-page-content.exclusive-leads-page-content__faq summary {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: calc(var(--marginForTransition) * -1);
  transition: margin 150ms ease-out;
}

.exclusive-leads-page-content.exclusive-leads-page-content__faq
  details[open]
  summary {
  margin-bottom: var(--marginForTransition);
}

.exclusive-leads-page-content__faq-description {
  margin-top: calc(var(--marginForTransition) * -1);
}

.exclusive-leads-page-content__faq-icon {
  transform: translateY(-4%);
  transition: transform 0.3s ease;
  flex-shrink: 0;
}

.exclusive-leads-page-content.exclusive-leads-page-content__faq
  details[open]
  .exclusive-leads-page-content__faq-icon {
  transform: rotate(180deg);
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.exclusive-leads-page-content__house-preview-images {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.exclusive-leads-page-content__house-preview-images img {
  width: calc(33.33% - 6px);
}

.exclusive-leads-page_content__footer .VendorDetailPage-footer-links {
  justify-content: flex-end;
}

.exclusive-leads-page_content__footer-logo {
  width: 100px;
  margin-right: 64px;
}

.exclusive-leads-page-content__hero-image-logo {
  background: var(--colour-white);
  max-width: 40vw;
}

@media (min-width: 768px) {
  .exclusive-leads-page-content__sidebar-content-awards {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
}

@media (max-width: 1160px) {
  .exclusive-leads-page {
    margin-top: var(--space-070);
    grid-template-areas:
      'banner-top banner-top'
      'vendor-contact vendor-contact'
      'vendor-promo vendor-promo'
      'awards awards'
      'vendor-video vendor-video'
      'vendor-statistics vendor-statistics'
      'vendor-services vendor-services'
      'house-preview house-preview'
      'faq faq';
    margin-bottom: var(--space-020);
  }
  .exclusive-leads-page-content__house-preview-images img {
    width: calc(50% - 4px);
  }

  .exclusive-leads-page-content__banner-top--standalone {
    grid-area: banner-top;
    margin: 0 -16px;
    border-radius: 0;
  }
}

@media (max-width: 1160px) {
  .exclusive-leads-page-content__sidebar-content-image {
    max-height: 95px;
    max-width: 40vw;
  }
}

@media (min-width: 1161px) {
  .exclusive-leads-page-content__sidebar-content-awards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  }

  .exclusive-leads-page-content {
    overflow: hidden;
  }
}

@media (max-width: 900px) {
  .exclusive-leads-page_content__footer-logo {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .exclusive-leads-page-content {
    padding-top: 0;
  }

  .exclusive-leads-page-content__hero-image-logo
    > .exclusive-leads-page-content__sidebar-content-image {
    height: auto;
    max-height: 75px;
    padding: 4px;
  }

  .exclusive-leads-page-content__banner-top--full-bleed {
    margin: 0 -16px 16px -16px;
  }

  .exclusive-leads-page {
    grid-template-areas:
      'vendor-contact vendor-contact'
      'vendor-promo vendor-promo'
      'awards awards'
      'vendor-video vendor-video'
      'vendor-statistics vendor-statistics'
      'vendor-services vendor-services'
      'house-preview house-preview'
      'faq faq';
  }
}
