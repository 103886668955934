.QuizWidgetContent_container {
  max-width: 60rem;
  margin: 0 auto;
}

.QuizWidgetContent-progress_bar .QuizApp-wrap {
  padding: 0;
}

.QuizWidgetContent-progress_bar.QuizWidgetContent-progress_bar--no-label {
  min-height: 20px;
}

.QuizWidgetContent-progress_bar--no-label .QuizProgress-bar__label {
  display: none;
}

.QuizWidgetContent_footer-container {
  background: transparent;
  border-top: 1px solid var(--colour-neutral-050);
}

.QuizWidgetContent_title-question {
  max-width: 928px;
  margin-top: 24px;
  margin-bottom: 0;
}

.QuizWidgetContent_subtitle-question {
  max-width: 928px;
  text-align: left;
  margin-bottom: 0;
}

#QuizWidgetContent_progress-bar {
  width: 100%;
  min-height: 38px;
}

/* QuizTiles - START */
.QWC_QuizTiles_tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 160px;
  width: 168px;
  transition: box-shadow 0.2s ease;
  overflow: hidden;
  border-radius: var(--border-radius-cta);
  box-shadow: 0 5px 10px -1px rgba(59, 71, 104, 0.05), 0 2px 4px -3px #3b4768,
    0 2px 40px 0 rgba(59, 71, 104, 0.05);
}

.QWC_QuizTiles_tile:hover {
  box-shadow: var(--action-card-box-shadow-hover);
}

.QWC_QuizTiles_tile img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.QWC_QuizTiles_tile_image-container {
  height: 120px;
}

.QWC_QuizTiles_question-label {
  width: 100%;
  background-color: var(--colour-background-015);
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 1.33;
}
/* QuizTiles - END */

.QuizWidgetContent_footer {
  display: flex;
  flex-direction: column;
}

.QuizWidgetContent_footer_ticks-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 32px;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
}

.QuizWidgetContent_footer_badges-container {
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
  gap: 80px;
}

.QuizWidgetContent_footer_badges-container img {
  height: 80px;
}

@media (min-width: 768px) {
  #QuizWidgetContent-progress_bar {
    min-height: 66.5px;
  }
  .QuizWidgetContent_title-question {
    text-align: center;
    margin: 32px 0 0;
  }
  .QuizWidgetContent_subtitle-question {
    text-align: center;
  }
  .QuizWidgetContent_footer {
    flex-direction: row;
    justify-content: space-between;
  }
  .QuizWidgetContent_footer_badges-container {
    gap: 40px;
  }
}
