.HLP-hero__left-aligned-tiles-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: var(--navbar-height);
  overflow: hidden;
}

.HLP-hero__left-aligned-tiles-pre-heading {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
}

.HLP-hero__left-aligned-tiles-wrapper-light,
.HLP-hero__left-aligned-tiles-wrapper-light
  .HLP-hero-content--left-aligned-tiles:after {
  background: var(--colour-background-025);
}

.HLP-hero__left-aligned-tiles-wrapper-light
  .ActionCardTemplate.has-strong-shadow:not(.is-disabled):after {
  box-shadow: var(--action-card-box-shadow);
}

.HLP-hero__left-aligned-tiles-wrapper-dark,
.HLP-hero__left-aligned-tiles-wrapper-dark
  .HLP-hero-content--left-aligned-tiles:after {
  background: var(--colour-primary-600);
  color: var(--colour-white);
}

.HLP-hero__left-aligned-tiles-wrapper-light
  .HLP-hero__left-aligned-tiles-pre-heading {
  color: var(--colour-tertiary-400);
}

.HLP-hero__left-aligned-tiles-wrapper-dark
  .HLP-hero__left-aligned-tiles-pre-heading {
  color: var(--colour-secondary);
}

.HLP-hero__left-aligned-tiles-wrapper-light
  .HLP-hero__left-aligned-tiles-ticks
  * {
  stroke: var(--colour-tertiary-400);
}

.HLP-hero__left-aligned-tiles-wrapper-dark
  .HLP-hero__left-aligned-tiles-ticks
  * {
  stroke: var(--colour-secondary);
}

.HLP-hero--left-aligned-tiles {
  width: 100vw;
  height: 140px;
  object-fit: cover;
  object-position: bottom;
}

.HLP-hero-sub-heading--left-aligned-tiles {
  font-size: 16px;
  line-height: 1.66;
  margin-bottom: 16px;
}

.HLP-hero-content-heading--left-aligned-tiles {
  font-size: 28px;
  font-weight: bold;
  font-family: var(--fancy-font-family);
  margin: var(--space-005) 0 var(--space-020) 0;
}

.HLP-hero-content--left-aligned-tiles {
  flex: 1;
  padding: 8px 16px 32px 16px;
}

.HLP-hero__left-aligned-tiles-ticks {
  margin-top: 24px;
}

.HLP-hero__left-aligned-tiles-ticks > div {
  min-height: 38px;
}

.HLP-hero-content--left-aligned-tiles .ActionCardTemplate {
  width: auto;
  height: 110px;
}

.HLP-hero-content--left-aligned-tiles .ActionCardTemplate-holder {
  padding: 15px 0;
}

.HLP-hero-content--left-aligned-tiles .ActionCardTemplate svg {
  height: 50px;
}

.HLP-hero-content--left-aligned-tiles .ActionCardTemplate-text {
  background: var(--colour-background-025);
}

.HLP-hero-content--left-aligned-tiles .ActionCardTemplate-text > span {
  font-size: 14px;
  word-break: keep-all;
  line-height: 1;
}

.HLP-hero-content--left-aligned-tiles .js-quiz-widget {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

@media (max-width: 403px) {
  .HLP-hero-content--left-aligned-tiles .js-quiz-widget {
    gap: 8px;
  }
}

@media (max-width: 768px) {
  .HLP-hero-content--left-aligned-tiles .js-quiz-option {
    margin: 0;
    flex-basis: calc(50% - 8px);
  }
  .HLP-hero-content--left-aligned-tiles .widget-tile {
    margin: 0;
    flex-basis: calc(50% - 8px);
  }
  .HLP-hero-content--left-aligned-tiles .widget-wrapper {
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .HLP-hero__left-aligned-tiles-wrapper {
    flex-direction: row-reverse;
    align-items: stretch;
  }

  .HLP-hero--left-aligned-tiles {
    width: 40%;
    flex-shrink: 0;
    height: auto;
  }

  .HLP-hero-content-heading--left-aligned-tiles {
    font-size: 48px;
  }

  .HLP-hero-sub-heading--left-aligned-tiles {
    font-size: 18px;
    margin-bottom: 32px;
  }

  .HLP-hero-content--left-aligned-tiles {
    position: relative;
    display: flex;
    padding: 40px 64px 70px 20px;
    box-shadow: 0 2px 40px 0 rgba(59, 71, 104, 0.05),
      10px 2px 250px -3px rgba(59, 71, 104, 0.6),
      10px 6px 38px -2px rgba(59, 71, 104, 0.7);
  }

  .HLP-hero-content--left-aligned-tiles:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 210px;
    left: -210px;
    top: 0;
    background: var(--colour-background-025);
  }

  .HLP-hero__left-aligned-tiles-ticks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 8px;
    margin-top: 40px;
  }

  .HLP-hero-content--left-aligned-tiles .js-quiz-option {
    width: auto;
    margin: 0;
    flex: 1;
  }

  .HLP-hero-content--left-aligned-tiles .widget-wrapper {
    flex-wrap: nowrap;
  }

  .HLP-hero-content--left-aligned-tiles .widget-tile {
    width: auto;
    margin: 0;
    flex: 1;
  }

  .HLP-hero-content--left-aligned-tiles .js-quiz-widget {
    flex-wrap: nowrap;
    justify-content: stretch;
  }

  .HLP-hero-content--left-aligned-tiles .ActionCardTemplate {
    width: auto;
    height: 120px;
  }

  .HLP-hero-content--left-aligned-tiles .ActionCardTemplate-holder {
    padding: 16px 0;
  }

  .HLP-hero-content--left-aligned-tiles .ActionCardTemplate svg {
    height: 60px;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .HLP-hero-content--left-aligned-tiles {
    padding: 40px 20px 70px 16px;
  }

  .HLP-hero-content--left-aligned-tiles .ActionCardTemplate-text > span {
    font-size: 12px;
  }
}
@media (min-width: 1160px) {
  .HLP-hero-content--left-aligned-tiles {
    display: flex;
    margin-left: calc((100vw - 1168px) / 2);
  }
}
