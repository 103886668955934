/* purgecss start ignore */
[class^='CTA-'] {
  cursor: pointer;
  font-family: inherit;
  text-decoration: none;
}

[class^='CTA-']:not([class*='f-d-']) {
  display: inline-block;
}

[class^='CTA-'].is-disabled {
  cursor: not-allowed;
  pointer-events: none;
}

[class^='CTA-'].is-full-width {
  display: block;
  max-width: 100%;
  text-align: center;
  width: 100%;
}

[class^='CTA-'].is-block {
  display: block;
}

@media (max-width: 767px) {
  [class^='CTA-'].is-block\:s {
    display: block;
    max-width: 100%;
    text-align: center;
    width: 100%;
  }
}

[class^='CTA-block'] {
  border-radius: var(--border-radius-cta);
  border-style: solid;
  border-width: 2px;
  font-family: var(--fancy-font-family);
  font-weight: 700;
  line-height: 1;
  text-align: center;
}

[class^='CTA-block']:not([class*='has-larger-font']) {
  font-size: 1rem;
}

[class^='CTA-block'][class*='has-larger-font'] {
  font-size: 1.25rem;
}

[class^='CTA-block-']:not([class*='f-p-']) {
  padding: calc(var(--space-020) - 2px) var(--space-050); /* -2px because of the 1px border on each side */
}

[class^='CTA-block-'].is-full-width:not([class*='f-p-']) {
  padding: calc(var(--space-020) - 2px) var(--space-030); /* -2px because of the 1px border on each side */
}

[class^='CTA-block-'][class*='f-p-h']:not([class*='f-p-v']) {
  padding-bottom: calc(
    var(--space-020) - 2px
  ); /* -2px because of the 1px border on each side */
  padding-top: calc(
    var(--space-020) - 2px
  ); /* -2px because of the 1px border on each side */
}

[class^='CTA-block-'].is-full-width[class*='f-p-h'] {
  padding-bottom: calc(
    var(--space-020) - 2px
  ); /* -2px because of the 1px border on each side */
  padding-top: calc(
    var(--space-020) - 2px
  ); /* -2px because of the 1px border on each side */
}
.CTA-block-primary {
  background-color: var(--colour-secondary);
  border-color: var(--colour-secondary);
  box-shadow: var(--cta-primary-box-shadow-with-opacity, none);
  color: var(--colour-primary-600);
  transition: background-color 0.2s ease, border-color 0.2s ease,
    box-shadow 0.2s ease, color 0.2s ease;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .CTA-block-primary:hover {
    background: var(--colour-secondary);
    border-color: var(--colour-secondary);
    box-shadow: var(--cta-primary-hover-box-shadow, none);
    transform: translateY(-1px);
  }
}

.CTA-block-primary:active,
.CTA-block-primary.is-active {
  box-shadow: none;
}

.CTA-block-primary.is-disabled {
  background-color: var(--colour-neutral-100);
  border-color: var(--colour-neutral-100);
  box-shadow: none;
  color: var(--colour-white);
}

.CTA-block-primary.is-failure {
  background-color: var(--colour-error);
  border-color: var(--colour-error);
  color: var(--colour-white);
  box-shadow: var(--cta-error-box-shadow-with-opacity, none);
}

.CTA-block-secondary {
  background-color: var(--colour-primary-600);
  border-color: var(--colour-primary-600);
  box-shadow: 0 6px 18px -15px var(--colour-primary-600),
    0 5px 30px -5px rgba(61, 121, 182, 0.33);
  color: var(--colour-white);
  transition: background-color 0.2s ease, border-color 0.2s ease,
    box-shadow 0.2s ease, color 0.2s ease;
}

.CTA-block-secondary:focus-visible {
  outline: 2px solid var(--colour-secondary);
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .CTA-block-secondary:hover {
    box-shadow: 0 6px 12px -8px var(--colour-primary-600),
      0 5px 30px -5px rgba(61, 121, 182, 0.33);
    transform: translateY(-1px);
  }
}

.CTA-block-secondary:active,
.CTA-block-secondary.is-active {
  box-shadow: none;
}

.CTA-block-secondary.is-disabled {
  background-color: var(--colour-neutral-100);
  border-color: var(--colour-neutral-100);
  box-shadow: none;
  color: var(--colour-white);
}

.CTA-block-secondary.is-inverted {
  background-color: var(--colour-white);
  border-color: var(--colour-white);
  color: var(--colour-primary-600);
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .CTA-block-secondary.is-inverted:hover {
    background-color: var(--colour-primary-600);
    border-color: var(--colour-primary-600);
    color: var(--colour-white);
  }
}

.CTA-block-secondary.is-inverted:active,
.CTA-block-secondary.is-inverted.is-active {
  background-color: var(--colour-white);
  border-color: var(--colour-white);
  color: var(--colour-tertiary-400);
}

.CTA-block-tertiary {
  background-color: transparent;
  border-color: var(--colour-primary-600);
  color: var(--colour-primary-600);
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .CTA-block-tertiary:hover {
    border-color: var(--colour-tertiary-400);
    color: var(--colour-tertiary-400);
  }
}

.CTA-block-tertiary:active,
.CTA-block-tertiary.is-active {
  border-color: var(--colour-primary-800);
  color: var(--colour-primary-800);
}

.CTA-block-tertiary.is-disabled {
  border-color: var(--colour-neutral-100);
  color: var(--colour-neutral-100);
}

.CTA-block-tertiary.is-inverted {
  border-color: var(--colour-white);
  color: var(--colour-white);
}

.CTA-block-tertiary.is-inverted:focus-visible {
  outline: 2px solid var(--colour-secondary);
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .CTA-block-tertiary.is-inverted:hover {
    border-color: var(--colour-tertiary-400);
    color: var(--colour-tertiary-400);
  }
}

.CTA-block-tertiary.is-inverted:active,
.CTA-block-tertiary.is-inverted.is-active {
  border-color: var(--colour-white);
  color: var(--colour-tertiary-400);
}

[class^='CTA-text'] {
  background-color: transparent;
  border: 0;
  text-align: inherit;
  border-radius: var(--border-radius-cta);
}

[class^='CTA-text']:where(:not([class*='f-c-fo']):not(.CTA-text-primary)) {
  color: inherit;
}

[class^='CTA-text']:not([class*='f-t-']) {
  line-height: inherit;
}

[class^='CTA-text']:not([class*='f-p-']) {
  padding: 0;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  [class^='CTA-text']:hover {
    text-decoration: underline;
  }
}

.CTA-text-primary {
  color: var(--colour-tertiary-400);
}

.CTA-text-primary.is-disabled {
  color: var(--colour-neutral-050);
}

.CTA-text-primary.is-inverted {
  color: var(--colour-white);
}

.CTA-text-error {
  color: var(--colour-error);
}

.CTA-icon {
  border: 0;
}

.CTA-icon:not([class*='f-c-ba-']) {
  background-color: transparent;
}

.CTA-icon:not([class*='f-p-']) {
  padding: 0;
}

.internal {
  color: inherit;
  text-decoration: underline;
}
/* purgecss end ignore */
