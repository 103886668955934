.ProcessSection {
  position: relative;
  display: flex;
  flex-direction: column;
}

.ProcessSection__wrapper {
  display: flex;
}

.ProcessSection__content {
  max-width: 600px;
  width: 65%;
}

.ProcessSection__title {
  margin: 0 0 36px 0;
}
.ProcessSection__step {
  margin-bottom: 24px;
}
.ProcessSection__step-title {
  display: flex;
  margin: 0 0 16px 0;
}
.ProcessSection__step-title > span:first-of-type {
  margin-right: 16px;
  width: 8px;
}

.ProcessSection__step-description-box {
  margin-bottom: 24px;
}

.ProcessSection__step-description {
  position: relative;
  padding: 8px 0 16px 24px;
  margin: 0;
}
.ProcessSection__step-description::before {
  position: absolute;
  left: 4px;
  top: 0;
  content: '';
  width: 2px;
  height: 100%;
  max-height: 108px;
  background: var(--colour-primary-600);
}
.ProcessSection__background {
  max-width: 432px;
  overflow: hidden;
  margin-left: var(--space-120);
}

.ProcessSection__background-img {
  object-fit: contain;
}

@media (max-width: 1160px) {
  .ProcessSection__background {
    left: 100vw;
  }
}

@media (max-width: 767px) {
  .ProcessSection__background {
    display: none;
  }
  .ProcessSection__content {
    width: 100%;
  }
}
