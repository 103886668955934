/* OfficeMap.jsx: START */
.OfficeMap-wrap {
  height: calc(100vh - var(--navbar-height));
  flex-direction: row-reverse;
}

.MapView-wrap,
.DesktopView-wrap,
.MobileView-wrap {
  height: 100%;
  width: 100%;
}

.MobileView-wrap {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.DesktopView-wrap {
  box-shadow: 8px 5px 10px -1px rgba(59, 71, 104, 0.05), 2px 0 4px -3px #3b4768,
    8px 0 37px 0 rgba(59, 71, 104, 0.05);
  max-width: 42rem;
  z-index: 1;
}

.DesktopView-scroll {
  height: 100%;
  overflow: auto;
}

.DesktopView-scroll.has-smooth-scrolling {
  scroll-behavior: smooth;
}
/* OfficeMap.jsx: END */

/* partials/MobileView.jsx: START */
.OfficeMap-wrap .gmnoprint a,
.OfficeMap-wrap .gmnoprint span,
.OfficeMap-wrap a[href^='https://maps.google.com/']
{
  display: none !important;
}

.MapView-filters-wrap {
  width: 100%;
  z-index: 1;
}

.MapView-filters-wrap-toggle {
  left: var(--space-020);
  top: var(--space-020);
  z-index: 1;
}

.MapView-filters-close-icon {
  top: 16px;
  right: 12px;
}

.MapView-top-right-CTA {
  right: var(--space-020);
  top: var(--space-020);
  z-index: 1;
  transition: transform 0.25s ease-in;
  transform: translateX(150%);
  will-change: transform;
}

.MapView-top-right-CTA.is-shown {
  transition: transform 0.25s ease-out;
  transition-delay: 0.25s;
  transform: translateX(0);
}

.MapView-bottom-center-CTA {
  bottom: var(--space-010);
  left: 50%;
  margin-left: -75px;
}

.MapView-content-wrap {
  bottom: 0;
  left: 0;
  overflow: auto;
  right: 0;
  scroll-behavior: smooth;
  top: var(--space-080);
  transform: translateY(100%);
  transition: transform 0.25s ease-in;
  will-change: transform;
}

.MapView-content-wrap.is-shown {
  transition: transform 0.25s ease-out;
  transform: translateY(0);
}

.MapView-cards-wrap {
  bottom: 0;
  left: 0;
  transition: transform 0.25s ease-in;
  transform: translateY(100%);
  right: 0;
}

.MapView-cards-wrap.is-shown {
  transition: transform 0.25s ease-out;
  transform: translateY(0);
}

.OfficeMap-list-item.is-mobile-card {
  flex-shrink: 0;
  margin-top: auto;
}

.MobileView-filters-wrap {
  transition: transform 0.25s ease-in;
  transform: translateY(-100%);
  will-change: transform;
}

.MobileView-filters-wrap.is-displaying-filter {
  position: relative;
  z-index: 1;
}

.MobileView-filters-wrap.is-shown {
  transition: transform 0.25s ease-out;
  transform: translateY(0);
}

.MobileView-filters-wrap .SearchResultsFilters-container {
  bottom: 0;
  height: calc(100vh - var(--navbar-height));
  top: 0;
}
/* partials/MobileView.jsx: END */

/* partials/DesktopView.jsx: START */
.OfficeMap-list-item {
  border: 0;
  color: var(--colour-primary-600);
  width: 100%;
}

button.OfficeMap-list-item {
  cursor: pointer;
}

.OfficeMap-list-item.is-highlighted:after {
  border: 2px solid var(--colour-secondary);
  border-radius: var(--border-radius-card-large);
  bottom: 0;
  content: '';
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}

.OfficeMap-list-item-highlight {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 129px;
  width: 100%;
}

.OfficeMap-list-item-logo-wrap,
.OfficeMap-list-item-type {
  height: 100px;
  width: 100px;
  border-radius: var(--border-radius-card-large);
}

.OfficeMap-list-item-details {
  height: 100px;
}

.OfficeMap-list-item-type-icon {
  padding: 12px;
}

.OfficeMap-list-item-type-icon use {
  stroke-width: 1;
}

.OfficeMap-list-item-logo {
  max-height: 84px;
  max-width: 84px;
}

@media (max-width: 374px) {
  .OfficeMap-list-item-description {
    width: 100%;
  }
}

@media (min-width: 375px) {
  .OfficeMap-list-item-description {
    min-width: 10rem;
  }
}

.OfficeMap-list-local-agent {
  border-style: solid;
  border-width: 1px;
  border-radius: var(--border-radius-card-large);
}

@media (min-width: 768px) {
  .OfficeMap-list-local-agent {
    max-width: 264px;
  }
}

.OfficeMap-list-item-seal {
  max-height: 72px;
  max-width: 72px;
}

.OfficeMap-DesktopView-panel {
  transition: 0.25s opacity ease-out, 0.25s transform ease-out;
}

.OfficeMap-DesktopView-panel.is-hidden {
  opacity: 0;
  transform: translateX(-100%);
  transition: 0.25s opacity ease-in, 0.25s transform ease-in;
}
/* partials/DesktopView.jsx: END */

/* partials/MapView.jsx: START */
.MapView-map-cta {
  white-space: nowrap;
}

.MapView-list-cta-wrap {
  right: var(--space-020);
  top: var(--space-020);
}

.MapView-update-cta-wrap {
  right: 50%;
  transition: 0.25s opacity ease, 0.25s transform ease, 0.25s top ease;
}

@media (max-width: 767px) {
  .MapView-update-cta-wrap {
    top: var(--space-080);
  }

  .MapView-update-cta-wrap.has-expanded-filters {
    top: 184px;
  }
}

@media (min-width: 768px) {
  .MapView-update-cta-wrap {
    top: var(--space-020);
  }

  .MapView-update-cta-wrap.is-bottom {
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    background: linear-gradient(
      to bottom,
      rgba(250, 253, 255, 0) 0%,
      rgba(250, 253, 255, 1) 100%
    );
    display: block;
    left: 0;
    padding: var(--space-040);
    position: sticky;
    right: auto;
    text-align: center;
    top: var(--space-020);
    transform: none !important;
  }
}

.MapView-update-cta-wrap.is-hidden {
  opacity: 0;
  transform: translate(50%, -5rem);
}

.MapView-update-cta-wrap.is-shown {
  opacity: 1;
  transform: translate(50%, 0);
}

.OfficeMap-pin-label {
  color: var(--colour-primary-600) !important;
  font-family: var(--fancy-font-family) !important;
  font-size: 0.85rem !important;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}
/* partials/MapView.jsx: END */

.MapView-map-cta-loader {
  width: 20px;
  height: 20px;
  border: 5px solid #fff;
  border-radius: 100%;
  border-top: 5px solid transparent;
  animation: load-animate infinite linear 1s;
  display: none;
  position: absolute;
  left: 50%;
  margin-left: -10px;
}

.MapView-update-cta-wrap.is-loading .MapView-map-cta-text {
  visibility: hidden;
}

.MapView-update-cta-wrap.is-loading .MapView-map-cta-loader {
  display: inline-block;
}

@keyframes load-animate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.35;
  }
  100% {
    transform: rotate(360deg);
  }
}
