.CategorySquareBanners-container {
  align-items: flex-start;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-height: 284px;
}

.CategorySquareBanners-header {
  /* Largest header size possible that fits all container widths */
  font-size: 20px;
  max-width: 100%;
}
