.quiz-construction-method__container {
  overflow: hidden;
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes slideOutAndHide {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
    visibility: hidden;
  }
}

.quiz-construction-method__first-step {
  transition: transform 0.3s ease-out;
}

.quiz-construction-method__second-step {
  transition: transform 0.3s ease-in;
}

.quiz-construction-method__first-step--out {
  animation: slideOutAndHide 0.3s ease-out forwards;
}

.quiz-construction-method__second-step--in {
  animation: slideOut 0.3s ease-in forwards;
}
