.Cookie-Banner {
  position: fixed;
}

.Cookie-Banner__actions {
  display: flex;
  gap: var(--space-020);
}

.Cookie-Banner__actions .CTA-block-tertiary.is-inverted:hover {
  text-decoration: underline;
  border-color: var(--colour-white);
  color: var(--colour-white);
}

@media (max-width: 767px) {
  .Cookie-Banner {
    bottom: 0;
    border-radius: var(--border-radius-card-large)
      var(--border-radius-card-large) 0 0;
    left: 0;
    right: 0;
  }

  .Cookie-Banner__actions {
    text-align: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .Cookie-Banner__actions > * {
    flex: 1 1 auto;
  }
}

@media (min-width: 768px) {
  .Cookie-Banner {
    bottom: var(--space-040);
    left: auto;
    max-width: 607px;
    right: var(--space-040);
    border-radius: var(--border-radius-card-large);
  }
}

.Cookie-Banner [class*='f-t-'] {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
