.PageBreadcrumbs-item {
  display: inline-block;
}

.PageBreadcrumbs-item + .PageBreadcrumbs-item {
  position: relative;
  padding-left: 1rem;
}

.PageBreadcrumbs-item + .PageBreadcrumbs-item:before {
  content: '›';
  display: inline-block;
  left: 0;
  position: absolute;
  text-align: center;
  width: 1rem;
}
