.YoutubeVideo-main {
  height: 0;
  padding-top: 56.25%;
}

.YoutubeVideo-play {
  appearance: none;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.YoutubeVideo-thumbs {
  margin: -5px;
}

@media (max-width: 767px) {
  .YoutubeVideo-play.is-thumb {
    height: 71px;
    width: 127px;
  }
}

@media (min-width: 768px) {
  .YoutubeVideo-play.is-thumb {
    height: 95px;
    width: 170px;
  }
}

.YoutubeVideo-play::after {
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%20-77%20512.002%20512%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m501.453125%2056.09375c-5.902344-21.933594-23.195313-39.222656-45.125-45.128906-40.066406-10.964844-200.332031-10.964844-200.332031-10.964844s-160.261719%200-200.328125%2010.546875c-21.507813%205.902344-39.222657%2023.617187-45.125%2045.546875-10.542969%2040.0625-10.542969%20123.148438-10.542969%20123.148438s0%2083.503906%2010.542969%20123.148437c5.90625%2021.929687%2023.195312%2039.222656%2045.128906%2045.128906%2040.484375%2010.964844%20200.328125%2010.964844%20200.328125%2010.964844s160.261719%200%20200.328125-10.546875c21.933594-5.902344%2039.222656-23.195312%2045.128906-45.125%2010.542969-40.066406%2010.542969-123.148438%2010.542969-123.148438s.421875-83.507812-10.546875-123.570312zm0%200%22%20fill%3D%22%23212121%22%20fill-opacity%3D%220.4%22%2F%3E%3Cpath%20d%3D%22m204.96875%20256%20133.269531-76.757812-133.269531-76.757813zm0%200%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-size: 60px 60px;
  background-position: 50% 50%;
  content: '';
  height: 64px;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
  position: absolute;
  top: 50%;
  width: 64px;
}

.YoutubeVideo-play.is-thumb::after {
  background-size: 50px 50px;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .YoutubeVideo-play:hover::after {
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%20-77%20512.002%20512%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m501.453125%2056.09375c-5.902344-21.933594-23.195313-39.222656-45.125-45.128906-40.066406-10.964844-200.332031-10.964844-200.332031-10.964844s-160.261719%200-200.328125%2010.546875c-21.507813%205.902344-39.222657%2023.617187-45.125%2045.546875-10.542969%2040.0625-10.542969%20123.148438-10.542969%20123.148438s0%2083.503906%2010.542969%20123.148437c5.90625%2021.929687%2023.195312%2039.222656%2045.128906%2045.128906%2040.484375%2010.964844%20200.328125%2010.964844%20200.328125%2010.964844s160.261719%200%20200.328125-10.546875c21.933594-5.902344%2039.222656-23.195312%2045.128906-45.125%2010.542969-40.066406%2010.542969-123.148438%2010.542969-123.148438s.421875-83.507812-10.546875-123.570312zm0%200%22%20fill%3D%22red%22%2F%3E%3Cpath%20d%3D%22m204.96875%20256%20133.269531-76.757812-133.269531-76.757813zm0%200%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');
  }
}
