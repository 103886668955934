.CMS-sharing-twitter-icon use {
  fill: #0f1419;
}

.CMS-sharing-facebook-icon use {
  fill: #4267b2;
}

.CMS-sharing-item-CTA {
  height: 24px;
  overflow: hidden;
  width: 24px;
}

@media (max-width: 1199px) {
  .CMS-sharing-items {
    display: flex;
    justify-content: center;
  }

  .CMS-sharing-item {
    margin: var(--space-030);
  }
}

@media (min-width: 1200px) {
  .CMS-sharing-items {
    background: var(--colour-white);
    box-shadow: 0 5px 10px -1px rgba(59, 71, 104, 0.05), 0 2px 4px -3px #3b4768,
      0 2px 40px 0 rgba(59, 71, 104, 0.05),
      inset -10px 0 10px 0 rgba(59, 71, 104, 0.05);
    clip-path: polygon(-30% 0, 100% -30%, 100% 130%, -30% 100%);
    float: left;
    margin-left: -56px;
    margin-top: 74px;
    position: sticky;
    top: 74px;
    width: 56px;
  }

  .CMS-sharing-item {
    margin: var(--space-030) var(--space-020);
  }
}

@media (min-width: 768px) {
  .CMS-side-widget {
    border-radius: var(--border-radius-card-large);
    position: sticky;
    top: 72px;
  }
}

.CMS-side-widget-options {
  margin-left: calc(var(--space-005) * -1);
  margin-right: calc(var(--space-005) * -1);
}

.CMS-side-widget-option {
  flex: 1 0 45%;
}

.CMS-side-widget-option > span {
  width: 100% !important;
}

@media (min-width: 768px) {
  .AnchorNavigation-CMS {
    background: var(--colour-background-015);
    border-bottom: 1px solid var(--colour-background-025);
    border-radius: var(--border-radius-card-large)
      var(--border-radius-card-large) 0 0;
    font-size: 0.85rem;
    margin-bottom: var(--space-020);
    min-height: 35px;
  }

  .AnchorNavigation-CMS .f-t-tbody {
    font-size: 0.85rem;
  }
}

.CMS-block > img,
.rich-text > img {
  margin-bottom: var(--space-020);
  margin-top: var(--space-020);
}

.CMS-block .is-first-block p:first-of-type::first-letter {
  font-size: 3.6rem;
  float: left;
  line-height: 3.6rem;
  padding-right: 0.45rem;
}
