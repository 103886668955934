.FormDatePicker {
  box-sizing: border-box;
  border-radius: var(--border-radius-inputs);
  height: 49px;
  align-items: center;
  border: 1px solid #b8bbc3 !important;
}

.FormDatePicker .MuiInputBase-root.MuiOutlinedInput-root {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-020) var(--space-020) 0 var(--space-020);
}

.FormDatePicker .MuiOutlinedInput-notchedOutline {
  border: none;
  top: 0;
}

.FormDatePicker .MuiInputBase-input {
  color: black;
  font-family: var(--base-font-family);
  padding: 0;
}

.FormDatePicker .MuiFormControl-root.MuiTextField-root {
  width: 100%;
  height: 100%;
}

.FormDatePicker .MuiInputBase-root.MuiOutlinedInput-root {
  width: 100%;
  height: 100%;
}

.FormDatePicker
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
  position: absolute;
  right: 16px;
  top: 4px;
}

.FormDatePicker
  .MuiFormControl-root
  > .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root {
  left: var(--space-020);
  top: var(--space-010);
}

.FormDatePicker
  .MuiFormControl-root
  > .MuiInputLabel-root[data-shrink='true'].MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root {
  top: calc(var(--space-010) + var(--space-005));
  height: 24px;
}

.FormDatePicker .MuiInputLabel-formControl {
  transform: translate(0, 6px) scale(1);
  font-family: var(--base-font-family);
}

.FormDatePicker .MuiInputLabel-formControl.MuiInputLabel-shrink {
  transform: translate(0, -6px) scale(0.75);
  font-family: var(--base-font-family);
}

.FormDatePicker .MuiFormLabel-root {
  color: var(--colour-neutral-400);
}

.FormDatePicker .MuiFormLabel-root.Mui-focused {
  color: var(--colour-neutral-400);
}
