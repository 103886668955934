.FinanceEntry {
  background-repeat: no-repeat;
}

@media (max-width: 749px) {
  .FinanceEntry {
    background-image: url({{ CDN_S }}images/at_f/BudgetAssistant/finance-mobile-image.png);
    background-position: top center;
    background-size: auto 30%;
    padding-top: 88px;
  }
}

@media (min-width: 750px) {
  .FinanceEntry {
    background-position: right center;
    padding-left: var(--space-040);
    padding-top: var(--space-030);
    text-align: left;
  }
}

@media (min-width: 750px) and (max-width: 999px) {
  .FinanceEntry {
    background-image: url({{ CDN_S }}images/at_f/BudgetAssistant/finance-tablet-image.png);
  }
}

@media (min-width: 1000px) {
  .FinanceEntry {
    background-image: url({{ CDN_S }}images/at_f/BudgetAssistant/finance-desktop-image.png);
  }
}

@media (min-width: 750px) {
  .FinanceEntry-content {
    width: 60%;
  }
}

@media (min-width: 750px) {
  .FinanceEntry-subtitle {
    margin-top: var(--space-010);
  }
}

@media (max-width: 749px) {
  .FinanceEntry-cta {
    padding: 14px 4px;
    width: 100%;
  }
}

@media (min-width: 750px) {
  .FinanceEntry-cta {
    width: auto;
    padding: 14px var(--space-050);
  }
}

/* Search List Design */
@media (max-width: 679px) {
  .FinanceEntry-container-minimal {
    display: inline-block;
    /* 15px to fit in with listings */
    margin: 15px 15px 0;
    max-width: 360px;
    padding: 0;
    width: 100%;
  }
}

@media (min-width: 680px) {
  .FinanceEntry-container-minimal {
    margin-bottom: 15px;
  }
}

@media (min-width: 680px) {
  .FinanceEntry-minimal {
    background-image: url({{ CDN_S }}images/at_f/BudgetAssistant/finance-tablet-image.png);
    background-position: right center;
    background-size: 25% auto;
    padding: var(--space-030) var(--space-040);
  }
}

@media (min-width: 880px) {
  .FinanceEntry-minimal {
    background-image: none;
  }
}

@media (min-width: 680px) {
  .FinanceEntry-content-minimal {
    text-align: left;
  }
}

@media (min-width: 680px) and (max-width: 879px) {
  .FinanceEntry-content-minimal {
    width: 75%;
  }
}

@media (min-width: 880px) {
  .FinanceEntry-content-minimal {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

@media (min-width: 880px) {
  .FinanceEntry-subtitle-minimal {
    display: none;
  }
}

@media (max-width: 879px) {
  .FinanceEntry-cta-minimal {
    margin-top: var(--space-020);
  }
}

@media (min-width: 680px) {
  .FinanceEntry-cta-minimal {
    width: auto;
    padding: 14px 40px;
  }
}

/* CMS Category Page / Article Page Sidebar / House Detail Sidebar */
.FinanceEntry-tile {
  background-repeat: no-repeat;
  background-image: url({{ CDN_S }}images/at_f/BudgetAssistant/finance-mobile-image.png);
  background-position: top center;
  background-size: auto 30%;
  padding-top: 88px;
}

.FinanceEntry-cta-tile {
  padding: 14px 4px;
  width: 100%;
}

/* House Detail Page content */
@media (min-width: 880px) {
  .FinanceEntry-no-desktop-880 {
    display: none;
  }
}

/* House Detail Page sidebar */
@media (max-width: 879px) {
  .FinanceEntry-no-mobile-880 {
    display: none;
  }
}

@media (max-width: 979px) {
  .FinanceEntryPanel {
    overflow: scroll;
  }
}

@media (min-width: 700px) {
  .FinanceEntryPanel-card-container {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

@media (min-width: 700px) and (max-width: 979px) {
  .FinanceEntryPanel-card-container {
    flex-wrap: wrap;
  }
}

.FinanceEntryPanel-card {
  border-radius: var(--border-radius-card-large);
  border-style: solid;
  border-width: 2px;
  padding-left: 96px;
}

@media (min-width: 700px) {
  .FinanceEntryPanel-card {
    max-width: 300px;
    padding-top: 152px;
    padding-left: var(--space-020);
  }
}

@media (max-width: 699px) {
  .FinanceEntryPanel-icon {
    top: var(--space-040);
    left: var(--space-030);
  }
}

@media (min-width: 700px) {
  .FinanceEntryPanel-icon {
    height: 86px;
    left: 108px;
    top: 40px;
    width: 86px;
  }
}

@media (min-width: 700px) {
  .FinanceEntryPanel-subtitle {
    /* Horizontal alignment of CTAs across panel options */
    min-height: 78px;
  }
}

@media (max-width: 374px) {
  .FinanceEntryPanel-no-break {
    display: none;
  }
}

@media (min-width: 375px) {
  .FinanceEntryPanel-word-break {
    display: none;
  }
}

.FinanceEntryPanel-close {
  z-index: 1;
}

@media (max-width: 699px) {
  .FinanceEntryPanel-cta {
    width: 100%;
  }
}

@media (min-width: 700px) {
  .FinanceEntryPanel-cta {
    width: auto;
  }
}
