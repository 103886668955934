.CalculatorBlock {
  border: 1px solid #ededed;
}

@media (min-width: 750px) {
  .CalculatorBlock {
    display: flex;
  }
}

.CalculatorBlock-slider-area {
  padding: 32px 16px 16px;
  position: relative;
  text-align: center;
}

@media (min-width: 750px) {
  .CalculatorBlock-slider-area {
    flex: 1 1 60%;
    padding: 16px 32px;
  }
}

.CalculatorBlock-slider-area:after {
  content: '';
  background: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 6" preserveAspectRatio="none"%3E%3Cpolygon points="0,0 6,0 3,6" fill="%23225588" /%3E%3C/svg%3E')
    no-repeat 0 0 / 100% 100%;
  width: 100%;
  height: 32px;
  position: absolute;
  bottom: -32px;
  right: 0;
}

@media (min-width: 750px) {
  .CalculatorBlock-slider-area:after {
    background: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 6" preserveAspectRatio="none"%3E%3Cpolygon points="0,0 0,6 6,3" fill="%23225588" /%3E%3C/svg%3E')
      no-repeat 0 0 / 100% 100%;
    bottom: 0;
    height: 100%;
    right: -32px;
    width: 32px;
  }
}

.CalculatorBlock-slider-header {
  display: block;
  margin: 56px 0 12px;
}

@media (min-width: 750px) {
  .CalculatorBlock-slider-header {
    margin: 24px auto;
    width: 260px;
  }

  .CalculatorBlock-slider-header:before {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .CalculatorBlock-slider-header:after {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.CalculatorBlock-slider-calculation {
  display: block;
}

.CalculatorBlock-slider-monthly {
  display: block;
  margin-bottom: 16px;
}

.CalculatorBlock-slider-legal {
  display: block;
  max-width: 370px;
}

@media (min-width: 750px) {
  .CalculatorBlock .rc-slider:not(.FormSlider) {
    margin: 0 auto;
    max-width: 370px;
  }
}

.CalculatorBlock-result-area {
  padding: 48px 16px 32px;
  text-align: center;
}

@media (min-width: 750px) {
  .CalculatorBlock-result-area {
    display: flex;
    flex: 1 1 40%;
    flex-direction: column;
    justify-content: center;
    padding: 32px 32px 32px 56px;
  }
}

.CalculatorBlock-result-figure {
  display: block;
  margin-bottom: 24px;
}

.CalculatorBlock-result-area .button:not([class^='CTA-']):not([class*='f-']) {
  font-weight: 700;
  padding: 12px;
  width: 100%;
}

.CalculatorBlock-result-area
  .button:not([class^='CTA-']):not([class*='f-']).outline {
  border-width: 2px;
  padding: 10px;
}

.CalculatorBlock-result-area
  .button:not([class^='CTA-']):not([class*='f-'])
  + .button:not([class^='CTA-']):not([class*='f-']) {
  margin-top: 8px;
}

@media (min-width: 768px) {
  .f-g-c12\:m .CalculatorBlock {
    display: block;
  }
}
