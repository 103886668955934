.Card.Card {
  flex-shrink: 0;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease, opacity 0.2s ease;
  width: 264px;
}

.Card.Card.is-small {
  width: 224px;
}

.Card-image {
  width: 100%;
}

.Card-info {
  border-top-style: solid;
  border-top-width: 1px;
}

.Card-info-heading {
  height: 48px;
  overflow: hidden;
}

/* _you-might-also-like.html */
.Card-link {
  margin-top: auto;
}

/* _more_houses.html */
.Card-usp-label {
  border-top-left-radius: var(--border-radius-card-large);
  border-bottom-left-radius: var(--border-radius-card-large);
  right: 0;
  top: 10px;
}

/* _house-small.html */
.Card-info-house-and-vendor-name {
  flex: 1 0 60%;
}

.Card-info-vendor-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Card-info-vendor-logo-wrapper {
  flex: 1 0 40%;
  padding-left: var(--space-010);
  text-align: right;
}

.Card-info-vendor-logo {
  display: inline-block;
  max-height: var(--space-040);
  width: auto;
}

/* _house-small.html & _floorplan-small.html */
.Card-info-price {
  flex: 1 1 50%;
}

.Card-info-area {
  flex: 1 1 50%;
  text-align: right;
}

/* _vendor.html */
.Card-vendor {
  height: 262px;
  width: 100%;
}

.Card-vendor-logo-wrapper {
  left: 50%;
  max-height: 120px;
  max-width: 200px;
  position: absolute;
  pointer-events: none;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Card-vendor-logo {
  margin: auto;
  max-height: 100%;
}

/* CardLink Small (link to houseDetailPage) */

@media (max-width: 767px) {
  .CardLinkSmall-wrapper {
    margin-left: calc(var(--space-005) * -1);
    margin-right: calc(var(--space-005) * -1);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .CardLinkSmall-wrapper {
    margin-left: calc(var(--space-010) * -1);
    margin-right: calc(var(--space-010) * -1);
  }
}

@media (min-width: 1200px) {
  .CardLinkSmall-wrapper {
    margin-left: calc(var(--space-020) * -1);
    margin-right: calc(var(--space-020) * -1);
  }
}

.CardLinkSmall {
  box-shadow: var(--action-card-box-shadow);
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  width: 160px;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .CardLinkSmall:hover {
    box-shadow: var(--action-card-box-shadow-hover);
  }
}

@media (max-width: 359px) {
  .CardLinkSmall {
    width: 140px;
  }
}

@media (min-width: 360px) {
  .CardLinkSmall {
    width: 160px;
  }
}

@media (max-width: 767px) {
  .CardLinkSmall {
    margin: var(--space-005);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .CardLinkSmall {
    margin: var(--space-010);
  }
}

@media (min-width: 1200px) {
  .CardLinkSmall {
    margin: var(--space-020);
    flex: 0 0 160px;
  }
}

/* CardLink Large */
.CardLinkLarge-wrapper {
  max-width: 712px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.CardLinkLarge {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease, opacity 0.2s ease;
}

.CardLinkLarge-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 3;
  align-self: flex-start;
  height: 100%;
}

.CardLinkLarge-image_container {
  flex: 2;
}

.CardLinkLarge-description {
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* Overflow required to work for clamping */
  overflow: hidden;
}

.CardsLinkLarge-link {
  color: var(--colour-tertiary-400);
  font-family: var(--base-font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 166%; /* 23.24px */
  margin-top: auto;
}

.CardLinkLarge img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .CardLinkLarge {
    height: 180px;
    flex-direction: row;
  }

  .CardLinkLarge-image_container {
    flex: 1;
  }
}
