/*
  Fertighaus Icons – keep as agnostic as possible
  All prefixed with -icon
*/

.f-icon-f-none {
  fill: none;
}

.f-icon-005 {
  height: var(--space-005); /* 4px */
  width: var(--space-005); /* 4px */
}

.f-icon-010 {
  height: var(--space-010); /* 8px */
  width: var(--space-010); /* 8px */
}

.f-icon-015 {
  height: var(--space-015); /* 12px */
  width: var(--space-015); /* 12px */
}

.f-icon-020 {
  height: var(--space-020); /* 16px */
  width: var(--space-020); /* 16px */
}

.f-icon-025 {
  height: var(--space-025); /* 20px */
  width: var(--space-025); /* 20px */
}

.f-icon-030 {
  height: var(--space-030); /* 24px */
  width: var(--space-030); /* 24px */
}

.f-icon-040 {
  height: var(--space-040); /* 32px */
  width: var(--space-040); /* 32px */
}

.f-icon-050 {
  height: var(--space-050); /* 40px */
  width: var(--space-050); /* 40px */
}

.f-icon-060 {
  height: var(--space-060); /* 48px */
  width: var(--space-060); /* 48px */
}

.f-icon-070 {
  height: var(--space-070); /* 56px */
  width: var(--space-070); /* 56px */
}

.f-icon-080 {
  height: var(--space-080); /* 64px */
  width: var(--space-080); /* 64px */
}

.f-icon-100 {
  height: var(--space-100); /* 80px */
  width: var(--space-100); /* 80px */
}

.f-icon-120 {
  height: var(--space-120); /* 96px */
  width: var(--space-120); /* 96px */
}

.f-icon-320 {
  height: var(--space-320); /* 256px */
  width: var(--space-320); /* 256px */
}

.f-icon-010 use,
.f-icon-020 use,
.f-icon-025 use,
.f-icon-030 use,
.f-icon-040 use,
.f-icon-050 use,
.f-icon-060 use,
.f-icon-070 use {
  stroke-width: 1.5;
}

.f-icon-030.f-icon-st-010 use {
  stroke-width: 1;
}

.f-icon-080 use,
.f-icon-100 use,
.f-icon-120 use {
  stroke-width: 2;
}

.f-icon-320 use {
  stroke-width: 5;
}

/* IE hack */
@media all and (-ms-high-contrast: none) {
  .f-icon-f-none use {
    fill: none;
  }

  .f-icon-010.is-16 use {
    stroke-width: 1;
  }
  .f-icon-020.is-16 use {
    stroke-width: 1;
  }
  .f-icon-030.is-16 use {
    stroke-width: 0.7;
  }
  .f-icon-040.is-16 use {
    stroke-width: 0.6;
  }
  .f-icon-050.is-16 use {
    stroke-width: 0.6;
  }
  .f-icon-060.is-16 use {
    stroke-width: 0.5;
  }
  .f-icon-070.is-16 use {
    stroke-width: 0.5;
  }
  .f-icon-080.is-16 use {
    stroke-width: 0.4;
  }
  .f-icon-100.is-16 use {
    stroke-width: 0.4;
  }
  .f-icon-120.is-16 use {
    stroke-width: 0.3;
  }
  .f-icon-320.is-16 use {
    stroke-width: 0.1;
  }

  .f-icon-010.is-64 use {
    stroke-width: 4.5;
  }
  .f-icon-020.is-64 use {
    stroke-width: 4.5;
  }
  .f-icon-030.is-64 use {
    stroke-width: 3.5;
  }
  .f-icon-040.is-64 use {
    stroke-width: 2.8;
  }
  .f-icon-050.is-64 use {
    stroke-width: 2;
  }
  .f-icon-060.is-64 use {
    stroke-width: 1.9;
  }
  .f-icon-070.is-64 use {
    stroke-width: 1.7;
  }
  .f-icon-080.is-64 use {
    stroke-width: 1;
  }
  .f-icon-100.is-64 use {
    stroke-width: 0.8;
  }
  .f-icon-120.is-64 use {
    stroke-width: 0.666;
  }
  .f-icon-320.is-64 use {
    stroke-width: 0.25;
  }
}

/*
  These two classes should be slowly deprecated.
  If you need to vertically align an icon with text,
  use a flex container (f-d-flr or f-d-iflr) and margins
  on the icon. You'll find an example in
  _internal-link-element.html
*/
.f-icon-t-body {
  vertical-align: -2px;
}

.f-icon-t-mini {
  vertical-align: -3px;
}

/* Icon colours */
[class*='f-icon'] * {
  fill: none;
  stroke: none;
}

/* SVG use SVG g strokes */
.f-c-st-inherit * {
  stroke: currentColor;
}
.f-c-st-awa * {
  stroke: var(--colour-award);
}
.f-c-st-err * {
  stroke: var(--colour-error);
}
.f-c-st-war * {
  stroke: var(--colour-warning);
}
.f-c-st-neu050 * {
  stroke: var(--colour-neutral-050);
}
.f-c-st-neu100 * {
  stroke: var(--colour-neutral-100);
}
.f-c-st-neu200 * {
  stroke: var(--colour-neutral-200);
}
.f-c-st-bla * {
  stroke: var(--colour-black);
}
.f-c-st-pri * {
  stroke: var(--colour-primary-600);
}
.f-c-st-sec * {
  stroke: var(--colour-secondary);
}
.f-c-st-ter * {
  stroke: var(--colour-tertiary-400);
}
.f-c-st-whi * {
  stroke: var(--colour-white);
}
.f-c-st-suc100 * {
  stroke: var(--colour-success-100);
}
.f-c-st-suc400 * {
  stroke: var(--colour-success-400);
}
.f-c-st-neu200 * {
  stroke: var(--colour-neutral-200);
}
.f-c-st-neu400 * {
  stroke: var(--colour-neutral-400);
}

/* SVG Fills. */
.f-c-fi-pri-050 * {
  fill: var(--colour-primary-600-050);
}

.f-c-fi-pri * {
  fill: var(--colour-primary-600);
}
.f-c-fi-sec * {
  fill: var(--colour-secondary);
}
.f-c-fi-whi * {
  fill: var(--colour-white);
}
.f-c-fi-ter * {
  fill: var(--colour-tertiary-400);
}
.f-c-fi-pri400 * {
  fill: var(--colour-primary-400);
}
.f-c-fi-pri600 * {
  fill: var(--colour-primary-600);
}
.f-c-fi-neu50 * {
  fill: var(--colour-neutral-050);
}
.f-c-fi-neu100 * {
  fill: var(--colour-neutral-100);
}
.f-c-fi-paper025 * {
  fill: var(--colour-paper-025);
}
.f-c-fi-awa * {
  fill: var(--colour-award);
}
.f-c-fi-inherit * {
  fill: currentColor;
}
.f-c-fi-neu025 * {
  fill: var(--colour-neutral-025);
}
.f-c-fi-bla * {
  fill: var(--colour-black);
}
.f-c-fi-suc100 * {
  fill: var(--colour-success-100);
}
.f-c-fi-suc400 * {
  fill: var(--colour-success-400);
}
.f-c-fi-err * {
  fill: var(--colour-error);
}

.f-c-fi-bac025 * {
  fill: var(--colour-background-025);
}

/* Icon helpers */
.has-animated-chevron {
  transition: transform 0.2s ease;
}

.has-animated-chevron.is-switched {
  transform: rotate(180deg);
}
