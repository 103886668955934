.StaticContentBlock-graph-container {
  position: relative;
}

.StaticContentBlock-graph-y-axis {
  position: absolute;
  left: 0;
  height: 424px;
}

.StaticContentBlock-graph-body-container {
  overflow-y: scroll;
  /* Make room for scrollbar */
  padding-bottom: 16px;
}

.StaticContentBlock-graph-body {
  margin-left: 55px;
  min-width: 1064px;
  height: 424px;
}

.StaticContentBlock-graph-legend {
  max-height: 16px;
}

.StaticContentBlock-graph-heating-y-axis {
  position: absolute;
  left: 0;
  width: 48px;
}

.StaticContentBlock-graph-heating-body {
  margin-left: 55px;
  min-width: 1072px;
  height: 475px;
}

.StaticContentBlock-graph-state-capital-body {
  margin-left: 63px;
}
