@media (max-width: 767px) {
  .VendorOfficeDetail-wrap {
    padding-top: var(--space-020);
  }
}

.VendorOfficesPage-local-agent {
  border-style: solid;
  border-width: 1px;
  border-radius: var(--border-radius-card-large);
}

.VendorOfficesPage-local-agent-tag {
  left: 0;
  overflow: hidden;
  top: 0;
  text-align: left;
  text-overflow: ellipsis;
  right: 0;
  white-space: nowrap;
}

.VendorOfficesPage-agent-name {
  line-height: 1.33;
}

.VendorOfficesPage-phone-cta {
  line-height: 1.33;
  padding-left: 0;
  padding-right: 0;
}

.VendorOfficesPage-map-wrapper svg {
  display: block;
  width: 13rem;
}

.VendorOfficeSidebar {
  position: sticky;
  top: 84px;
}

.VendorOfficeSidebar-logo {
  max-width: 172px;
  max-height: 100px;
}

.VendorOfficesSidebar-icon-wrap {
  height: 42px;
  width: 42px;
}

.VendorOfficesSidebar-icon-wrap use {
  stroke-width: 1;
}

.VendorOfficeDetail-header.has-profile-picture {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 767px) {
  .VendorOfficeDetail-header.has-profile-picture {
    min-height: 200px;
  }
}

@media (min-width: 768px) {
  .VendorOfficeDetail-header {
    align-items: flex-end;
    display: flex;
  }

  .VendorOfficeDetail-header.has-profile-picture {
    min-height: 300px;
  }
}

.VendorOfficeDetail-logo-wrap {
  height: 100px;
  width: 100px;
  border-radius: var(--border-radius-card-large);
}

.VendorOfficeDetail-logo {
  max-height: 48px;
  max-width: 84px;
}

@media (max-width: 767px) {
  .VendorOfficeDetail-columns {
    display: block;
  }
}

.VendorOfficeDetail-story-photo {
  height: auto;
  border-radius: var(--border-radius-card-large);
}

@media (max-width: 767px) {
  .VendorOfficeDetail-story-photo {
    margin-bottom: var(--space-020);
    width: 100%;
  }
}

@media (min-width: 768px) {
  .VendorOfficeDetail-story-photo {
    width: 222px;
  }
}

.VendorOfficeDetail-map {
  height: 0;
  padding-top: 48%;
}

.VendorOfficeDetail-map:empty {
  display: none;
}
