.RequestWizard-wrapper {
  position: relative;
  height: 100%;
  margin: 0 auto;
  max-width: 640px;
  width: 100%;
}

.RequestWizard {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 512px;
  max-height: 100vh;
  width: 100%;
  padding: var(--space-080) var(--space-020) var(--space-020);
  background-color: var(--colour-background-025);
}

.RequestWizard--HDP_conversion-funnel {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: var(--space-080) var(--space-020) var(--space-020);
  background-color: var(--colour-white);
}

.RequestWizard__modal-close-button--HDP_conversion-funnel.RequestWizard__modal-close-button--HDP_conversion-funnel.RequestWizard__modal-close-button--HDP_conversion-funnel {
  background-color: var(--colour-white);
  opacity: 0.5;
  border-radius: 20px;
  padding: var(--space-010);
  display: flex;
}

.RequestWizard-form-wrapper.RequestWizard-form-wrapper--HDP_conversion-funnel
  .RequestWizard-bottom {
  margin-bottom: 32px;
}

.RequestWizard-top {
  flex: 1 1 auto;
  overflow: auto;
}

.RequestWizard-heading {
  padding-bottom: var(--space-040);
}

.RequestWizard-heading .MtanValidation-header {
  display: grid;
  grid-template-columns: minmax(100%, 600px);
}

.RequestWizard-content {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .RequestWizard-content,
  .RequestWizard-bottom {
    padding-left: var(--space-090);
    padding-right: var(--space-090);
  }
}

@media (min-height: 736px) {
  .RequestWizard-top.is-location {
    overflow: visible;
  }
}

.RequestWizard-bottom {
  flex: 0 0 auto;
  margin-top: auto;
  text-align: center;
}

.RequestWizard-form-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-height: 100%;
  min-height: 432px; /* This is here for IE11, it's 512 (min width of parent) minus paddings */
}

.RequestWizard-options-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: var(--space-020) -4px;
}

.QuestionRadio-button {
  position: relative;
  cursor: pointer;
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0;
  color: inherit;
}

.RequestWizard-options-wrapper .QuestionRadio-button {
  margin: var(--space-005);
}

@media (min-width: 768px) {
  .RequestWizard-options-wrapper .QuestionRadio-button {
    margin: var(--space-010);
  }
}

.RequestWizard-block-options-wrapper {
  justify-content: center;
  flex-wrap: wrap;
}

.RequestWizard-block-options-wrapper .f-fl-half {
  max-width: calc(50% - 8px);
}

.RequestWizard-block-options-wrapper,
.RequestWizard-block-options-wrapper ~ .FormError-wrapper {
  flex: 1 0 100%;
}

.RequestWizard-columns-wrapper {
  overflow: hidden;
}

.RequestWizard-columns {
  margin-left: -4px;
  margin-right: -4px;
}

.RequestWizard-column {
  flex: 1 0 50%;
  padding-left: 4px;
  padding-right: 4px;
}

.RequestWizard-progress-bar {
  position: absolute;
  height: 4px;
  left: 0;
  top: 0;
}

@media (min-width: 900px) {
  .RequestWizard--HDP_conversion-funnel {
    flex-direction: row-reverse;
    padding: 0;
    overflow: hidden;
    border-radius: var(--border-radius-card-large);
  }

  .RequestWizard-form-wrapper--HDP_conversion-funnel {
    flex: 2 0 560px;
    padding-top: 64px;
    border-radius: var(--border-radius-card-large);
    border-right: 4px solid white;
    z-index: 2;
  }

  .RequestWizard-wrapper--HDP_conversion-funnel {
    max-width: 1100px;
  }
  .RequestWizard-content-wrapper--HDP_conversion-funnel {
    max-width: 1100px;
    margin: 0 var(--space-020);
  }
}
