.FormCheckbox-wrapper.is-full-width {
  max-width: 100%;
  width: 100%;
}

.FormCheckbox-wrapper {
  position: relative;
}

.FormCheckbox {
  float: left;
  margin-left: -99999px;
}

.FormCheckbox:focus-within ~ .FormCheckbox-square svg {
  outline: 2px solid var(--colour-secondary);
  outline-offset: -2px;
}
.FormCheckbox:focus-within ~ .FormCheckbox-square svg.is-checked-icon {
  outline: 2px solid var(--colour-primary-600);
}

.FormCheckbox-square {
  float: left;
  height: 30px;
  margin-left: calc(var(--space-040) * -1);
  width: 30px;
}

.FormCheckbox-square-check {
  left: 0;
  position: absolute;
  top: 0;
  background-color: var(--colour-white);
  border: 2px solid var(--colour-neutral-100);
  padding-top: 5px;
  padding-right: 4px;
  padding-bottom: 5px;
  padding-left: 4px;
}

.FormCheckbox-square-check use {
  stroke-width: 2px;
}

.FormCheckbox-square-check.unchecked use {
  stroke-opacity: 50%;
}

.FormCheckbox-square-check.is-checked-icon {
  background: var(--radio-checked-color);
  border: 2px solid var(--radio-checked-color);
}

.FormCheckbox:disabled
  + .FormCheckbox-square
  .FormCheckbox-square-check.is-checked-icon {
  background: var(--colour-primary-600);
  border: 2px solid var(--colour-primary-600);
}

.FormCheckbox-wrapper-card {
  border-style: solid;
  border-width: 1px;
  user-select: none;
}

.FormCheckbox-wrapper-card.is-checked {
  border-color: var(--colour-primary-600);
}

[class*='FormCheckbox-wrapper-image-label-'] {
  border-style: solid;
  border-width: 1px;
  user-select: none;
}

[class*='FormCheckbox-wrapper-image-label-'].is-checked {
  border-color: var(--colour-primary-600);
}

.FormCheckbox-wrapper-image-label-checkmark {
  border: 0;
}

.FormCheckbox-wrapper-image-label-checkmark.is-checked::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border: 2px solid var(--colour-success-400);
  border-radius: 50%;
}

.FormCheckbox-wrapper-image-label-quiz.is-checked {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
}

.FormCheckbox-icon {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 2;
  border-radius: var(--border-radius-inputs);
}

.FormCheckbox-wrapper-image-label-small {
  width: 160px;
}

.FormCheckbox-wrapper-image-label-large {
  width: 256px;
}
.Formcheckbox-image-wrapper {
  margin: 0 auto;
  width: 140px;
  height: 68px;
}

.Formcheckbox-vendor-image {
  max-height: 68px;
  max-width: 140px;
  margin: 0 auto;
}

.FormCheckbox-wrapper-image-label-vendor {
  width: 160px;
  height: 120px;
  overflow: hidden;
}

.FormCheckbox-description {
  height: 6em;
}

.FormCheckbox-description-trigger {
  padding: var(--space-010);
  right: 0;
  top: var(--space-020);
}

.FormCheckbox-vendor-name {
  border-radius: 50%;
  height: 52px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.corner-icon {
  position: absolute;
  left: 5px;
  top: 5px;
}
