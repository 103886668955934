.VendorDetailPage-hero {
  height: 280px;
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  display: block;
  object-fit: cover;
}

.VendorDetailPage-hero picture {
  display: block;
  height: 280px;
}

.VendorDetailPage-hero picture img {
  object-fit: cover;
  height: 100%;
}

@media (min-width: 900px) {
  .VendorDetailPage-hero,
  .VendorDetailPage-hero picture {
    height: 480px;
  }
}

.VendorDetailPage-hero.is-premium {
  border-bottom-style: solid;
  border-bottom-width: var(--space-010);
}

.VendorDetailPage-intro {
  display: flex;
  flex-direction: column;
}

@media (min-width: 900px) {
  .VendorDetailPage-hero.is-premium {
    border-bottom-width: var(--space-020);
  }

  .VendorDetailPage-intro {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 16px;
    padding: 16px 0 24px 0;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .UserAccountPage * .rcb-toggle-button {
    bottom: 75px;
  }
}

.VendorDetailPage-details-logo-name {
  display: flex;
  column-gap: 8px;
}

.VendorDetailPage-logo-wrapper {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: var(--border-radius-card-large);
}

.VendorDetailPage-highlights-CTA {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
}

.VendorDetailPage-vendor-rating {
  background-color: #fff;
  padding: 16px 16px 0 16px;
  border-radius: var(--border-radius-card-large);
}

.js-vendor-rating {
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 32px;
  margin: 2px 0;
}

.js-vendor-rating svg {
  margin-right: 4px;
}

.js-vendor-rating svg:last-of-type {
  margin-right: 0;
}

@media (min-width: 900px) {
  .VendorDetailPage-logo-wrapper {
    flex: 0 0 200px;
    height: 200px;
    width: 200px;
    max-width: revert;
  }

  .VendorDetailPage-highlights-CTA {
    justify-content: end;
    align-items: flex-end;
  }

  .VendorDetailPage-franchise-banner-benefits {
    display: flex;
  }

  .VendorDetailPage-franchise-banner-benefit {
    margin-right: 16px;
  }

  .VendorDetailPage-details-logo-name {
    column-gap: 32px;
  }

  .VendorDetailPage-details-build {
    padding-top: 16px;
  }

  .VendorDetailPage-logo {
    left: 50%;
    max-height: 80%;
    max-width: 80%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .VendorDetailPage-vendor-rating {
    margin-bottom: 16px;
    padding-bottom: 16px;
    box-shadow: var(--action-card-box-shadow);
    width: 408px;
  }
}

.VendorDetailPage-display-name {
  margin: 0;
}

@media (max-width: 900px) {
  body.vendor-detail {
    text-align: left;
  }

  .VendorDetailPage-display-claim.f-t-paragraph {
    font-size: 12px;
    line-height: 1.2;
  }

  .VendorDetailPage-display-name {
    margin-top: var(--space-010);
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .VendorDetailPage-franchise-banner-text {
    width: 100%;
  }

  .VendorDetailPage-franchise-banner-benefit {
    margin-top: 8px;
  }

  .VendorDetailPage-logo-wrapper {
    padding: 10px;
    top: -16px;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 190px;
    height: 90px;
    width: 90px;
    max-width: 90px;
  }

  .VendorDetailPage-logo-wrapper img {
    max-height: 100%;
  }

  .VendorDetailPage-details {
    height: 74px;
    max-width: calc(100vw - 130px);
  }

  .VendorDetailPage-details-build {
    margin-left: -98px;
    margin-bottom: 16px;
    padding-top: 8px;
  }

  .VendorDetailPage-details-build > div {
    display: flex;
  }

  .VendorDetailPage-highlights-CTA {
    position: relative;
    left: 16px;
    margin-left: -32px;
    flex-direction: column-reverse;
  }

  .VendorDetailPage-highlights-CTA > button {
    margin-top: 0;
    width: calc(100% - 32px);
    margin-bottom: 16px;
  }

  .VendorDetailPage-vendor-rating {
    width: 100%;
    padding: 16px;
  }

  .js-vendor-rating {
    margin-bottom: 8px;
  }

  .VendorDetailPage-info-icons {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .VendorDetailPage-info-icons > :last-child:nth-child(odd) {
    grid-column: span 2;
  }
}

.VendorDetailPage-details-method.has-separator::before,
.VendorDetailPage-details-types-item.has-separator::before {
  content: ',';
  padding-right: var(--space-005);
}

.VendorDetailPage-details-build-svg {
  margin-right: var(--space-010);
  vertical-align: bottom;
}

.VendorDetailPage-franchise-banner {
  order: 3;
  width: 100%;
}

.VendorDetailPage-franchise-banner-logo-wrapper {
  border: 1px solid var(--colour-neutral-400);
  height: 68px;
  width: 121px;
}

.VendorDetailPage-CTA-wrapper {
  text-align: center;
  margin: var(--space-020) 0;
  padding-bottom: var(--space-020);
  order: 2;
}

@media (min-width: 900px) {
  .VendorDetailPage-CTA-wrapper {
    margin: var(--space-030) 0 0 auto;
  }
}

.VendorDetailPage-subsection {
  border-top-width: 1px;
  border-top-style: solid;
}

.VendorDetailPage-distinction-logo {
  height: 64px;
  width: 64px;
}

.VendorDetailPage-distinction-logo img {
  max-height: 100%;
  max-width: 100%;
}

.VendorDetailPage-info-icons {
  margin-left: calc(var(--space-020) * -1);
  margin-right: calc(var(--space-020) * -1);
}

.VendorDetailPage-info-icon {
  display: block;
}

@media (min-width: 900px) {
  .VendorDetailPage-info-icon {
    display: inline-block;
    min-width: 150px;
  }
}

.VendorDetailPage-info-icon-svg {
  height: 48px;
  width: 48px;
}

.VendorDetailPage-bg-svg {
  background-color: #bde9f9;
  background-image: url({{ CDN_S }}images/bg-pattern.svg);
  background-repeat: repeat;
  background-position: -20px -20px;
}

.VendorDetailPage-layout-two-columns {
  margin: 0 calc(var(--space-010) * -1);
  position: relative;
}

.VendorDetailPage-layout-two-columns.no-margin {
  margin: 0;
}

.VendorDetailPage-layout-two-columns.no-margin .VendorDetailPage-column {
  margin: 0 calc(var(--space-020) * -1);
  padding: 0;
}

@media (min-width: 900px) {
  .VendorDetailPage-layout-two-columns.no-margin .VendorDetailPage-column {
    margin: 0;
  }
  .VendorDetailPage-column-overlap {
    object-fit: cover;
    height: 100%;
  }
}

@media (min-width: 900px) {
  .VendorDetailPage-layout-two-columns {
    flex-wrap: nowrap;
  }

  .VendorDetailPage-layout-two-columns.has-columns-inverted {
    flex-direction: row-reverse;
  }
}

.VendorDetailPage-layout-two-columns .VendorDetailPage-column {
  flex: 1 1 100%;
  padding: 0 var(--space-010);
}

@media (min-width: 900px) {
  .VendorDetailPage-layout-two-columns .VendorDetailPage-column {
    flex: 1 1 50%;
  }
}

@media (min-width: 900px) {
  .VendorDetailPage-layout-two-columns.is-overlapping-column
    .VendorDetailPage-column {
    flex: 1 1 55%;
    margin-right: calc(var(--space-060) * -1);
  }
}

.VendorDetailPage-layout-two-columns.is-overlapping-column
  .VendorDetailPage-column
  + .VendorDetailPage-column {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  margin: calc(var(--space-030) * -1) var(--space-030) 0;
  flex: 1 1 auto;
  padding: var(--space-040);
}

@media (min-width: 900px) {
  .VendorDetailPage-layout-two-columns.is-overlapping-column
    .VendorDetailPage-column
    + .VendorDetailPage-column {
    flex: 1 1 45%;
    margin: var(--space-060) var(--space-010) var(--space-060) 0;
    padding: var(--space-060);
  }
}

@media (max-width: 900px) {
  .VendorDetailPage-column + .VendorDetailPage-column {
    margin-top: var(--space-020);
  }
}

.VendorDetailPage-layout-two-columns + .VendorDetailPage-layout-two-columns {
  margin-top: var(--space-080);
}

.VendorDetailPage-example-house-table {
  border-collapse: collapse;
}

.VendorDetailPage-example-house-table td {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.VendorDetailPage-video-thumbnails {
  margin-left: calc(var(--space-010) * -1);
  margin-right: calc(var(--space-010) * -1);
  overflow: auto;
  white-space: nowrap;
}

.VendorDetailPage-video-thumbnail-overlay {
  height: 32px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
}

.VendorDetailPage-video-thumbnail-button {
  background: transparent;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
}

.VendorDetailPage-video-thumbnail-img {
  height: 69px;
  width: 120px;
}

@media (min-width: 900px) {
  .VendorDetailPage-video-thumbnail-img {
    height: 80px;
    width: 140px;
  }
}

.VendorDetailPage-building-steps {
  max-width: 1000px;
}

.VendorDetailPage-building-steps .VendorDetailPage-building-step {
  list-style: none;
  margin: 0;
  padding: 0 0 var(--space-020) var(--space-060);
  position: relative;
}

@media (min-width: 900px) {
  .VendorDetailPage-building-steps .VendorDetailPage-building-step {
    padding: 0 140px var(--space-040);
  }
}

.VendorDetailPage-building-step:before {
  background: var(--colour-neutral-025);
  content: '';
  height: calc(100% + 16px);
  left: 16px;
  position: absolute;
  width: 4px;
}

@media (min-width: 900px) {
  .VendorDetailPage-building-step:before {
    left: 68px;
  }
}

.VendorDetailPage-building-step:last-child:before {
  content: none;
}

.VendorDetailPage-building-step:after {
  background: #fff;
  border: 3px solid var(--colour-neutral-025);
  border-radius: 100%;
  content: '';
  height: 27px;
  left: 4px;
  position: absolute;
  top: -2px;
  width: 27px;
}

@media (min-width: 900px) {
  .VendorDetailPage-building-step:after {
    left: 57px;
  }
}

.VendorDetailPage-building-step.is-first:after {
  border-color: var(--colour-secondary);
  border-width: 6px;
  height: 31px;
  left: 2px;
  top: -5px;
  width: 31px;
}

@media (min-width: 900px) {
  .VendorDetailPage-building-step.is-first:after {
    left: 54px;
  }
}

.VendorDetailPage-bottom-banner {
  padding: var(--space-040) 0;
  text-align: center;
}

@media (min-width: 900px) {
  .VendorDetailPage-bottom-banner {
    align-items: center;
    display: flex;
    padding: 80px 0;
  }
}

@media (min-width: 900px) {
  .VendorDetailPage-bottom-banner-intro {
    flex: 1;
  }
}

@media (min-width: 900px) {
  .VendorDetailPage-bottom-banner-text {
    flex: 1;
    text-align: left;
  }
}

@media (min-width: 900px) {
  .VendorDetailPage-bottom-banner-CTA-wrapper {
    flex: 1;
  }
}

.VendorDetailPage-sale-offices-wrapper {
  padding: var(--space-050) var(--space-020) !important;
}

.VendorDetailPage-location-section {
  overflow: hidden;
  border-radius: var(--border-radius-card-large);
}

@media (min-width: 900px) {
  .VendorDetailPage-sale-offices-wrapper {
    padding: var(--space-050) var(--space-080) !important;
  }

  .VendorDetailPage-location-section {
    padding: 0 !important;
  }
}

.sale-offices-results {
  border-top-style: solid;
  border-top-width: 1px;
}

.sale-offices-result {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.sale-offices-result > span {
  margin-right: 100px;
}

.sale-offices-result .sale-offices-result-link {
  float: right;
}

.sale-offices-map {
  height: 0;
  padding-top: 100%;
}

@media (min-height: 900px) {
  .sale-offices-map {
    height: 100%;
    padding-top: 0;
  }
}

.VendorDetailPage-footer {
  align-items: center;
}

@media (max-width: 900px) {
  .VendorDetailPage-footer {
    margin: 0 -16px;
  }
}

.VendorDetailPage-footer-logo-wrapper {
  flex: 1 0 40%;
  text-align: center;
}

@media (min-width: 900px) {
  .VendorDetailPage-footer-logo-wrapper {
    flex: 1 0 25%;
  }
}

.VendorDetailPage-footer-logo {
  max-height: 80px;
  max-width: 80px;
}

.VendorDetailPage-footer-info {
  flex: 1 0 60%;
}

@media (min-width: 900px) {
  .VendorDetailPage-footer-info {
    flex: 1 0 25%;
  }
}

.VendorDetailPage-footer-links {
  flex: 1 0 100%;
  text-align: center;
  padding: var(--space-030) var(--space-020) 0 var(--space-020);
}

@media (min-width: 900px) {
  .VendorDetailPage-footer-links {
    display: flex;
    flex: 1 0 50%;
    justify-content: center;
    padding-top: 0;
  }
}

@media (min-width: 900px) {
  .VendorDetailPage-footer-links .CTA-block-tertiary {
    order: 2;
  }
}

.VendorDetailPage-footer-social-links {
  align-items: center;
  margin: var(--space-030) 0 0;
}

@media (min-width: 900px) {
  .VendorDetailPage-footer-social-links {
    margin: 0 var(--space-040) 0 0;
    order: 1;
  }
}

@media (min-width: 900px) {
  .VendorDetailPage-footer-social-link-item {
    float: left;
  }
}

/* Helpers */
.InactiveVendorMessage-close {
  float: right;
}

.VendorDetailPage-sale-office-search-wrapper {
  display: block;
}

.VendorDetailPage-sale-office-search {
  border-style: solid;
  border-width: 1px;
}

.VendorDetailPage-description-text.VendorDetailPage-description-text--column-layout {
  column-count: auto;
  column-width: 430px;
}

.VendorDetailPage-description-text.VendorDetailPage-description-text--column-layout {
  orphans: 3;
}

.VendorDetailPage-introduction {
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
}

@media (min-width: 768px) {
  .VendorDetailPage-introduction {
    display: block;
  }

  .VendorDetailPage-introduction > :first-child:not(:last-child) {
    float: right;
    width: calc(66.66% - 8px);
    margin-left: 16px;
  }
}

@media (max-width: 900px) {
  .VendorDetailPage-description {
    /*order: 1;*/
  }
}

/* Vendor Microsite */
.VendorDetailPage-ms-nav {
  position: sticky;
  top: 0;
  z-index: 10;
}

.VendorDetailPage-ms-logo-wrapper {
  height: 66px;
  width: 120px;
}

.VendorDetailPage-ms-logo {
  margin: auto;
  max-height: 80%;
  max-width: 80%;
}

@media (max-width: 899px) {
  .VendorDetailPage-ms-menu-toggle-wrapper {
    margin-left: auto;
  }
}

@media (min-width: 900px) {
  .VendorDetailPage-ms-menu-toggle-wrapper {
    display: none;
  }
}

@media (max-width: 899px) {
  .VendorDetailPage-ms-menu-wrapper {
    display: none;
  }
}

@media (min-width: 900px) {
  .VendorDetailPage-ms-menu-wrapper {
    font-size: 16px;
    line-height: 18px;
  }

  .VendorDetailPage-ms-menu-item.is-active:after {
    background-color: currentColor;
    bottom: 0;
    content: '';
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
  }
}

.VendorDetailPage-ms-search .SearchResultsFilters {
  position: static;
}

@media (max-width: 899px) {
  .VendorMicrosite-menu-button.is-open:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 8px;
    bottom: -14px;
    content: ' ';
    height: 0;
    left: 50%;
    margin-left: -8px;
    pointer-events: none;
    position: absolute;
    width: 0;
  }

  .VendorMicrosite-menu {
    position: absolute;
    right: 0;
    top: 62px;
    width: 270px;
    z-index: 120;
  }
}

@media (max-width: 900px) {
  .VendorDetailPage-events-date {
    width: 80%;
  }
}

@media (min-width: 900px) {
  .VendorDetailPage-events-date {
    width: 152px;
  }
}

@media (max-width: 399px) {
  .VendorDetailPage-events-infobox {
    width: 100%;
  }
}

.VendorDetailPage-events-infobox {
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
}

.VendorDetailPage-events-infobox-icon {
  flex-shrink: 0;
}

.VendorDetailPage-summary-card {
  min-height: 378px;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .VendorDetailPage-bottom-banner-CTA-wrapper
    .CTA-block-tertiary.is-inverted:hover {
    --inverted-color: var(--branding-font-color, --colour-white);
    color: var(--inverted-color);
    border-color: var(--inverted-color);
  }
}

.VendorDetailPage-bottom-banner-CTA-wrapper .CTA-block-tertiary.is-inverted,
.VendorDetailPage-bottom-banner-CTA-wrapper
  .CTA-block-tertiary.is-inverted:active,
.VendorDetailPage-bottom-banner-CTA-wrapper
  .CTA-block-tertiary.is-inverted.is-active {
  --inverted-color: var(--branding-font-color, --colour-white);
  color: var(--inverted-color);
  border-color: var(--inverted-color);
}

/* Mosaic gallery */
.VendorDetailPage-mosaic-gallery_container {
  --gap: 16px;
  column-count: 1;
  gap: var(--gap);
}

.VendorDetailPage-mosaic-gallery_image-container {
  position: relative;
}

.VendorDetailPage-mosaic-gallery_container img {
  display: block;
  margin-bottom: var(--gap);
}

.VendorDetailPage-mosaic-gallery_description {
  position: absolute;
  top: 0;
  background-color: rgba(6, 59, 115, 0.75);
  text-shadow: 0px 0px 0.3px var(--text-main-on-dark);
  color: var(--text-main-on-dark);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  transition: opacity 300ms ease;
  opacity: 0;
}

.VendorDetailPage-mosaic-gallery_description:hover {
  opacity: 100%;
  cursor: pointer;
}

.VendorDetailPage-mosaic-gallery_description:active,
.VendorDetailPage-mosaic-gallery_description:focus {
  opacity: 0;
}

.VendorDetailPage-mosaic-gallery_description .f-t-body {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: 768px) {
  .VendorDetailPage-mosaic-gallery_container {
    column-count: 2;
  }
}

@media (min-width: 1200px) {
  .VendorDetailPage-mosaic-gallery_container {
    column-count: 3;
  }
}
