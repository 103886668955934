.TextArea {
  border: none;
  color: inherit;
  cursor: text;
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: 0;
  padding: 8px;
  resize: none;
  text-align: left;
  width: 100%;
  height: 6em;
}

.TextArea__title {
  font-size: 14px;
  color: var(--colour-neutral-400);
  margin-bottom: var(--space-005);
}
