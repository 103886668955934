/*
  Fertighaus Shame file.
  This is a place for hacks, temporary solutions and to copy
  paste old code before it gets refactored. It NEEDS to be
  refactored, so as a rule of thumb, putting stuff here means
  creating a ticket that addresses it.

  ಠ_ಠ
*/

/* We have two Table blocks, remove this once we decide which block we're keeping */
.Table-block-inner .RichContent-block {
  overflow-x: auto;
}

/* Paragraphs from the CMS with links */

[class*='f-t-body'] a:not([class*='CTA-']),
.RichContent-block p > a,
.Statement-block-content p > a {
  color: var(--colour-tertiary-400);
  text-decoration: underline;
}

/* Fullscreen Gallery */
.fullscreen-gallery-outer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.gallery-left,
.gallery-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30px;
  height: 40px;
  cursor: pointer;
  position: fixed;
  top: 50%;
  margin-top: -20px;
  background-color: rgba(9, 59, 94, 0.2);
  opacity: 0.8;
  align-items: normal;
  padding: 0;
  border: 0;
}
.gallery-left:hover,
.gallery-right:hover {
  background-color: rgba(9, 59, 94, 0.5);
}
.gallery-left {
  left: 0;
}
.gallery-right {
  right: 0;
}
.gallery-slide {
  cursor: zoom-out;
}
.gallery-slide-image {
  max-height: 80vh;
}
.fullscreen-gallery-close {
  right: 10px;
  top: 10px;
  width: 64px;
  height: 64px;
  z-index: 22;
  position: fixed;
  cursor: pointer;
  border: 0;
  background: none;
}
.close-gallery-icon {
  display: block;
  margin: 6px;
  width: 50px;
  height: 50px;
  background: url({{ CDN_S }}images/royalslider.png) 0 0;
  background-position: -24px 9px;
  border-radius: 2px;
}
.gallery__slide {
  margin: auto;
}
.glide {
  position: relative;
  width: 100%;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides__dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

/* Request Wizard */
.RequestWizard-wrapper .ActionCardTemplate-holder {
  padding-top: 24px;
  padding-bottom: 24px;
}
