[class*='HouseCategoriesSection-img-'] {
  height: auto;
  width: 100%;
}

@media (min-width: 768px) {
  .HouseCategoriesSection-large {
    height: calc(100% - var(--space-020));
  }

  .HouseCategoriesSection-img-large {
    /* The text area needs 70px */
    height: calc(100% - 70px);
    object-fit: cover;
    width: auto;
  }
}
