.BrandBadgesSection-images {
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: auto;
  flex-wrap: wrap;
}

.BrandBadgesSection-images--flat {
  flex-wrap: nowrap;
  justify-content: space-between;
}

.BrandBadgesSection-image {
  max-height: 40px;
}

.BrandBadgesSection-image--tall {
  max-height: 80px;
}
