.GardenWaterCalculatorWidget {
  max-width: 600px;
  padding: 16px;
  border-radius: var(--border-radius-card-large);
  background-color: var(--colour-background-015);
  box-shadow: var(--action-card-box-shadow);
}

.GardenWaterCalculatorWidget_FormWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
}

.GardenWaterCalculatorWidget_FormWrapper .FormText-wrapper .FormTextLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 15%);
}

.GardenWaterCalculatorWidget_FormWrapper
  .FormText-wrapper:focus-within
  .FormTextLabel {
  overflow: revert;
}

@media (min-width: 767px) {
  .GardenWaterCalculatorWidget_FormWrapper {
    display: grid;
  }

  .GardenWaterCalculatorWidget_Results {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .GardenWaterCalculatorWidget_FormWrapper .FormText-wrapper {
    margin-bottom: 8px;
  }

  .GardenWaterCalculatorWidget_FormWrapper:has(span.FormError-wrapper)
    .FormText-wrapper {
    margin-bottom: 4px;
  }

  .GardenWaterCalculatorWidget_FormWrapper .FormError-wrapper {
    margin-bottom: 8px;
  }

  .GardenWaterCalculatorWidget_FormWrapper button {
    justify-self: end;
  }
}
