/*
  Fertighaus Typography – keep as agnostic as possible
  All prefixed with -t for "typography"
*/

:root {
  --base-font-size: 16px;
  --base-font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';

  --fancy-font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

@font-face {
  font-display: swap;
  font-family: 'Lato';
  src: url('{{ CDN_S }}webfonts/lato/Lato-Regular.woff2') format('woff2'),
    url('{{ CDN_S }}webfonts/lato/Lato-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-display: swap;
  font-family: 'Lato';
  src: url('{{ CDN_S }}webfonts/lato/Lato-Bold.woff2') format('woff2'),
    url('{{ CDN_S }}webfonts/lato/Lato-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-display: swap;
  font-family: 'Gilroy';
  src: url('{{ CDN_S }}webfonts/lato/Gilroy-Bold.woff2') format('woff2'),
    url('{{ CDN_S }}webfonts/lato/Gilroy-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}

body {
  color: var(--colour-black);
  font-family: var(--base-font-family);
  font-weight: 400;
}

.f-t-fancy {
  font-family: var(--fancy-font-family);
}

.f-t-thero,
.f-t-tsection,
.f-t-tmedium,
.f-t-tminor,
.f-t-tbody {
  font-family: var(--fancy-font-family);
  font-weight: 700;
  line-height: 1.33;
  word-break: break-word;
}

@media (max-width: 767px) {
  /* XL H1 */
  .f-t-thero {
    font-size: 1.6875rem; /* 27px */
  }

  /* H1 */
  .f-t-tsection {
    font-size: 1.5rem; /* 24px */
  }

  /* H2 */
  .f-t-tmedium {
    font-size: 1.375rem; /* 22px */
  }
  /* H3 */
  .f-t-tminor {
    font-size: 1.1875rem; /* 19px */
  }
}

@media (min-width: 768px) {
  /* XL H1 */
  .f-t-thero {
    font-size: 2.125rem; /* 34px */
  }
  /* H1 */
  .f-t-tsection {
    font-size: 1.75rem; /* 28px */
  }
  /* H2 */
  .f-t-tmedium {
    font-size: 1.4375rem; /* 23px */
  }
  /* H3 */
  .f-t-tminor {
    font-size: 1.125rem; /* 18px */
  }

  .f-t-tbody {
    font-size: 1rem; /* 16px */
  }
}

.f-t-introhero,
.f-t-body,
.f-t-mini,
.f-t-micro,
.f-t-paragraph {
  font-family: var(--base-font-family);
  font-weight: 400;
  line-height: 1.66;
}

@media (max-width: 767px) {
  .f-t-introhero {
    font-size: 1.125rem; /* 18px */
  }
  .f-t-tbody {
    font-size: 1rem; /* 16px */
  }
}

@media (min-width: 768px) {
  .f-t-introhero {
    font-size: 1.5rem; /* 24px */
  }
}

.f-t-body {
  font-size: 1rem; /* 16px */
}

.f-t-mini {
  font-size: 0.875rem; /* 14px */
}

.f-t-micro {
  font-size: 0.75rem; /* 12px */
}

.f-t-paragraph {
  font-size: 1.125rem; /* 18px */
}

.f-t-bold {
  font-weight: 700;
}

.f-t-centre {
  text-align: center;
}

.f-t-left {
  text-align: left;
}

@media (min-width: 768px) {
  .f-t-centre\:m {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .f-t-left\:m {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .f-t-left\:l {
    text-align: left;
  }
}

.f-t-right {
  text-align: right;
}

@media (min-width: 768px) {
  .f-t-right\:m {
    text-align: right;
  }
}

.f-t-ellip {
  min-width: 0; /* Fixes bug when using inside a flexed container */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.f-t-nowrap {
  white-space: nowrap;
}

.f-t-input {
  /* To apply to <input />, <select /> and <textarea /> */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.f-t-lineheight\:133 {
  line-height: 1.33;
}

.f-t-lineheight\:166 {
  line-height: 1.66;
}
