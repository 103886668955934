.HouseCard_carousel-item-wrapper {
  flex: 1 0 100%;
  pointer-events: none;
  height: 100%;
  width: 100%;
  display: inline-block;
}

.HouseCard_carousel-item-image {
  height: 100%;
  width: 100%;
}

.HouseCard_carousel-container {
  border-radius: var(--border-radius-card-medium);
  overflow: hidden;
}

.HouseCard_carousel-container.HouseCard_carousel-container--with-thumbnails {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.HouseCard_carousel-wrapper {
  display: flex;
  flex-direction: row;
}

.HouseCard_details-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
  background-color: transparent;
}

.Slider-counter {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  background-color: white;
  position: absolute;
  z-index: 10;
  right: 16px;
  bottom: 16px;
  border-radius: 12px;
  line-height: 1;
}

.HouseCard_vendor-logo {
  width: 64px;
  height: 64px;
  background-color: white;
  font-size: 12px;
  border-radius: var(--border-radius-card-medium);
  box-shadow: 0px 2.286px 57.143px 0px rgba(59, 71, 104, 0.05);
}

.HouseCard_vendor-rating-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.HouseCard_vendor-rating-wrapper svg {
  flex-shrink: 0;
}

.HouseCard_thumbnail-item-wrapper:first-of-type {
  border-bottom-left-radius: 4px;
  overflow: hidden;
}

.HouseCard_thumbnail-item-wrapper:last-of-type {
  border-bottom-right-radius: 4px;
  overflow: hidden;
}

.HouseCard_thumbnail-item-wrapper--placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(243, 247, 251, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.HouseCard_thumbnail-item-wrapper--placeholder svg {
  width: 36px;
  height: 36px;
}

.HouseCard_wrapper {
  border-radius: var(--border-radius-card-medium);
}

.HouseCard_wrapper.is-top-placement::before {
  color: var(--colour-neutral-100);
  content: 'Gesponsert';
  font-size: 12px;
  left: 0;
  position: absolute;
  top: -20px;
  z-index: 20;
}

@media (max-width: 703px) {
  .HouseCard_wrapper.is-top-placement:not(.is-top-local-placement):nth-child(n
      + 2) {
    display: none;
  }
}

@media (min-width: 704px) and (max-width: 1055px) {
  .HouseCard_wrapper.is-top-placement:not(.is-top-local-placement):nth-child(n
      + 3) {
    display: none;
  }
}

@media (max-width: 359px) {
  .HouseCard_wrapper {
    width: calc(100% - 32px);
  }
}

@media (min-width: 360px) and (max-width: 1055px) {
  .HouseCard_wrapper {
    max-width: 360px;
    min-width: 320px;
    flex: 1 0 320px;
  }
}

@media (min-width: 1056px) {
  .HouseCard_wrapper {
    flex: 1 0 100%;
    max-width: calc(33.33333% - 32px);
  }
}

.HouseCard_add-to-favourite-cta {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  z-index: 10;
  transition: transform 0.3s;
}

.HouseCard_carousel-container--attentionBar .HouseCard_add-to-favourite-cta {
  top: 16px;
}

.HouseCard_add-to-favourite-cta:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.HouseCard_add-to-favourite-cta:active,
.HouseCard_add-to-favourite-cta:focus {
  outline: none;
}

.HouseCard_carousel-container--attentionBar::before {
  content: '';
  font-size: 12px;
  position: absolute;
  height: 8px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: var(--colour-primary-600);
  border-top-left-radius: var(--border-radius-card-medium);
  border-top-right-radius: var(--border-radius-card-medium);
}

.HouseCard_carousel-container--attentionBar.HouseCard_carousel-container--customAttentionBar::before {
  background-color: currentColor;
}

.HouseCard_wrapper .SliderArrow-next,
.HouseCard_wrapper .SliderArrow-prev {
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
  box-shadow: none;
}

.HouseCard_wrapper .SliderArrow-next {
  right: 8px;
}

.HouseCard_wrapper .SliderArrow-prev {
  left: 8px;
}

@media (min-width: 1024px) and (hover: hover) {
  .HouseCard_wrapper:hover .SliderArrow-next,
  .HouseCard_wrapper:hover .SliderArrow-prev {
    opacity: 0.75;
    box-shadow: none;
  }
}

:host {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
.HouseCard_carousel-container .swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
  display: block;
}
.HouseCard_carousel-container .swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}
.HouseCard_carousel-container .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(
    --swiper-wrapper-transition-timing-function,
    initial
  );
  box-sizing: content-box;
}
.HouseCard_carousel-container .swiper-android .swiper-slide,
.HouseCard_carousel-container .swiper-ios .swiper-slide,
.HouseCard_carousel-container .swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.HouseCard_carousel-container .swiper-horizontal {
  touch-action: pan-y;
}
.HouseCard_carousel-container .swiper-vertical {
  touch-action: pan-x;
}
.HouseCard_carousel-container .swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}
.HouseCard_carousel-container .swiper-slide-invisible-blank {
  visibility: hidden;
}
/* Auto Height */
.HouseCard_carousel-container .swiper-autoheight,
.HouseCard_carousel-container .swiper-autoheight .swiper-slide {
  height: auto;
}
.HouseCard_carousel-container .swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}
.HouseCard_carousel-container .swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
/* 3D Effects */
.HouseCard_carousel-container .swiper-3d.swiper-css-mode .swiper-wrapper {
  perspective: 1200px;
}
.HouseCard_carousel-container .swiper-3d .swiper-wrapper {
  transform-style: preserve-3d;
}
.HouseCard_carousel-container .swiper-3d {
  perspective: 1200px;
}
.HouseCard_carousel-container .swiper-3d .swiper-slide,
.HouseCard_carousel-container .swiper-3d .swiper-cube-shadow {
  transform-style: preserve-3d;
}
/* CSS Mode */
.HouseCard_carousel-container .swiper-css-mode > .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}
.HouseCard_carousel-container
  .swiper-css-mode
  > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}
.HouseCard_carousel-container
  .swiper-css-mode
  > .swiper-wrapper
  > .swiper-slide {
  scroll-snap-align: start start;
}
.HouseCard_carousel-container
  .swiper-css-mode.swiper-horizontal
  > .swiper-wrapper {
  scroll-snap-type: x mandatory;
}
.HouseCard_carousel-container
  .swiper-css-mode.swiper-vertical
  > .swiper-wrapper {
  scroll-snap-type: y mandatory;
}
.HouseCard_carousel-container
  .swiper-css-mode.swiper-free-mode
  > .swiper-wrapper {
  scroll-snap-type: none;
}
.HouseCard_carousel-container
  .swiper-css-mode.swiper-free-mode
  > .swiper-wrapper
  > .swiper-slide {
  scroll-snap-align: none;
}
.HouseCard_carousel-container
  .swiper-css-mode.swiper-centered
  > .swiper-wrapper::before {
  content: '';
  flex-shrink: 0;
  order: 9999;
}
.HouseCard_carousel-container
  .swiper-css-mode.swiper-centered
  > .swiper-wrapper
  > .swiper-slide {
  scroll-snap-align: center center;
  scroll-snap-stop: always;
}
.HouseCard_carousel-container
  .swiper-css-mode.swiper-centered.swiper-horizontal
  > .swiper-wrapper
  > .swiper-slide:first-child {
  margin-inline-start: var(--swiper-centered-offset-before);
}
.HouseCard_carousel-container
  .swiper-css-mode.swiper-centered.swiper-horizontal
  > .swiper-wrapper::before {
  height: 100%;
  min-height: 1px;
  width: var(--swiper-centered-offset-after);
}
.HouseCard_carousel-container
  .swiper-css-mode.swiper-centered.swiper-vertical
  > .swiper-wrapper
  > .swiper-slide:first-child {
  margin-block-start: var(--swiper-centered-offset-before);
}
.HouseCard_carousel-container
  .swiper-css-mode.swiper-centered.swiper-vertical
  > .swiper-wrapper::before {
  width: 100%;
  min-width: 1px;
  height: var(--swiper-centered-offset-after);
}
/* Slide styles start */
/* 3D Shadows */
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow,
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow-left,
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow-right,
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow-top,
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow-bottom,
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow,
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow-left,
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow-right,
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow-top,
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow {
  background: rgba(0, 0, 0, 0.15);
}
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.HouseCard_carousel-container .swiper-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.HouseCard_carousel-container .swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  box-sizing: border-box;
  border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
  border-radius: 50%;
  border-top-color: transparent;
}
.HouseCard_carousel-container
  .swiper:not(.swiper-watch-progress)
  .swiper-lazy-preloader,
.HouseCard_carousel-container
  .swiper-watch-progress
  .swiper-slide-visible
  .swiper-lazy-preloader {
  animation: swiper-preloader-spin 1s infinite linear;
}
.HouseCard_carousel-container .swiper-lazy-preloader-white {
  --swiper-preloader-color: #fff;
}
.HouseCard_carousel-container .swiper-lazy-preloader-black {
  --swiper-preloader-color: #000;
}
@keyframes swiper-preloader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Slide styles end */
.HouseCard_carousel-container .swiper-virtual .swiper-slide {
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
}
.HouseCard_carousel-container
  .swiper-virtual.swiper-css-mode
  .swiper-wrapper::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
.HouseCard_carousel-container
  .swiper-virtual.swiper-css-mode.swiper-horizontal
  .swiper-wrapper::after {
  height: 1px;
  width: var(--swiper-virtual-size);
}
.HouseCard_carousel-container
  .swiper-virtual.swiper-css-mode.swiper-vertical
  .swiper-wrapper::after {
  width: 1px;
  height: var(--swiper-virtual-size);
}
:root {
  --swiper-navigation-size: 44px;
  /*
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 10px;
  --swiper-navigation-color: var(--swiper-theme-color);
  */
}
.HouseCard_carousel-container .swiper-button-prev,
.HouseCard_carousel-container .swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}
.HouseCard_carousel-container .swiper-button-prev.swiper-button-disabled,
.HouseCard_carousel-container .swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.HouseCard_carousel-container .swiper-button-prev.swiper-button-hidden,
.HouseCard_carousel-container .swiper-button-next.swiper-button-hidden {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}
.HouseCard_carousel-container .swiper-navigation-disabled .swiper-button-prev,
.HouseCard_carousel-container .swiper-navigation-disabled .swiper-button-next {
  display: none !important;
}
.HouseCard_carousel-container .swiper-button-prev svg,
.HouseCard_carousel-container .swiper-button-next svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform-origin: center;
}
.HouseCard_carousel-container .swiper-rtl .swiper-button-prev svg,
.HouseCard_carousel-container .swiper-rtl .swiper-button-next svg {
  transform: rotate(180deg);
}
.HouseCard_carousel-container .swiper-button-prev,
.HouseCard_carousel-container .swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 10px);
  right: auto;
}
.HouseCard_carousel-container .swiper-button-next,
.HouseCard_carousel-container .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}
.HouseCard_carousel-container .swiper-button-lock {
  display: none;
}
/* Navigation font start */
.HouseCard_carousel-container .swiper-button-prev:after,
.HouseCard_carousel-container .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}
.HouseCard_carousel-container .swiper-button-prev:after,
.HouseCard_carousel-container .swiper-rtl .swiper-button-next:after {
  content: 'prev';
}
.HouseCard_carousel-container .swiper-button-next,
.HouseCard_carousel-container .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}
.HouseCard_carousel-container .swiper-button-next:after,
.HouseCard_carousel-container .swiper-rtl .swiper-button-prev:after {
  content: 'next';
}
/* Navigation font end */
:root {
  /*
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0,0,0,0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-border-radius: 50%;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
  */
}
.HouseCard_carousel-container .swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.HouseCard_carousel-container .swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
.HouseCard_carousel-container .swiper-pagination-disabled > .swiper-pagination,
.HouseCard_carousel-container .swiper-pagination.swiper-pagination-disabled {
  display: none !important;
}
/* Common Styles */
.HouseCard_carousel-container .swiper-pagination-fraction,
.HouseCard_carousel-container .swiper-pagination-custom,
.HouseCard_carousel-container .swiper-horizontal > .swiper-pagination-bullets,
.HouseCard_carousel-container
  .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
}
/* Bullets */
.HouseCard_carousel-container .swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.HouseCard_carousel-container
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
}
.HouseCard_carousel-container
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active {
  transform: scale(1);
}
.HouseCard_carousel-container
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-main {
  transform: scale(1);
}
.HouseCard_carousel-container
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}
.HouseCard_carousel-container
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.HouseCard_carousel-container
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.HouseCard_carousel-container
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}
.HouseCard_carousel-container .swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 8px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 8px)
  );
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}
.HouseCard_carousel-container button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
}
.HouseCard_carousel-container
  .swiper-pagination-clickable
  .swiper-pagination-bullet {
  cursor: pointer;
}
.HouseCard_carousel-container .swiper-pagination-bullet:only-child {
  display: none !important;
}
.HouseCard_carousel-container .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}
.HouseCard_carousel-container .swiper-vertical > .swiper-pagination-bullets,
.HouseCard_carousel-container
  .swiper-pagination-vertical.swiper-pagination-bullets {
  right: var(--swiper-pagination-right, 8px);
  left: var(--swiper-pagination-left, auto);
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}
.HouseCard_carousel-container
  .swiper-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.HouseCard_carousel-container
  .swiper-pagination-vertical.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
  display: block;
}
.HouseCard_carousel-container
  .swiper-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.HouseCard_carousel-container
  .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}
.HouseCard_carousel-container
  .swiper-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet,
.HouseCard_carousel-container
  .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  display: inline-block;
  transition: 200ms transform, 200ms top;
}
.HouseCard_carousel-container
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.HouseCard_carousel-container
  .swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}
.HouseCard_carousel-container
  .swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.HouseCard_carousel-container
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.HouseCard_carousel-container
  .swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet,
.HouseCard_carousel-container
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: 200ms transform, 200ms left;
}
.HouseCard_carousel-container
  .swiper-horizontal.swiper-rtl
  > .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right;
}
/* Fraction */
.HouseCard_carousel-container .swiper-pagination-fraction {
  color: var(--swiper-pagination-fraction-color, inherit);
}
/* Progress */
.HouseCard_carousel-container .swiper-pagination-progressbar {
  background: var(
    --swiper-pagination-progressbar-bg-color,
    rgba(0, 0, 0, 0.25)
  );
  position: absolute;
}
.HouseCard_carousel-container
  .swiper-pagination-progressbar
  .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}
.HouseCard_carousel-container
  .swiper-rtl
  .swiper-pagination-progressbar
  .swiper-pagination-progressbar-fill {
  transform-origin: right top;
}
.HouseCard_carousel-container
  .swiper-horizontal
  > .swiper-pagination-progressbar,
.HouseCard_carousel-container
  .swiper-pagination-progressbar.swiper-pagination-horizontal,
.HouseCard_carousel-container
  .swiper-vertical
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.HouseCard_carousel-container
  .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: var(--swiper-pagination-progressbar-size, 4px);
  left: 0;
  top: 0;
}
.HouseCard_carousel-container .swiper-vertical > .swiper-pagination-progressbar,
.HouseCard_carousel-container
  .swiper-pagination-progressbar.swiper-pagination-vertical,
.HouseCard_carousel-container
  .swiper-horizontal
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.HouseCard_carousel-container
  .swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite {
  width: var(--swiper-pagination-progressbar-size, 4px);
  height: 100%;
  left: 0;
  top: 0;
}
.HouseCard_carousel-container .swiper-pagination-lock {
  display: none;
}
:root {
  /*
  --swiper-scrollbar-border-radius: 10px;
  --swiper-scrollbar-top: auto;
  --swiper-scrollbar-bottom: 4px;
  --swiper-scrollbar-left: auto;
  --swiper-scrollbar-right: 4px;
  --swiper-scrollbar-sides-offset: 1%;
  --swiper-scrollbar-bg-color: rgba(0, 0, 0, 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(0, 0, 0, 0.5);
  --swiper-scrollbar-size: 4px;
  */
}
.HouseCard_carousel-container .swiper-scrollbar {
  border-radius: var(--swiper-scrollbar-border-radius, 10px);
  position: relative;
  touch-action: none;
  background: var(--swiper-scrollbar-bg-color, rgba(0, 0, 0, 0.1));
}
.HouseCard_carousel-container .swiper-scrollbar-disabled > .swiper-scrollbar,
.HouseCard_carousel-container .swiper-scrollbar.swiper-scrollbar-disabled {
  display: none !important;
}
.HouseCard_carousel-container .swiper-horizontal > .swiper-scrollbar,
.HouseCard_carousel-container .swiper-scrollbar.swiper-scrollbar-horizontal {
  position: absolute;
  left: var(--swiper-scrollbar-sides-offset, 1%);
  bottom: var(--swiper-scrollbar-bottom, 4px);
  top: var(--swiper-scrollbar-top, auto);
  z-index: 50;
  height: var(--swiper-scrollbar-size, 4px);
  width: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
}
.HouseCard_carousel-container .swiper-vertical > .swiper-scrollbar,
.HouseCard_carousel-container .swiper-scrollbar.swiper-scrollbar-vertical {
  position: absolute;
  left: var(--swiper-scrollbar-left, auto);
  right: var(--swiper-scrollbar-right, 4px);
  top: var(--swiper-scrollbar-sides-offset, 1%);
  z-index: 50;
  width: var(--swiper-scrollbar-size, 4px);
  height: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
}
.HouseCard_carousel-container .swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: var(--swiper-scrollbar-drag-bg-color, rgba(0, 0, 0, 0.5));
  border-radius: var(--swiper-scrollbar-border-radius, 10px);
  left: 0;
  top: 0;
}
.HouseCard_carousel-container .swiper-scrollbar-cursor-drag {
  cursor: move;
}
.HouseCard_carousel-container .swiper-scrollbar-lock {
  display: none;
}
/* Zoom container styles start */
.HouseCard_carousel-container .swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.HouseCard_carousel-container .swiper-zoom-container > img,
.HouseCard_carousel-container .swiper-zoom-container > svg,
.HouseCard_carousel-container .swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
/* Zoom container styles end */
.HouseCard_carousel-container .swiper-slide-zoomed {
  cursor: move;
  touch-action: none;
}
/* a11y */
.HouseCard_carousel-container .swiper .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
.HouseCard_carousel-container .swiper-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.HouseCard_carousel-container .swiper-grid > .swiper-wrapper {
  flex-wrap: wrap;
}
.HouseCard_carousel-container .swiper-grid-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
}
.HouseCard_carousel-container .swiper-fade.swiper-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}
.HouseCard_carousel-container .swiper-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
}
.HouseCard_carousel-container .swiper-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.HouseCard_carousel-container .swiper-fade .swiper-slide-active {
  pointer-events: auto;
}
.HouseCard_carousel-container
  .swiper-fade
  .swiper-slide-active
  .swiper-slide-active {
  pointer-events: auto;
}
.HouseCard_carousel-container .swiper-cube {
  overflow: visible;
}
.HouseCard_carousel-container .swiper-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.HouseCard_carousel-container .swiper-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}
.HouseCard_carousel-container .swiper-cube.swiper-rtl .swiper-slide {
  transform-origin: 100% 0;
}
.HouseCard_carousel-container .swiper-cube .swiper-slide-active,
.HouseCard_carousel-container
  .swiper-cube
  .swiper-slide-active
  .swiper-slide-active {
  pointer-events: auto;
}
.HouseCard_carousel-container .swiper-cube .swiper-slide-active,
.HouseCard_carousel-container .swiper-cube .swiper-slide-next,
.HouseCard_carousel-container .swiper-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}
.HouseCard_carousel-container .swiper-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: 0;
}
.HouseCard_carousel-container .swiper-cube .swiper-cube-shadow:before {
  content: '';
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  filter: blur(50px);
}
.HouseCard_carousel-container .swiper-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}
/* Cube slide shadows start */
.HouseCard_carousel-container
  .swiper-cube
  .swiper-slide-shadow-cube.swiper-slide-shadow-top,
.HouseCard_carousel-container
  .swiper-cube
  .swiper-slide-shadow-cube.swiper-slide-shadow-bottom,
.HouseCard_carousel-container
  .swiper-cube
  .swiper-slide-shadow-cube.swiper-slide-shadow-left,
.HouseCard_carousel-container
  .swiper-cube
  .swiper-slide-shadow-cube.swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
/* Cube slide shadows end */
.HouseCard_carousel-container .swiper-flip {
  overflow: visible;
}
.HouseCard_carousel-container .swiper-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.HouseCard_carousel-container .swiper-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.HouseCard_carousel-container .swiper-flip .swiper-slide-active,
.HouseCard_carousel-container
  .swiper-flip
  .swiper-slide-active
  .swiper-slide-active {
  pointer-events: auto;
}
/* Flip slide shadows start */
.HouseCard_carousel-container
  .swiper-flip
  .swiper-slide-shadow-flip.swiper-slide-shadow-top,
.HouseCard_carousel-container
  .swiper-flip
  .swiper-slide-shadow-flip.swiper-slide-shadow-bottom,
.HouseCard_carousel-container
  .swiper-flip
  .swiper-slide-shadow-flip.swiper-slide-shadow-left,
.HouseCard_carousel-container
  .swiper-flip
  .swiper-slide-shadow-flip.swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
/* Flip slide shadows end */
.HouseCard_carousel-container .swiper-creative .swiper-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  transition-property: transform, opacity, height;
}
.HouseCard_carousel-container .swiper-cards {
  overflow: visible;
}
.HouseCard_carousel-container .swiper-cards .swiper-slide {
  transform-origin: center bottom;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}
