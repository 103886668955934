/** rc-slider */
.FormSlider {
  border-radius: var(--border-radius-inputs);
  height: 24px;
  margin: 0 12px;
  padding: 8px 0;
  position: relative;
  touch-action: none;
}

.FormSlider * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.FormSlider .rc-slider-rail {
  background-color: var(--colour-neutral-025);
  border-radius: var(--border-radius-card-large);
  height: 8px;
  left: 0;
  position: absolute;
  right: -8px;
}

.FormSlider .rc-slider-track {
  background-color: var(--colour-tertiary-400);
  border-radius: var(--border-radius-inputs);
  height: 8px;
  left: 0;
  position: absolute;
}

.FormSlider .rc-slider-step {
  background: transparent;
  height: 8px;
  position: absolute;
  width: 100%;
}

.FormSlider .rc-slider-handle {
  background-color: var(--colour-white);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border: 3px solid var(--colour-tertiary-400);
  border-radius: 50%;
  cursor: pointer;
  cursor: grab;
  height: 22px;
  margin-top: -7px;
  outline: none;
  position: absolute;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
  width: 22px;
}

.FormSlider .rc-slider-handle:focus-visible {
  outline: 2px solid var(--colour-secondary);
}

.FormSlider .rc-slider-mark {
  font-size: 12px;
  left: 0;
  position: absolute;
  top: 32px;
  width: 100%;
}

.FormSlider .rc-slider-mark-text {
  cursor: pointer;
  color: var(--colour-white);
  display: inline-block;
  position: absolute;
  text-align: center;
  vertical-align: middle;
}

.FormSlider .rc-slider-mark-text {
  pointer-events: none;
}

@media (max-width: 767px) {
  .FormSlider .rc-slider-mark-text {
    display: none;
  }

  .FormSlider .rc-slider-mark-text:first-child {
    display: inline-block;
  }

  .FormSlider .rc-slider-mark-text:last-child {
    display: inline-block;
  }
}
