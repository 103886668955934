@media (min-width: 768px) {
  .HousePriceSection-card {
    height: calc(100% - var(--space-020));
  }
}

.HousePriceSection-img {
  height: auto;
  width: 100%;
}
