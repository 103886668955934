@media (max-width: 767px) {
  .QuizWidget {
    justify-content: center;
  }
}

@media (max-width: 979px) {
  .QuizWidget {
    max-width: 400px;
  }
}
