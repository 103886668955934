.react-tabs__tab--selected {
  outline: none;
}

.TabsGenerator-tab-list {
  display: flex;
  overflow: scroll;
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
}

@media (min-width: 768px) {
  .TabsGenerator-tab-list {
    /* Ties left most tab to left part of screen, probably */
    padding-left: calc(var(--base-unit) * 2);
  }
}

.TabsGenerator-tab-item {
  border: none;
  background: none;
  white-space: nowrap;
  outline: none;
  font-size: var(--base-font-size); /* 16px */
  font-weight: 400;
  line-height: calc(var(--base-font-size) * 1.5);
  padding: var(--space-020, --spacing-24px);
  min-width: 136px;
  color: var(--colour-black);
  cursor: pointer;
}

.TabsGenerator-tab-item:active {
  background-color: none;
}

.TabsGenerator-tab-item:disabled {
  opacity: 0.25;
  cursor: default;
}

.TabsGenerator-tab-item.react-tabs__tab--selected {
  font-weight: 700;
  border-bottom: solid 2px var(--colour-award);
  color: var(--colour-primary-600);
}
