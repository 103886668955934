/* This template inherits all the CSS from CMSArticlePage.css */
.DictionaryAlphabet-item {
  margin: var(--space-010);
}

.DictionaryAlphabet-item + .DictionaryAlphabet-item:before {
  content: '|';
  color: var(--colour-neutral-100);
  left: calc(var(--space-010) * -1);
  position: relative;
  top: -1px;
}
