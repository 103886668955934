.exclusive-leads-page-content__vendor-ratings {
  border-radius: var(--border-radius-card-large);
  border: 1px solid var(--colour-neutral-100);
  padding: var(--space-030);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.exclusive-leads-page-content__vendor-ratings-badge {
  width: 120px;
  text-align: center;
  flex-shrink: 0;
}

.exclusive-leads-page-content__vendor-ratings-text {
  word-break: initial;
}

.exclusive-leads-page-content__vendor-ratings-division {
  height: 40px;
  width: 1px;
  background: var(--colour-neutral-100);
}

.exclusive-leads-page-content__vendor-ratings-benefits-icon {
  width: 32px;
  height: 32px;
}

@media (max-width: 768px) {
  .exclusive-leads-page-content__vendor-ratings {
    flex-direction: column;
  }
}

@media (max-width: 1160px) {
  .exclusive-leads-page-content__vendor-ratings-text {
    margin: var(--space-020) 0;
    text-align: center;
  }
}
