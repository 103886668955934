.ValueSection-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ValueSection-card-list {
  margin-top: 64px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 72px;
  column-gap: 16px;
}
.ValueSection-card-list__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 64px 16px 32px;
  background-color: #fff;
  box-shadow: 0px 15px 30px rgba(59, 71, 104, 0.15);
  border-radius: var(--border-radius-card-large);
}

.ValueSection-card-list__item-icon-wrapper {
  background-color: var(--colour-background-025);
  border-radius: 50%;
  padding: 4px;
  position: absolute;
  top: -50px;
}

.ValueSection-card-list__item-icon {
  display: flex;
  background-color: var(--colour-primary-600);
  border-radius: 50%;
  padding: 14px;
}

.ValueSection-card-list__item-content {
  margin-top: 16px;
  text-align: center;
}

.ValueSection-footer {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 600px) {
  .ValueSection-card-list__item {
    width: 48%; /* 2 items per row */
  }
}

@media (min-width: 1160px) {
  .ValueSection-card-list__item {
    width: 23%; /* 4 cards per row + spacings */
  }
}
