.ListNestedArticles {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.ListNestedArticle {
  width: 540px;
  height: 192px;
  border-radius: var(--border-radius-card-large);
  background: var(--colour-white);
  box-shadow: var(--action-card-box-shadow);
  display: flex;
}

.ListNestedArticle:hover {
  box-shadow: var(--action-card-box-shadow-hover);
}

.ListNestedArticle__wrapper {
  color: inherit;
  text-decoration: none;
}

.ListNestedArticles__img-wrapper {
  flex-shrink: 0;
  width: 192px;
  height: 180px;
  background: var(--colour-white);
}

.ListNestedArticle__img {
  width: 192px;
  height: 192px;
  object-fit: cover;
  border-radius: var(--border-radius-card-large) var(--border-radius-card-large)
    0 0;
}

.ListNestedArticle__title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.33;
}

.ListNestedArticle__description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.ListNestedArticle__content {
  flex: 1;
}

@media (max-width: 1150px) {
  .ListNestedArticle {
    width: 100%;
  }
  .ListNestedArticle__wrapper {
    flex: 0 0 100%;
  }
}
@media (max-width: 767px) {
  .ListNestedArticles__img-wrapper {
    width: 118px;
  }
}
