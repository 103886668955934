.MuiTextField-root > .MuiInputLabel-root[data-shrink='true'] {
  top: var(--space-020);
}

.MuiTextField-root
  > .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-outlined {
  top: calc(0 - var(--space-005));
  color: var(--colour-neutral-200);
}

.MuiTextField-root.form-phone-input
  > .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-outlined {
  left: calc(var(--space-090));
  width: calc(100% - 90px);
}
