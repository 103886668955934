.StairsCalculatorWidget {
  max-width: 670px;
  padding: 16px;
  border-radius: var(--border-radius-card-large);
  background-color: var(--colour-background-015);
  box-shadow: var(--action-card-box-shadow);
}

.StairsCalculatorWidget_OptionButtonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.StairsCalculatorWidget_divider {
  height: 1px;
  background-color: var(--colour-neutral-050);
}

.StairsCalculatorWidget_FormWrapper .FormText-wrapper .FormTextLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 15%);
}

.StairsCalculatorWidget_FormWrapper
  .FormText-wrapper:focus-within
  .FormTextLabel {
  overflow: revert;
}

@media (min-width: 767px) {
  .StairsCalculatorWidget_FormWrapper {
    display: grid;
    grid-template-columns: 3fr 3fr max-content;
    column-gap: 16px;
  }

  .StairsCalculatorWidget_Results {
    grid-column: 1 / -1;
  }

  .StairsCalculatorWidget_FormWrapper .FormText-wrapper {
    margin-bottom: 0;
  }

  .StairsCalculatorWidget_FormWrapper:has(span.FormError-wrapper)
    .FormText-wrapper {
    margin-bottom: 8px;
  }

  .StairsCalculatorWidget_FormWrapper .FormError-wrapper {
    margin-bottom: 0;
  }
}
