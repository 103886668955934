.FormPhone-wrapper {
  padding-top: 11px; /* Weird override */
}

.FormPhone-country-select {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  bottom: 0;
  color: rgba(0, 0, 0, 0.0001) !important;
  left: 0;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.FormPhone-country-select::-ms-expand {
  display: none;
}

.FormPhone-country-select::-ms-value {
  background: transparent;
  color: rgba(0, 0, 0, 0.0001) !important;
}

.FormPhone-country-select option {
  color: var(--colour-black);
  user-select: none;
}

.FormPhone-country-select-flag {
  display: inline-block;
  vertical-align: text-bottom;
  width: 24px;
  /* IE */
  max-height: 20px;
}

.FormPhone-number {
  width: calc(100% - 92px);
}

.FormPhone-number .FormText {
  top: 8px;
}
