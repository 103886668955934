/* Foregrounds. Add on-need basis. */
.f-c-fo-pri {
  color: var(--text-main-on-light);
}
.f-c-fo-pri400 {
  color: var(--colour-primary-400);
}
.f-c-fo-sec {
  color: var(--colour-secondary);
}
.f-c-fo-mut {
  color: var(--colour-neutral-400);
}
.f-c-fo-ter {
  color: var(--colour-tertiary-400);
}
.f-c-fo-whi {
  color: var(--colour-white);
}
.f-c-fo-suc {
  color: var(--colour-primary-600);
}
.f-c-fo-err {
  color: var(--colour-error);
}
.f-c-fo-accent-on-dark {
  color: var(--text-accent-on-dark);
}
.f-c-fo-accent-on-light {
  color: var(--text-accent-on-light);
}

/* Backgrounds. Add on-need basis. */
.f-c-ba-pri {
  background-color: var(--colour-primary-600);
}
.f-c-ba-sec {
  background-color: var(--colour-secondary);
}
.f-c-ba-whi {
  background-color: var(--colour-white);
}

/* Borders. Add on-need basis. */
.f-c-bo-pri {
  border-color: var(--colour-primary-600);
}
.f-c-bo-sec {
  border-color: var(--colour-secondary);
}
.f-c-bo-neu100 {
  border-color: var(--colour-neutral-100);
}
