.SingleImage-zoom {
  border: none;
  padding: 0;
}

.SingleImage-zoom:hover {
  cursor: zoom-in;
}

.SingleImage-image {
  width: 100%;
}

.SingleImage-zoom-icon {
  position: absolute;
  bottom: 8px;
  right: 8px;
}
