@media print {
  body.has-modal {
    position: initial;
    top: 0;
  }
  #ModalWrapper,
  footer,
  header,
  .PageFooterLinks,
  div.MuiTabs-root,
  .detail-overview__expand-button {
    display: none !important;
  }
}

.expose-download_content-wrapper {
  padding: 0 32px;
}

@media (min-width: 900px) {
  .expose-download_content-wrapper {
    padding: 0 64px;
  }
}
