.BannerWrapper {
  position: fixed;
  z-index: 99;
}

@media (max-width: 767px) {
  .BannerWrapper {
    bottom: 0;
    right: 0;
  }
}

@media (min-width: 768px) {
  .BannerWrapper {
    bottom: var(--space-040);
    right: var(--space-040);
  }
}

.Banner {
  bottom: 0;
  position: absolute;
  right: 0;
}

.BannerContent {
  max-width: 640px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

@media (max-width: 767px) {
  .BannerWrapperFloating {
    left: 0;
    right: 0;
  }
}
@media (min-width: 768px) {
  .BannerWrapperFloating {
    bottom: 0;
    left: 16px;
    right: 16px;
  }
}

.BannerWrapperFloating .Banner {
  position: static;
}

.BannerWrapperFloating .BannerContent {
  max-width: none;
}

@media (min-width: 768px) {
  .BannerWrapperFloating .BannerContent {
    padding-bottom: 16px;
  }
}

.BannerWrapperFloating .FloatingBanner {
  width: auto;
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideBackToRight {
  100% {
    transform: translateX(calc(var(--space-040) + 100%));
  }
  0% {
    transform: translateX(0);
  }
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideBackToBottom {
  100% {
    transform: translateY(100%);
  }
  0% {
    transform: translateY(0);
  }
}

@media (max-width: 767px) {
  .BannerContent.has-animation {
    animation: slideFromBottom 333ms ease 1;
  }

  .BannerContent.animation-reverse {
    animation: slideBackToBottom 333ms ease 1;
    animation-fill-mode: forwards;
  }
}

@media (min-width: 768px) {
  .BannerContent.has-animation {
    animation: slideFromRight 333ms ease 1;
  }

  .BannerContent.animation-reverse {
    animation: slideBackToRight 333ms ease 1;
    animation-fill-mode: forwards;
  }
}

.BannerWrapperFloating .BannerContent.has-animation {
  animation: slideFromBottom 333ms ease 1;
}

.BannerWrapperFloating .BannerContent.animation-reverse {
  animation: slideBackToBottom 333ms ease 1;
  animation-fill-mode: forwards;
}
