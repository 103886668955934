@media (max-width: 1199px) {
  .UserAccountPage {
    min-height: calc(100vh - var(--navbar-height));
  }
}

@media (min-width: 1200px) {
  .UserAccountPage {
    height: calc(100vh - var(--navbar-height));
  }
}
