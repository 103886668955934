.house-image__container {
  flex: 1 1 100%;
  margin: -64px -16px 32px;
}

.house-image__img {
  max-height: 265px;
  width: 100%;
}

@media (min-width: 500px) {
  .house-image__img {
    object-fit: cover;
  }
}

@media (min-width: 900px) {
  .house-image__img {
    height: 100%;
    max-height: none;
    object-position: left;
  }
  .house-image__container {
    margin: 0;
    margin-left: -4px;
    position: relative;
    display: inline-block;
  }
  .house-image__container:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: linear-gradient(to right, #3b47684a 10%, transparent);
  }
}
