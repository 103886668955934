.HLP-hero-default-centre-aligned {
  padding-top: var(--navbar-height);
}

.HLP-hero-default-centre-aligned__footer-wrapper {
  background-color: var(--colour-background-025);
}

.HLP-hero-default-centre-aligned__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: var(--space-020);
  padding-bottom: var(--space-020);
  gap: var(--space-030);
}

.HLP-hero-default-centre-aligned__footer-ticks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px 32px;
  width: 100%;
}

.HLP-hero-default-centre-aligned__background-wrapper {
  position: relative;
}

.HLP-hero-content--default-centre-aligned
  .QuizWidget__option.ActionCardTemplate
  .ActionCardTemplate-text
  > span {
  font-family: var(--base-font-family);
}

.HLP-hero-default-centre-aligned .HLP-hero-text--default-centre-aligned {
  text-align: center;
  max-width: 900px;
}

.HLP-hero-default-centre-aligned
  .QuizWidget-wrap
  > .QuizWidget--default-centre-aligned {
  justify-content: space-between;
  max-width: 800px !important;
  margin: 0 auto;
}

.HLP-hero-content__heading-default-centre-aligned {
  text-align: center;
  max-width: 900px;
}

.HLP-hero-content--default-centre-aligned > * {
  color: var(--colour-primary);
}

@media (max-width: 374px) {
  .HLP-hero-default-centre-aligned
    .HLP-hero-content__heading-default-centre-aligned {
    font-size: 24px;
  }

  .HLP-hero-default-centre-aligned
    .QuizWidget-wrap
    > .QuizWidget--default-centre-aligned {
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    max-width: 320px !important;
  }
}

@media (max-width: 767px) {
  .HLP-hero-text--default-centre-aligned.HLP-hero-sub-heading {
    margin-top: var(--space-010);
    font-size: 16px;
    text-align: left;
  }

  .HLP-hero-default-centre-aligned .HLP-hero-content {
    margin-top: 0px;
    margin-bottom: var(--space-040);
  }

  .HLP-hero-default-centre-aligned
    .HLP-hero-content__heading-default-centre-aligned {
    font-size: 28px;
    margin-top: var(--space-040);
    text-align: left;
  }

  .HLP-hero-default-centre-aligned .QuizWidget-wrap {
    padding-top: 0;
    margin-top: var(--space-010);
  }

  .HLP-hero-default-centre-aligned
    .QuizWidget-wrap
    > .QuizWidget--default-centre-aligned {
    margin: revert;
    flex-direction: column;
    max-width: 360px !important;
  }

  .HLP-hero-post-heading.f-t-centre {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .HLP-hero-default-centre-aligned__footer {
    flex-direction: row;
    justify-content: space-between;
    padding-top: var(--space-020);
  }

  .HLP-hero-default-centre-aligned__footer--no-badges {
    justify-content: center;
  }

  .HLP-hero-default-centre-aligned__footer-ticks {
    justify-content: center;
    width: auto;
  }

  .HLP-hero-default-centre-aligned
    .HLP-hero-content.HLP-hero-content--default-centre-aligned {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: var(--space-080);
    margin-bottom: var(--space-080);
  }

  .HLP-hero-default-centre-aligned .HLP-hero-text--default-centre-aligned {
    font-size: 24px;
    font-weight: 400;
  }

  .HLP-hero-default-centre-aligned .HLP-hero-sub-heading {
    margin-top: 8px;
  }

  .HLP-hero-default-centre-aligned .HLP-hero-post-heading {
    margin-top: 16px;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    max-width: revert;
  }
}

@media (min-width: 1200px) {
  .QuizWidget--default-centre-aligned {
    justify-content: space-evenly;
  }
}
