.RegionalPricesInfo-large-modal {
  max-width: 748px;
}

.RegionalPricesInfo-container {
  width: 100%;
}

@media (max-width: 699px) {
  .RegionalPricesInfo-container {
    overflow: scroll;
  }
}

.RegionalPricesInfo-center {
  margin-left: auto;
  margin-right: auto;
  max-width: 584px;
  text-align: center;
  /* IE: */
  width: 100%;
}

.RegionalPricesInfo-bauort {
  align-items: flex-end;
  justify-content: space-between;
  margin: auto;
}

@media (max-width: 699px) {
  .RegionalPricesInfo-bauort {
    max-width: 328px;
  }
}

@media (min-width: 700px) {
  .RegionalPricesInfo-bauort {
    max-width: 388px;
  }
}

.RegionalPricesInfo-input {
  text-align: left;
}

@media (max-width: 699px) {
  .RegionalPricesInfo-input {
    margin-right: 8px;
    width: 190px;
  }
}

@media (min-width: 700px) {
  .RegionalPricesInfo-input {
    width: 220px;
  }
}

.RegionalPricesInfo-submit {
  height: 48px;
  justify-content: space-between;
  padding: 12px;
}

.RegionalPricesInfo-guide-container {
  text-align: center;
}

.RegionalPricesInfo-guide {
  display: flex;
}

@media (max-width: 700px) {
  .RegionalPricesInfo-guide {
    align-items: center;
    flex-direction: column;
  }
}

@media (min-width: 700px) {
  .RegionalPricesInfo-guide {
    flex-direction: row;
    justify-content: space-between;
    max-width: 90%;
    margin: auto;
  }
}

.RegionalPricesInfo-step {
  /* IE: */
  flex: 1 0 50%;
  max-width: 255px;
}

@media (max-width: 699px) {
  .RegionalPricesInfo-step + .RegionalPricesInfo-step {
    margin-top: var(--space-040);
  }
}
