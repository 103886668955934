.VideoCallPage-wrapper {
  height: 100%;
}
.VideoCallPage-header {
  display: flex;
  align-items: flex-start;
  padding: 1em;
  height: 100px;
}
.VideoCallPage-vendor-logo {
  width: 50%;
}
.VideoCallPage-vendor-logo img {
  max-height: 65px;
}
.VideoCallPage-vendor-info {
  width: 50%;
  text-align: right;
}
.VideoCallPage-video-container {
  height: calc(100vh - 100px);
}

.VideoCallPage-welcome-message {
  padding: 1em;
  text-align: center;
}

.VideoCallPage-welcome-message a {
  color: #000000;
}
