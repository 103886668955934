@media (max-width: 767px) {
  .FloatingBanner {
    width: 100vw;
  }

  .FloatingBanner:not(.ModalBanner) {
    border-radius: var(--border-radius-card-large)
      var(--border-radius-card-large) 0 0;
  }
}

@media (min-width: 768px) {
  .FloatingBanner {
    padding: 0;
    border-radius: var(--border-radius-card-large);
    width: 400px;
  }

  .FloatingBanner-house-list {
    padding: var(--space-010) var(--space-050);
  }
}

@media (min-width: 768px) {
  .BottomBanner-illustration {
    height: auto;
    flex: 0 0 209px;
    max-width: 209px;
  }
}

@media (max-width: 767px) {
  .FloatingBanner-image-holder {
    padding: 0 var(--space-030);
  }
}

@media (min-width: 768px) {
  .FloatingBanner-image-holder {
    padding: 0;
  }
}

.FloatingBanner-close {
  right: 16px;
  top: 16px;
}

@media (max-width: 767px) {
  .FloatingBanner-house-list .FloatingBanner-close {
    top: 16px;
  }
}

@media (min-width: 768px) {
  .FloatingBanner-house-list .FloatingBanner-close {
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .FloatingBanner-house-list .FloatingBanner-close {
    top: 15%;
  }
}

.FloatingBanner-close-icon use {
  stroke-width: 2px;
}

@media (min-width: 768px) {
  .FloatingBanner-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .FloatingBanner-content-message {
    flex: 1;
    padding-left: var(--space-030);
    padding-right: var(--space-030);
  }

  .FloatingBanner-content-message-wrapper {
    max-width: 37.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .FloatingBanner-content-message {
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .FloatingBanner.ModalBanner {
    width: auto;
  }
}

@media (-ms-high-contrast: none) {
  .FloatingBanner.ModalBanner {
    min-width: 600px;
  }
}

.ModalBanner {
  flex-direction: column-reverse;
}

@media (min-width: 768px) {
  .ModalBanner {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .ModalBanner-copy-wrapper {
    padding: var(--space-060);
  }
}

.ModalBanner-wrapper {
  align-items: center;
  display: flex;
  min-height: 100%;
}

.ModalBanner-image-wrapper img {
  height: 100%;
}

@media (max-width: 767px) {
  .ModalBanner-image-wrapper img {
    object-fit: cover;
    max-height: 200px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .ModalBanner-image-wrapper img {
    object-fit: cover;
    max-height: 415px;
    width: 100%;
  }
}
