.InteractiveStateMapSVG path {
  fill: var(--colour-primary-600);
}

@media (min-width: 768px) {
  .InteractiveStateMapSVG path {
    pointer-events: all;
  }
}

.InteractiveStateMapSVG g.is-active path {
  fill: var(--colour-tertiary-400);
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .InteractiveStateMapSVG path:hover {
    fill: var(--colour-primary-600);
  }
}
