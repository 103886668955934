textarea.review-form-free-text {
  border: 1px solid var(--colour-neutral-100);
  background: var(--colour-white);
  border-radius: var(--border-radius-inputs);
  height: revert;
}

.review-form {
  max-width: 510px;
}
