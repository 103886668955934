.combobox-list-container {
  z-index: 2;
  position: absolute;
  width: 100%;
  background-color: white;
  right: 0px;
  top: 33px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  list-style: none;
}

.combobox-list-container--open {
  padding: 16px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  box-shadow: var(--action-card-box-shadow);
}

.combobox-list-container--limited {
  max-height: 300px;
  overflow: auto;
}

.combobox-list-item--highlighted {
  font-weight: 700;
}
