.spinner {
  --offset: -25px;
  --border: 25px;
  --dimensions: 300px;
  height: var(--dimensions);
  width: var(--dimensions);
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: calc(50% - var(--dimensions) / 2);
  animation: rotation 1s infinite linear;
  border: var(--border) solid var(--colour-neutral-100);
  border-radius: 100%;
}

.spin-animation {
  animation: rotation 1s infinite linear;
}

.spinner.spinner--inline {
  --offset: -5px;
  --dimensions: 30px;
  --border: 5px;
  top: calc(50% - var(--dimensions) / 2);
}

.spinner:before {
  content: '';
  display: block;
  position: absolute;
  left: var(--offset);
  top: var(--offset);
  height: var(--dimensions);
  width: var(--dimensions);
  border-top: var(--border) solid var(--colour-success-400);
  border-left: var(--border) solid transparent;
  border-bottom: var(--border) solid transparent;
  border-right: var(--border) solid transparent;
  border-radius: 50%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/* Used for HDP conversion funnels only */
.RequestWizard-form-wrapper--HDP_conversion-funnel .spinner {
  margin-top: 32px;
  position: static;
}
