.SumamryCard-block-overview > [class*='f-icon'] {
  vertical-align: -0.7rem;
}

.SummaryCard-block-overview-title {
  vertical-align: top;
  width: calc(100% - 32px);
}

.SummaryCard-block-overview-item {
  list-style: disc;
}
