.exclusive-leads-page-content__vendor-contact-image {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.exclusive-leads-page-content__vendor-contact-phone {
  border: 1px solid var(--colour-black);
  border-radius: var(--border-radius-card-large);
  padding: var(--space-010);
  text-align: center;
  margin: var(--space-040) 0;
}

.exclusive-leads-page-content__vendor-contact-phone-number.CTA-text {
  text-decoration: underline;
}

.exclusive-leads-page-content__progress {
  display: flex;
  flex-direction: column;
}

.exclusive-leads-page-content__progress-bar {
  padding: 0 96px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.exclusive-leads-page-content__progress-bar-text {
  width: 33%;
}
.exclusive-leads-page-content__progress-bar-tick {
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
}

.exclusive-leads-page-content__progress-bar-line {
  height: 6px;
  width: 50%;
}

.exclusive-leads-page-content__progress-steps {
  margin-top: var(--space-020);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: var(--space-020);
}

.exclusive-leads-page-content__vendor-contact.exclusive-leads-page-content__vendor-contact--tablet {
  margin-top: calc(-128px - var(--space-030));
  z-index: 1;
}

@media (max-width: 1160px) {
  .exclusive-leads-page-content__progress {
    flex-direction: row;
  }

  .exclusive-leads-page-content__progress-bar {
    padding: 0;
    flex-direction: column;
  }

  .exclusive-leads-page-content__progress-bar-line {
    width: 6px;
    height: 50%;
  }

  .exclusive-leads-page-content__progress-bar-text {
    width: 100%;
  }

  .exclusive-leads-page-content__progress-steps {
    margin-top: 0;
    margin-left: var(--space-020);
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--space-030);
  }
}
