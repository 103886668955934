.PageFooterLinks.PageFooterLinks {
  background-color: var(--page-footer-background-top);
}

.PageFooterLinks .PageFooterLinks-heading {
  color: var(--page-footer-text);
}

.PageFooterLinks.PageFooterLinks .PageFooterLinks-link {
  color: var(--page-footer-text-link);
}

@media (max-width: 767px) {
  .PageFooterLinks {
    padding-top: var(--space-020);
  }

  .PageFooterLinks-heading {
    margin-bottom: var(--space-020);
  }

  .PageFooterLinks-toggle {
    float: right;
    transition: transform 0.2s ease;
  }

  input:checked ~ .PageFooterLinks-toggle {
    transform: rotate(180deg);
  }

  .PageFooterLinks-toggle-icon {
    display: block;
  }

  .PageFooterLinks-content {
    display: none;
  }

  input:checked ~ .PageFooterLinks-content {
    display: block;
  }
}

@media (min-width: 768px) {
  .PageFooterLinks {
    padding-top: var(--space-060);
  }

  .PageFooterLinks-heading {
    margin-bottom: var(--space-030);
  }

  .PageFooterLinks-toggle,
  #PageFooterLinks-checkbox {
    display: none;
  }
}
