body.has-modal {
  position: fixed;
  width: 100%;
  z-index: -1;
}

body.has-modal .ModalWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1200;
}

body.has-modal .DeclarativeModal {
  z-index: 1300;
}

body.sales-agent-app.has-modal .Modal {
  z-index: 1402;
}

@media (min-width: 1200px) {
  body.sales-agent-app.has-modal .Modal {
    z-index: 1400;
  }
}

.Modal {
  align-items: center;
  background: rgba(106, 112, 128, 0.75);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-backdrop-filter: blur(5px);
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99;
}

@media (min-width: 768px) {
  .Modal.quiz-modal-wrap {
    align-items: start;
    padding-top: 15vh;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (-ms-high-contrast: none) and (min-width: 1023px) {
  .ModalContent {
    height: 100%;
  }
}

.ModalContent {
  box-shadow: 0 15px 30px rgb(59, 71, 104, 0.15);
  max-width: 640px;
  position: relative;
}

@media (max-width: 767px) {
  .ModalContent {
    box-shadow: none;
  }
}

.ModalContent.is-quiz-modal {
  max-width: 840px;
}

.ModalContent.has-grid {
  max-width: 900px;
  min-height: 0;
}

@media (min-width: 980px) {
  .ModalFinance {
    max-width: 980px;
  }
}

.Modal-top-button {
  background: none;
  border: 0;
  position: absolute;
  top: 19px;
}

@media (min-width: 480px) {
  .Modal-top-button {
    top: 22px;
  }
}

.Modal-top-button-back {
  left: 15px;
  z-index: 5;
}

@media (min-width: 480px) {
  .Modal-top-button-back {
    left: 20px;
  }
}

.Modal-top-button-close {
  right: 15px;
}

@media (min-width: 480px) {
  .Modal-top-button-close {
    right: 20px;
  }
}

.Modal-top-button-close.is-modal {
  z-index: 99;
}

@keyframes modal-banner-transition {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.ModalContent.modal-banner-transition {
  animation: modal-banner-transition 0.6s ease;
}

.ModalContent-modal-loader {
  height: 100%;
  background: var(--quiz-step-transition-background);
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
