.QuestionLoanDetails {
  max-width: 480px;
}

.QuestionLoanDetails .RadioInput:checked ~ .RadioInput-dot {
  border-color: var(--colour-primary-600);
}

.QuestionLoanDetails .RadioInput:checked ~ .RadioInput-dot:after {
  background: var(--colour-primary-600);
}
