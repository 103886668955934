.QuizApp .FormSlider__wrapper {
  padding-top: 40px;
}

.QuizApp .FormSlider__wrapper .FormSlider__label {
  display: none;
}

.QuizApp .FinanceSlider-tooltip {
  background: var(--colour-tertiary-400);
  border-radius: var(--border-radius-card-medium);
  position: absolute;
  padding: 4px 8px;
  white-space: nowrap;
  transform: translate(-39%, -130%);
  min-width: 70px;
  text-align: center;
}

.QuizApp .FinanceSlider-tooltip:before {
  border-color: var(--colour-tertiary-400) transparent transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  content: '';
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  pointer-events: none;
  top: 100%;
  width: 0;
}

.QuizApp-long-card.QuizApp-ExpectedValue {
  padding: 0 24px;
}

.QuizApp-long-card.QuizApp-ExpectedValue .FormSlider {
  margin: 0;
  margin-top: 8px;
}

.QuizApp-long-card.QuizApp-ExpectedValue .QuizApp-long-card-label {
  margin: 0 -24px;
}

.QuizApp-ExpectedValue.QuizApp-long-card .FormSlider .rc-slider-rail {
  right: 0;
}

.QuizApp-ExpectedValue.QuizApp-long-card .FormSlider .rc-slider-handle {
  margin-left: 0;
}

.QuizApp-ExpectedValue__loan-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  margin: 32px auto 0 auto;
}

@media screen and (min-width: 768px) {
  .QuizApp-long-card.QuizApp-ExpectedValue {
    padding: 0 32px;
  }

  .QuizApp-long-card.QuizApp-ExpectedValue .QuizApp-long-card-label {
    margin: 0 -32px;
  }

  .QuizApp-ExpectedValue__loan-amount {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1200px) {
  .QuizApp-long-card.QuizApp-ExpectedValue {
    padding: 0 64px;
  }

  .QuizApp-long-card.QuizApp-ExpectedValue .QuizApp-long-card-label {
    margin: 0 -64px;
  }
}
