.FormSelect-wrapper {
  padding: calc(var(--space-020) - 1px) var(--space-050)
    calc(var(--space-020) - 1px) var(--space-020);
  position: relative;
  width: 100%;
  border-radius: var(--border-radius-inputs);
}

.FormSelect-wrapper:focus-within {
  border-color: var(--colour-tertiary-400) !important;
}

.FormSelect-wrapper.has-border {
  border-style: solid;
  border-width: 1px;
}

.FormSelect-wrapper:not(.has-border) {
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.15);
}

.FormSelect-wrapper.is-full-width {
  max-width: 100%;
}

.FormSelect-display {
  color: var(--colour-black);
  display: block;
  font-size: var(--base-font-size);
  height: calc(var(--base-font-size) + 2px);
  line-height: calc(var(--base-font-size) + 2px);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.FormSelect-caret {
  position: absolute;
  right: var(--space-020);
  top: var(--space-020);
}

.FormSelect {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: rgba(0, 0, 0, 0.001);
  border: rgba(0, 0, 0, 0.001);
  bottom: 0;
  color: rgba(0, 0, 0, 0.001);
  font-size: var(--base-font-size);
  height: 100%;
  left: 0;
  outline: none;
  padding-left: 16px;
  padding-right: 16px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.FormSelect:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
  -webkit-text-fill-color: inherit !important;
  font-family: inherit;
  border-color: var(--colour-secondary);
}

.FormSelect option {
  color: var(--colour-black);
}

.FormSelect::-ms-expand {
  display: none;
}

.FormSelect-disabled .FormSelect-display {
  color: var(--colour-black);
}

.FormSelect-disabled svg {
  display: none;
}

.FormSelect-disabled {
  cursor: pointer;
  pointer-events: none;
}

.FormTextLabel.has-icon {
  left: var(--space-050);
}

.FormSelect-display.has-icon {
  padding-left: var(--space-030);
}
