.SADB-dropdown-menu-wrapper a {
  display: inline-block;
  width: 100%;
  color: var(--colour-primary-600) !important;
  line-height: 1;
}

.SADB-dropdown-menu-wrapper button {
  color: var(--colour-primary-600);
}

.SADB-dropdown-menu-wrapper > .SADB-desktop-header-toggler {
  padding: 0;
}

.SADB-dropdown-menu-wrapper__menu {
  position: absolute;
  transform: translate(-25%, 0);
  z-index: 100;
  background-color: var(--colour-white);
  border-radius: var(--border-radius-card-large);
  box-shadow: var(--action-card-box-shadow-hover);
  width: unset;
}

.SADB-dropdown-menu-wrapper__element {
  white-space: nowrap;
}

.SADB-dropdown-menu-wrapper__element > button {
  padding: 8px 16px;
}
