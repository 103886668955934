.FormTextArea-wrapper {
  background: var(--colour-white);
  border-radius: var(--border-radius-inputs);
  cursor: text;
  font-size: var(--base-font-size);
  height: 128px;
  position: relative;
}

.FormTextArea-wrapper ::placeholder {
  color: var(--colour-neutral-100);
}

.FormTextArea-wrapper ::-ms-input-placeholder {
  color: var(--colour-neutral-100);
}

.FormTextArea-wrapper :-ms-input-placeholder {
  color: var(--colour-neutral-100);
}

.FormTextArea-wrapper:focus-within {
  border-color: var(--colour-tertiary-400) !important;
}

.FormTextArea-wrapper.is-full-width {
  max-width: 100%;
  width: 100%;
}

@media (max-width: 767px) {
  .FormTextArea-wrapper.is-block\:s {
    max-width: 100%;
    width: 100%;
  }
}

.FormTextArea-wrapper.has-border {
  border-style: solid;
  border-width: 1px;
}

.FormTextArea-wrapper.is-disabled {
  cursor: pointer;
  pointer-events: none;
}

.FormTextArea {
  background: rgba(255, 255, 255, 0.0001);
  border: none;
  color: inherit;
  cursor: text;
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: -2px 0 -1px; /* Fixes line-height weirdness */
  outline: 0;
  padding: 0;
  resize: none;
  text-align: left;
  width: 100%;
  height: 6em;
}

.FormTextarea-caret {
  position: absolute;
  right: var(--space-020);
  top: 12px;
  cursor: pointer;
}

.FormTextarea-caret-rotate {
  transform: rotate(180deg);
  top: 18px;
}
