.GritCalculatorWidget {
  max-width: 700px;
  padding: 16px;
  border-radius: var(--border-radius-card-large);
  background-color: var(--colour-background-015);
  box-shadow: var(--action-card-box-shadow);
}

.GritCalculatorWidget_FormWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
}

.GritCalculatorWidget-widthInput,
.GritCalculatorWidget-lengthInput,
.GritCalculatorWidget-depthInput {
  flex: 1;
}

.GritCalculatorWidget-materialsInput,
.GritCalculatorWidget-cta {
  flex: 3 0 100%;
}

.GritCalculatorWidget_FormWrapper .FormText-wrapper .FormTextLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 15%);
}

.GritCalculatorWidget_FormWrapper
  .FormText-wrapper:focus-within
  .FormTextLabel {
  overflow: revert;
}

.GritCalculatorWidget-widthInput {
  grid-area: widthInput;
}

.GritCalculatorWidget-lengthInput {
  grid-area: lengthInput;
}

.GritCalculatorWidget-depthInput {
  grid-area: depthInput;
}

.GritCalculatorWidget-materialsInput {
  grid-area: materialsInput;
}

.GritCalculatorWidget-cta {
  grid-area: cta;
}

@media (min-width: 767px) {
  .GritCalculatorWidget_FormWrapper {
    display: grid;
    grid-template-areas:
      'widthInput lengthInput depthInput'
      'materialsInput materialsInput cta'
      'results results .';
    gap: 16px;
  }

  .GritCalculatorWidget_Results {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .GritCalculatorWidget_FormWrapper .FormText-wrapper {
    margin-bottom: 0;
  }

  .GritCalculatorWidget_FormWrapper:has(span.FormError-wrapper)
    .FormText-wrapper {
    margin-bottom: 8px;
  }

  .GritCalculatorWidget_FormWrapper .FormError-wrapper {
    margin-bottom: 0;
  }
}
