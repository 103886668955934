.Cookie-Banner-form {
  width: 640px;
}

@media (max-width: 767px) {
  .Cookie-Banner-form {
    width: 100%;
  }
}

.CookieModal-wrapper {
  margin: 0 auto;
  max-width: 737px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .CookieModal-wrapper {
    align-items: flex-end;
  }
}

.CookieModal {
  height: 100%;
  min-height: 512px;
  max-height: 100vh;
  width: 100%;
}

.CookieModal-form-wrapper {
  flex: 1 1 auto;
  max-height: 100%;
  min-height: 432px;
}

.CookieModal-CTA-wrapper {
  justify-content: flex-end;
}

.CookieModal-item {
  border-bottom: 1px solid #edeef1;
  padding-top: var(--space-020);
}

.CookieModal-item:first-child {
  border-top: 1px solid #edeef1;
}

.CookieModal-details {
  height: auto;
}
