@media (max-width: 427px) {
  .ServicesOverview-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: 1 0 100%;
    text-align: left;
    border-radius: var(--border-radius-card-large);
  }
}

@media (min-width: 428px) {
  .ServicesOverview-item {
    display: flex;
    flex-direction: column;
    flex: 0 0 166px;
  }
}

@media (max-width: 427px) {
  .ServicesOverview-icon {
    height: 2.25rem;
    margin: 0.75rem 1.375rem;
    width: 2.25rem;
  }
}

@media (min-width: 428px) {
  .ServicesOverview-icon {
    height: 3.25rem;
    margin: 1.5rem 3.5rem;
    width: 3.25rem;
  }
}

@media all and (min-width: 428px) and (-ms-high-contrast: none) {
  .ServicesOverview-icon use {
    stroke-width: 0.45;
  }
}

.ServicesOverview-label {
  font-size: 1rem;
  transition: color 0.2s ease;
}

@media (min-width: 428px) {
  .ServicesOverview-label {
    background-color: var(--colour-background-015);
    border-radius: 0 0 var(--border-radius-card-large)
      var(--border-radius-card-large);
    padding: var(--space-010) var(--space-040);
  }
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .ServicesOverview-item:hover .ServicesOverview-label {
    color: var(--colour-tertiary-400);
  }
}
