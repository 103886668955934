.Carousel-block {
  overflow: hidden;
}

@media (max-width: 767px) {
  .Carousel-block {
    margin-left: calc(var(--base-unit) * -2);
    margin-right: calc(var(--base-unit) * -2);
  }
}

@media (max-width: 767px) {
  .Carousel-block-thumbnails-wrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767px) {
  .Carousel-block-thumbnails {
    white-space: nowrap;
  }
}

@media (min-width: 768px) {
  .Carousel-block-thumbnails {
    display: flex;
  }
}

.Carousel-block-thumbnail {
  background: 0;
  border: 0;
  cursor: pointer;
  max-width: 160px;
  outline: none;
  overflow: hidden;
  padding: 0;
  opacity: 0.6;
  transition: opacity 0.1s ease;
  position: relative;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .Carousel-block-thumbnail:hover {
    opacity: 1;
  }
}

.Carousel-block-thumbnail.glide__bullet--active {
  opacity: 1;
}

@media (max-width: 767px) {
  .Carousel-block-thumbnail {
    margin: var(--space-010) var(--space-005) 0;
    width: 96px;
  }

  .Carousel-block-thumbnail:first-child {
    margin-left: var(--space-010);
  }

  .Carousel-block-thumbnail:last-child {
    margin-right: var(--space-010);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .Carousel-block-thumbnail {
    margin: var(--space-010) var(--space-005) 0;
  }

  .Carousel-block-thumbnail:first-child {
    margin-left: 0;
  }

  .Carousel-block-thumbnail:last-child {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .Carousel-block-thumbnail {
    margin: var(--space-020) var(--space-005) 0;
  }

  .Carousel-block-thumbnail:first-child {
    margin-left: 0;
  }

  .Carousel-block-thumbnail:last-child {
    margin-right: 0;
  }
}

.Carousel-block-thumbnail.is-active:after {
  background: transparent;
  border: 2px solid var(--colour-primary-600);
  border-radius: var(--border-radius-card-large);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.Carousel-block-arrow-wrapper {
  height: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.Carousel-block-arrow-wrapper.has-images {
  padding-top: 75%; /* 4:3 aspect ratio */
}

.Carousel-block-arrow-wrapper.has-videos {
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.Carousel-block-arrow {
  border: 0;
  background-color: var(--colour-white);
  height: 32px;
  opacity: 0.6;
  position: absolute;
  top: calc(50% - 16px);
  transition: opacity 0.2s ease;
  transform: translateY(-50%);
  width: 32px;
  cursor: pointer;
  outline: none;
  pointer-events: all;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .Carousel-block-arrow:hover {
    opacity: 1;
    background-color: var(--colour-primary-600);
  }

  .Carousel-block-arrow:hover use {
    stroke: var(--colour-white);
  }
}

.Carousel-block-arrow-left {
  border-radius: 0 var(--border-radius-card-large)
    var(--border-radius-card-large) 0;
  left: 0;
}

.Carousel-block-arrow-right {
  border-radius: var(--border-radius-card-large) 0 0
    var(--border-radius-card-large);
  right: 0;
}

.Carousel-block-arrow-icon {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Carousel-block-thumbnail-video-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: auto;
}

.glide__slide--active img {
  border-radius: var(--border-radius-card-large);
}
