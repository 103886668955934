.LocationFinder {
  position: relative;
}

.LocationFinder .geosuggest {
  float: none;
  margin-right: 0;
  position: relative;
}

.LocationFinder .LocationFinder-suggestions {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 4px 2px;
  padding: 0;
  width: calc(100% - 4px);
}

.LocationFinder .LocationFinder-suggestions-item {
  background: var(--colour-white);
  padding: var(--space-020) var(--space-020);
}

.LocationFinder .LocationFinder-suggestions-item:first-of-type {
  border-radius: var(--border-radius-card-large) var(--border-radius-card-large)
    0 0;
}

.LocationFinder .LocationFinder-suggestions-item:last-of-type {
  border-radius: 0 0 var(--border-radius-card-large)
    var(--border-radius-card-large);
}

.LocationFinder.has-bauort .FormText-wrapper {
  border-color: var(--colour-success-400);
}

.LocationFinder.has-bauort ::placeholder {
  color: var(--colour-black);
}

.LocationFinder.has-bauort ::-ms-input-placeholder {
  color: var(--colour-black);
}

.LocationFinder.has-bauort :-ms-input-placeholder {
  color: var(--colour-black);
}

.LocationFinder.has-bauort .FormText-wrapper:focus-within ::placeholder {
  color: var(--colour-neutral-100);
}

.LocationFinder.has-bauort
  .FormText-wrapper:focus-within
  ::-ms-input-placeholder {
  color: var(--colour-neutral-100);
}

.LocationFinder.has-bauort
  .FormText-wrapper:focus-within
  :-ms-input-placeholder {
  color: var(--colour-neutral-100);
}

.LocationFinder .LocationFinder-suggestions-item {
  border-top: 1px solid var(--colour-neutral-025);
}

.LocationFinder .LocationFinder-suggestions-item--active {
  color: var(--colour-tertiary-400);
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .LocationFinder .LocationFinder-suggestions-item:hover {
    color: var(--colour-tertiary-400);
    cursor: pointer;
  }
}

.LocationFinder .FormText-wrapper {
  margin-bottom: 4px;
}

.LocationFinder .FormError-wrapper {
  font-size: 0.875rem;
}
