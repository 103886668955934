.ArticleAuthorBlock {
  display: grid;
  grid-template-areas:
    'img name'
    'img description';
  grid-column-gap: 16px;
  grid-template-columns: 100px 1fr;
}

.ArticleAuthorBlock__image {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  font-size: 10px;
  text-align: center;
  grid-area: img;
}

.ArticleAuthorBlock__name {
  grid-area: name;
  align-items: center;
}

.ArticleAuthorBlock__description {
  grid-area: description;
}

.ArticleAuthorBlock__image.ArticleAuthorBlock__image--small {
  width: 60px;
  height: 60px;
}

.ArticleAuthorBlock__title {
  margin-left: 8px;
  padding-left: 12px;
  position: relative;
}

.ArticleAuthorBlock__title:before {
  content: '|';
  position: absolute;
  left: 0;
}

@media (max-width: 767px) {
  .ArticleAuthorBlock {
    grid-template-areas:
      'img name'
      'description description';
    grid-template-columns: 60px 1fr;
  }

  .ArticleAuthorBlock__name {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .ArticleAuthorBlock__title {
    margin: 4px 0 0 0;
    padding: 0;
    position: static;
    color: var(--colour-neutral-200);
  }

  .ArticleAuthorBlock__title:before {
    display: none;
  }

  .ArticleAuthorBlock__image {
    width: 60px;
    height: 60px;
  }
}
