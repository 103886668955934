.PageSharing {
  float: right;
}

.PageSharing-button {
  background: none;
  border: 0;
  outline: 0;
  padding: 0;
}

@media (max-width: 1199px) {
  .PageSharing-button {
    height: 21px;
    overflow: hidden;
    width: 18px;
  }
}

.PageSharing-button.is-open {
  color: var(--colour-primary-600);
}

.PageSharing-social-wrapper {
  display: inline-block;
  position: relative;
}

.PageSharing-social {
  display: none;
  position: absolute;
  right: -16px;
  top: 40px;
  width: 112px;
  z-index: 90;
}

.PageSharing-social.is-open {
  display: block;
}

.PageSharing-social:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 8px;
  top: -16px;
  content: ' ';
  height: 0;
  left: 50%;
  margin-left: -8px;
  pointer-events: none;
  position: absolute;
  width: 0;
}
