.Snackbar-container {
  width: 100%;
  min-width: 300px;
  box-shadow: 0px 15px 30px 0px rgba(59, 71, 104, 0.15);
  padding: 12px 16px 12px 16px;
  border-radius: var(--border-radius-small);
  background: rgba(255, 255, 255, 1);
  color: var(--colour-primary-600);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.Snackbar-close:hover {
  background-color: var(--colour-background-050) !important;
}

.Snackbar_action-text {
  color: var(--colour-tertiary-400);
  font-size: 14px;
  cursor: pointer;
  padding: 8px;
}

.Snackbar_action-text:hover {
  background-color: var(--colour-background-050);
  border-radius: 100px;
}

@media (min-width: 1200px) {
  .Snackbar-container {
    margin-bottom: 0;
    max-width: 450px;
  }
}
