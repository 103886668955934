.HouseMosaic-items {
  display: grid;
}

@media (max-width: 767px) {
  .HouseMosaic-items {
    grid-gap: var(--space-010);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .HouseMosaic-items.is-s {
    grid-template-areas:
      'i1 i2'
      'i3 i4'
      'i5 i6';
    grid-template-rows: repeat(3, 180px);
  }

  .HouseMosaic-items.is-m {
    grid-template-areas:
      'i1 i2'
      'i3 i4'
      'i5 i6'
      'i7 i8';
    grid-template-rows: repeat(4, 180px);
  }

  .HouseMosaic-items.is-l {
    grid-template-areas:
      'i1 i2'
      'i3 i4'
      'i5 i6'
      'i7 i8'
      'i9 i10';
    grid-template-rows: repeat(5, 180px);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .HouseMosaic-items {
    grid-gap: var(--space-020);
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .HouseMosaic-items.is-s {
    grid-template-areas:
      'i1 i1 i2 i3'
      'i4 i5 i5 i6';
    grid-template-rows: repeat(2, 180px);
  }

  .HouseMosaic-items.is-s.is-CMS {
    grid-template-rows: repeat(2, 110px);
  }

  .HouseMosaic-items.is-m {
    grid-template-areas:
      'i1 i1 i2 i3'
      'i1 i1 i4 i5'
      'i6 i7 i8 i8';
    grid-template-rows: repeat(3, 180px);
  }

  .HouseMosaic-items.is-m.is-CMS {
    grid-template-rows: repeat(3, 110px);
  }

  .HouseMosaic-items.is-l {
    grid-template-areas:
      'i1 i2 i3 i3'
      'i4 i4 i5 i6'
      'i4 i4 i7 i7'
      'i8 i8 i9 i10';
    grid-template-rows: repeat(4, 180px);
  }

  .HouseMosaic-items.is-l.is-CMS {
    grid-template-rows: repeat(4, 110px);
  }
}

@media (min-width: 1024px) {
  .HouseMosaic-items {
    grid-gap: var(--space-020);
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .HouseMosaic-items.is-s {
    grid-template-areas:
      'i1 i1 i2 i3 i3'
      'i1 i1 i4 i5 i6';
    grid-template-rows: repeat(2, 180px);
  }

  .HouseMosaic-items.is-s.is-CMS {
    grid-template-rows: repeat(2, 110px);
  }

  .HouseMosaic-items.is-m {
    grid-template-areas:
      'i1 i1 i2 i3 i3'
      'i1 i1 i4 i8 i8'
      'i5 i6 i7 i8 i8';
    grid-template-rows: repeat(3, 180px);
  }

  .HouseMosaic-items.is-m.is-CMS {
    grid-template-rows: repeat(3, 110px);
  }

  .HouseMosaic-items.is-l {
    grid-template-areas:
      'i1 i1 i2 i2 i3'
      'i1 i1 i4 i5 i5'
      'i6 i6 i7 i10 i10'
      'i8 i9 i9 i10 i10';
    grid-template-rows: repeat(4, 180px);
  }

  .HouseMosaic-items.is-l.is-CMS {
    grid-template-rows: repeat(4, 110px);
  }
}

.HouseMosaic-item.is-item-1 {
  grid-area: i1;
}

.HouseMosaic-item.is-item-2 {
  grid-area: i2;
}

.HouseMosaic-item.is-item-3 {
  grid-area: i3;
}

.HouseMosaic-item.is-item-4 {
  grid-area: i4;
}

.HouseMosaic-item.is-item-5 {
  grid-area: i5;
}

.HouseMosaic-item.is-item-6 {
  grid-area: i6;
}

.HouseMosaic-item.is-item-7 {
  grid-area: i7;
}

.HouseMosaic-item.is-item-8 {
  grid-area: i8;
}

.HouseMosaic-item.is-item-9 {
  grid-area: i9;
}

.HouseMosaic-item.is-item-10 {
  grid-area: i10;
}

.HouseMosaic-item-card {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.HouseMosaic-item-info {
  bottom: 0;
  left: 0;
  pointer-events: none;
  transform: translateY(100%);
  transition: transform 0.2s ease;
  width: 100%;
}

.HouseMosaic-item-card:hover .HouseMosaic-item-info,
.HouseMosaic-item-card.is-hovering .HouseMosaic-item-info {
  transform: translateY(0);
}

/* IE 11 */
@media all and (-ms-high-contrast: none) {
  .HouseMosaic-items {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
    flex-wrap: wrap;
  }

  .HouseMosaic-item-card {
    height: 0;
    margin: 4px;
    padding-top: 75%;
  }
}

@media all and (-ms-high-contrast: none) and (max-width: 767px) {
  .HouseMosaic-item {
    flex: 0 0 50%;
  }
}

@media all and (-ms-high-contrast: none) and (min-width: 768px) and (max-width: 1023px) {
  .HouseMosaic-item {
    flex: 0 0 25%;
  }
}

@media all and (-ms-high-contrast: none) and (min-width: 1024px) {
  .HouseMosaic-item {
    flex: 0 0 20%;
  }
}
