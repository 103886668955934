.rcb-chatbot-global {
  line-height: normal;
}

.rcb-chat-header-container {
  padding: 12px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  max-height: 80px;
}
.rcb-chat-header {
  display: flex;
  flex-direction: row;
}
.rcb-bot-avatar {
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 12px;
}

.rcb-toggle-button.rcb-button-show {
  align-items: center;
  justify-content: center;
}

.rcb-toggle-button.rcb-button-show:after {
  /* Makes area clickable */
  content: '  ';
  font-size: 1px;
  color: #fff;
  font-weight: 700;
  position: absolute;
  width: 100%;
  height: 100%;
}
.rcb-notification-icon-on,
.rcb-notification-icon-off,
.rcb-audio-icon-on,
.rcb-audio-icon-off {
  position: relative;
  display: inline-block;
  background-size: cover;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}
.rcb-notification-icon-off,
.rcb-audio-icon-off {
  filter: grayscale(100%);
}
.rcb-notification-icon-on:after,
.rcb-notification-icon-off:after,
.rcb-audio-icon-on:after,
.rcb-audio-icon-off:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: #0000001a;
  border-radius: 50%;
  opacity: 0;
  transition: width 0.2s ease-out, height 0.2s ease-out, opacity 0.2s ease-out;
}
.rcb-notification-icon-on:hover:after,
.rcb-notification-icon-off:hover:after,
.rcb-audio-icon-on:hover:after,
.rcb-audio-icon-off:hover:after {
  width: 130%;
  height: 130%;
  opacity: 1;
}
.rcb-message-prompt-container.visible {
  position: sticky;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  animation: popIn 0.3s ease-in-out;
  pointer-events: auto;
}
.rcb-message-prompt-container.hidden {
  opacity: 0;
  height: 0px;
  visibility: hidden;
  pointer-events: none;
}
.rcb-message-prompt-text {
  padding: 6px 12px;
  border-radius: 20px;
  color: #adadad;
  font-size: 12px;
  background-color: #fff;
  border: 0.5px solid #adadad;
  cursor: pointer;
  transition: color 0.3s ease, border-color 0.3s ease;
  z-index: 9999;
}
.rcb-message-prompt-container.hidden .rcb-message-prompt-text {
  padding: 0;
}
@keyframes popIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  70% {
    transform: scale(1.1);
    opacity: 1;
  }
  to {
    transform: scale(1);
  }
}
.rcb-chat-body-container {
  height: 100%;
  width: 100%;
  padding-bottom: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
  touch-action: pan-y;
}
.rcb-chat-body-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.rcb-chat-body-container::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 4px;
}
.rcb-chat-body-container::-webkit-scrollbar-thumb:hover {
  background-color: #cfcfcf;
}
.rcb-chat-body-container::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}
.rcb-bot-message-container {
  display: flex;
  flex-direction: row;
}
.rcb-user-message-container {
  display: flex;
  flex-direction: row;
  justify-content: right;
}
.rcb-bot-message,
.rcb-user-message {
  margin-top: 8px;
  padding: 12px 16px;
  border-radius: 22px;
  min-height: 20px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  width: fit-content;
  font-size: 14px;
  overflow-wrap: anywhere;
  overflow: hidden;
  white-space: pre-wrap;
  font-family: var(--base-font-family);
}
.rcb-bot-message.rcb-bot-message-entry p,
div.rcb-user-message.rcb-user-message-entry,
.rcb-bot-message.rcb-bot-message-entry span,
.rcb-bot-message.rcb-bot-message-entry ul {
  font-size: 14px;
  line-height: 1.66;
  font-family: var(--base-font-family);
}

.rcb-bot-message-entry.rcb-bot-message-entry p,
.rcb-bot-message-entry.rcb-bot-message-entry span {
  margin: 0;
}

.rcb-bot-message-entry.rcb-bot-message-entry ul,
.rcb-bot-message-entry.rcb-bot-message-entry ol {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-inline-start: 0;
  padding-left: 16px;
  margin: 0;
  list-style-type: none;
}

.rcb-bot-message-entry.rcb-bot-message-entry ul li,
.rcb-bot-message-entry.rcb-bot-message-entry ol li {
  padding-left: 0;
}

.rcb-bot-message.rcb-bot-message-entry
  a:not(.Chatbot-LinkPreview-link_container) {
  background-color: transparent;
  color: var(--colour-tertiary-400);
  border: 0;
  text-align: inherit;
  border-radius: var(--border-radius-cta);
  padding: 0;
  line-height: inherit;
  display: inline-block;
  text-decoration: none;
  outline: none;
}

.rcb-bot-message {
  margin-left: 16px;
}
.rcb-bot-message-offset {
  margin-left: 50px;
}
.rcb-bot-message-entry {
  animation: bot-entry 0.3s ease-in backwards;
}
@keyframes bot-entry {
  0% {
    transform: translate(-100%, 50%) scale(0);
    opacity: 0;
  }
  to {
    transform: translate(0) scale(1);
    opacity: 1;
  }
}
.rcb-user-message {
  margin-right: 16px;
}
.rcb-user-message-offset {
  margin-right: 50px;
}
.rcb-user-message-entry {
  animation: user-entry 0.3s ease-in backwards;
}

.rcb-chat-input-textarea {
  transition: height 0.3s ease;
}
@keyframes user-entry {
  0% {
    transform: translate(100%, 50%) scale(0);
    opacity: 0;
  }
  to {
    transform: translate(0) scale(1);
    opacity: 1;
  }
}
.rcb-message-bot-avatar,
.rcb-message-user-avatar {
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 9px;
}
.rcb-message-bot-avatar {
  margin-left: 6px;
  margin-right: -10px;
}
.rcb-message-user-avatar {
  margin-left: -10px;
  margin-right: 6px;
}
.rcb-typing-indicator {
  display: flex;
  align-items: center;
  gap: 1px;
}
.rcb-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 4px;
  animation: rcb-typing 1s infinite;
}
.rcb-dot:nth-child(2) {
  animation-delay: 0.2s;
}
.rcb-dot:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes rcb-typing {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
}
.rcb-chat-input {
  padding: 8px 16px;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.rcb-chat-input::placeholder {
  color: #999;
}
.rcb-chat-input-textarea {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  resize: none;
  height: auto;
  min-height: 38px;
  overflow-y: scroll;
  font-family: inherit;
  background-color: #fff;
  color: #000;
  touch-action: none;
  line-height: 1.66;
}
.rcb-chat-input-textarea::-webkit-scrollbar {
  background-color: transparent;
}
.rcb-chat-input-textarea::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.rcb-chat-input-textarea::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}
.rcb-chat-input-button-container {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}
.rcb-chat-input-char-counter {
  font-size: 14px;
  margin-left: 8px;
  margin-top: 3px;
}
.rcb-chat-footer-container {
  padding: 12px 16px 8px 10px;
  border-top: 1px solid #ccc;
  max-height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 12px;
  background-color: #f2f2f2;
  color: #000;
}
.rcb-chat-footer {
  display: flex;
  flex-direction: row;
}
.rcb-toggle-button {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px #0003;
}
.rcb-toggle-button.rcb-button-hide {
  opacity: 0;
  visibility: hidden;
  animation: collapse 0.3s ease-in-out forwards;
}
.rcb-toggle-button.rcb-button-show {
  opacity: 1;
  visibility: visible;
  animation: expand 0.3s ease-in-out forwards;
}
.rcb-toggle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
  border-radius: inherit;
}
.rcb-badge {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 5px 10px;
  border-radius: 50%;
  background-color: red;
  color: #fff;
}
.rcb-chat-tooltip {
  position: fixed;
  padding: 16px;
  border-radius: 20px;
  box-shadow: 0 2px 6px #0003;
  white-space: nowrap;
  cursor: pointer;
  font-size: 20px;
  transition: transform 0.3s ease;
}
.rcb-chat-tooltip-tail {
  content: '';
  position: absolute;
  top: 50%;
  right: -10px;
  margin-top: -10px;
  border-width: 10px 0 10px 10px;
  border-style: solid;
}
.rcb-chat-tooltip.rcb-tooltip-hide {
  opacity: 0;
  visibility: hidden;
  animation: tooltip-out 0.5s ease-in-out;
}
.rcb-chat-tooltip.rcb-tooltip-show {
  opacity: 1;
  visibility: visible;
  animation: tooltip-in 0.5s ease-in-out;
}
@keyframes tooltip-in {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes tooltip-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-5px);
  }
}
.rcb-view-history-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 5px;
  min-height: 35px;
  max-height: 45px;
}
.rcb-view-history-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  border-radius: 20px;
  color: #adadad;
  font-size: 12px;
  background-color: #fff;
  border-color: #adadad;
  border-width: 0.5px;
  border-style: solid;
  max-width: 60%;
  cursor: pointer;
  transition: color 0.3s ease, border-color 0.3s ease;
}
.rcb-view-history-button > p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rcb-checkbox-container {
  display: flex;
  padding-top: 12px;
  margin-left: 16px;
  flex-wrap: wrap;
  gap: 10px;
}
.rcb-checkbox-offset {
  margin-left: 50px !important;
}
.rcb-checkbox-row-container {
  display: flex;
  align-items: center;
  gap: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 10px;
  min-height: 30px;
  max-height: 32px;
  width: 80%;
  cursor: pointer;
  background-color: #fff;
  animation: checkboxes-entry 0.5s ease-out;
  overflow: hidden;
}
.rcb-checkbox-row-container:hover {
  box-shadow: 0 0 5px #0003;
}
@keyframes checkboxes-entry {
  0% {
    transform: translate(-100%);
    opacity: 0;
  }
  to {
    transform: translate(0);
    opacity: 1;
  }
}
.rcb-checkbox-row {
  display: inline-flex;
  margin-left: 10px;
  align-items: center;
  cursor: pointer;
}
.rcb-checkbox-mark {
  width: 20px;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  margin-right: 10px;
  cursor: pointer;
}
.rcb-checkbox-mark:hover {
  background-color: #c2c2c2;
}
.rcb-checkbox-mark:before {
  content: '✓';
  transition: all 0.3s ease;
}
.rcb-checkbox-label {
  font-size: 14px;
}
.rcb-checkbox-next-button {
  text-align: center;
  display: inline-block;
  align-items: center;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 10px;
  font-size: 24px;
  min-height: 30px;
  max-height: 32px;
  width: 80%;
  cursor: pointer;
  background-color: #fff;
  animation: checkboxes-entry 0.5s ease-out;
}
.rcb-checkbox-next-button:before {
  content: '→';
}
.rcb-checkbox-next-button:hover {
  box-shadow: 0 0 5px #0003;
}
.rcb-options-container {
  padding: 12px 8px 0;
  margin-left: 16px;
  max-width: 70%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.rcb-options-offset {
  margin-left: 50px !important;
}

@keyframes options-entry {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.rcb-options:hover {
  box-shadow: 0 0 5px #0003;
}
.rcb-line-break-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 5px;
  max-height: 45px;
}
.rcb-line-break-text {
  padding: 6px 12px;
  color: #adadad;
  font-size: 12px;
}
.rcb-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 5px;
  min-height: 35px;
  max-height: 45px;
}
.rcb-spinner {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 4px solid #f3f3f3;
  animation: rcb-spin 1s linear infinite;
}
@keyframes rcb-spin {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.rcb-media-display-image-container,
.rcb-media-display-video-container {
  margin-top: 8px;
  margin-right: 16px;
  border-radius: 22px;
  padding: 16px;
  width: fit-content;
}
.rcb-media-display-offset {
  margin-right: 50px !important;
}
.rcb-media-display-image {
  width: 100%;
  height: auto;
  border-radius: 22px;
  object-fit: cover;
}
.rcb-media-display-video {
  width: 100%;
  height: auto;
  border-radius: 22px;
  background-color: #000;
}
.rcb-media-display-audio {
  margin-top: 8px;
  margin-right: 16px;
  width: 100%;
  height: auto;
  border-radius: 22px;
}
.rcb-media-entry {
  animation: media-entry 0.3s ease-in backwards;
}
@keyframes media-entry {
  0% {
    transform: translate(100%, 50%) scale(0);
    opacity: 0;
  }
  to {
    transform: translate(0) scale(1);
    opacity: 1;
  }
}
.rcb-attach-button-enabled,
.rcb-attach-button-disabled {
  position: relative;
  display: inline-block;
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  text-align: center;
}
.rcb-attach-button-enabled input[type='file'],
.rcb-attach-button-disabled input[type='file'] {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
}
.rcb-attach-button-enabled {
  cursor: pointer;
}
.rcb-attach-button-disabled {
  opacity: 0.4;
}
.rcb-attach-button-enabled:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: #0000001a;
  border-radius: 50%;
  opacity: 0;
  transition: width 0.2s ease-out, height 0.2s ease-out, opacity 0.2s ease-out;
}
.rcb-attach-button-enabled:hover:after {
  width: 130%;
  height: 130%;
  opacity: 1;
}
.rcb-attach-icon-enabled,
.rcb-attach-icon-disabled {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 2px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 0.3s ease;
}
.rcb-attach-icon-enabled {
  cursor: pointer;
}
.rcb-emoji-button-enabled,
.rcb-emoji-button-disabled {
  position: relative;
  display: inline-block;
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
}
.rcb-emoji-icon-enabled,
.rcb-emoji-icon-disabled {
  position: relative;
  display: inline-block;
  background-size: cover;
  font-size: 20px;
  width: 24px;
  height: 24px;
  margin-top: 2px;
  background-repeat: no-repeat;
}
.rcb-emoji-icon-enabled {
  cursor: pointer;
}
.rcb-emoji-icon-disabled {
  opacity: 0.4;
}
.rcb-emoji-button-enabled:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: #0000001a;
  border-radius: 50%;
  opacity: 0;
  transition: width 0.2s ease-out, height 0.2s ease-out, opacity 0.2s ease-out;
}
.rcb-emoji-button-enabled:hover:after {
  width: 130%;
  height: 130%;
  opacity: 1;
}
.rcb-emoji-button-popup {
  position: absolute;
  width: 158px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px #0003;
  max-height: 200px;
  overflow-y: auto;
  transform: translateY(calc(-100% - 30px));
}
.rcb-emoji {
  cursor: pointer;
  font-size: 24px;
  padding: 3px;
  transition: transform 0.2s ease-in-out;
}
.rcb-emoji:hover {
  transform: scale(1.2);
}
.rcb-audio-icon-on,
.rcb-audio-icon-off {
  position: relative;
  display: inline-block;
  background-size: cover;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}
.rcb-audio-icon-off {
  filter: grayscale(100%);
}
.rcb-audio-icon-on:after,
.rcb-audio-icon-off:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: #0000001a;
  border-radius: 50%;
  opacity: 0;
  transition: width 0.2s ease-out, height 0.2s ease-out, opacity 0.2s ease-out;
}
.rcb-audio-icon-on:hover:after,
.rcb-audio-icon-off:hover:after {
  width: 130%;
  height: 130%;
  opacity: 1;
}
.rcb-close-chat-icon {
  position: relative;
  display: flex;
  background-size: cover;
  width: 30px;
  height: 30px;
  border: none;
  margin-left: 5px;
  cursor: pointer;
}
.rcb-close-chat-icon:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: #0000001a;
  border-radius: 50%;
  opacity: 0;
  transition: width 0.2s ease-out, height 0.2s ease-out, opacity 0.2s ease-out;
}
.rcb-close-chat-icon:hover:after {
  width: 130%;
  height: 130%;
  opacity: 1;
}
.rcb-notification-icon-on,
.rcb-notification-icon-off {
  position: relative;
  display: inline-block;
  background-size: cover;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}
.rcb-notification-icon-off {
  filter: grayscale(100%);
}
.rcb-notification-icon-on:after,
.rcb-notification-icon-off:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: #0000001a;
  border-radius: 50%;
  opacity: 0;
  transition: width 0.2s ease-out, height 0.2s ease-out, opacity 0.2s ease-out;
}
.rcb-notification-icon-on:hover:after,
.rcb-notification-icon-off:hover:after {
  width: 130%;
  height: 130%;
  opacity: 1;
}
.rcb-voice-button-enabled,
.rcb-voice-button-disabled {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 4px;
  box-shadow: 0 0 3px #0000004d;
  cursor: pointer;
  height: 32px;
  width: 32px;
  margin-left: 8px;
  transition: all 0.3s ease;
  background-color: #fff;
}
.rcb-voice-button-enabled {
  border: 1px solid red;
  box-shadow: 0 0 3px #ff000080;
}
.rcb-voice-button-enabled:hover {
  border: 1px solid rgb(61, 0, 0);
}
.rcb-voice-button-disabled {
  border: 1px;
  border-style: solid;
  border-color: #0003;
}
.rcb-voice-button-disabled:hover {
  box-shadow: 0 0 3px #8a0000;
}
.rcb-voice-icon-on,
.rcb-voice-icon-off {
  width: 60%;
  height: 60%;
  background-size: cover;
  object-fit: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.rcb-voice-icon-on {
  animation: ping 1s infinite;
}
.rcb-voice-icon-off {
  filter: grayscale(100%);
}
@keyframes ping {
  0% {
    filter: brightness(100%);
    opacity: 1;
  }
  50% {
    filter: brightness(50%);
    opacity: 0.8;
  }
}
.rcb-send-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 3px #0000004d;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: auto;
  width: auto;
  margin-left: 8px;
  position: relative;
}

.rcb-send-icon {
  width: 100%;
  height: auto;
  display: flex;
  transform: none;
}

[class^='rcb'] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.rcb-chat-window {
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px #0003;
  background-color: #fff;
  transition: all 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 550px;
  z-index: 10000;
}
.rcb-window-embedded .rcb-chat-window {
  position: static;
  opacity: 1;
  visibility: visible;
  right: auto;
  bottom: auto;
}
.rcb-window-open .rcb-chat-window {
  opacity: 1;
  visibility: visible;
  animation: expand 0.3s ease-in-out forwards;
}
.rcb-window-close .rcb-chat-window {
  opacity: 0;
  visibility: hidden;
  animation: collapse 0.3s ease-in-out forwards;
}
@keyframes expand {
  0% {
    transform: translate(100%, 100%) scale(0);
    opacity: 0;
  }
  to {
    transform: translate(0) scale(1);
    opacity: 1;
  }
}
@keyframes collapse {
  0% {
    transform: translate(0) scale(1);
    opacity: 1;
  }
  to {
    transform: translate(100%, 100%) scale(0);
    opacity: 0;
  }
}

.Chatbot-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.Chatbot-title p {
  margin-block: 0;
}

.Chatbot-title > p:first-child {
  font-size: 1rem;
  font-weight: 600;
}

.Chatbot-title > p:last-child {
  font-size: 0.875rem;
  font-weight: 400;
}

.Chatbot-LinkPreview-skeleton {
  background: #e2e5e7;
  border-radius: 4px;
  line-height: 1;
  position: relative;
  overflow: hidden;
  animation: skeleton-loading 1.5s ease-in-out infinite;
}

.Chatbot-LinkPreview-skeleton::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  animation: shimmer 1.5s infinite;
}

@keyframes skeleton-loading {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Chatbot-LinkPreview-description {
  line-height: 1.33;
  overflow: hidden;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  margin-top: auto;
}

.Chatbot-LinkPreview-link_container {
  text-decoration: none;
  color: inherit;
}
