.reputation-rating-widget {
  justify-content: flex-start;
  align-items: flex-start;
}

.reputation-rating-container {
  width: 100%;
}

.reputation-rating-icon:not(:last-child) {
  padding-right: var(--space-005);
}

.reputation-rating-icon {
  flex-shrink: 1;
  flex-grow: 1;
  cursor: pointer;
}

.reputation-rating-icon path {
  transition: 0.3s ease-in;
}

.reputation-rating-icon svg {
  width: 17vw;
  height: 17vw;
  box-shadow: 0 2px 40px 0 rgba(59, 71, 104, 0.05),
    0 2px 4px -3px rgba(59, 71, 104, 1), 0 5px 10px -1px rgba(59, 71, 104, 0.05);
}

@media (min-width: 369px) {
  .reputation-rating-container {
    max-width: 336px;
  }

  .reputation-rating-icon svg {
    width: revert;
    height: revert;
  }
}
