.SiteSearchNav-toolbar {
  height: calc(100vh - var(--navbar-height));
  z-index: 110;
  overflow: hidden;
}

.SiteSearchNav-toolbar.SiteSearchNav-full:after {
  content: none;
}

.SiteSearchNav-input-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.SiteSearchNav-form-input {
  border-style: solid;
  border-width: 1px;
  outline: none;
  width: 100%;
}

.SiteSearchNav-form-button {
  border: none;
  width: 112px;
}

.SiteSearchNav-suggestions-container {
  pointer-events: all;
}

.SiteSearchNav-result-text {
  text-decoration: none;
}

.SiteSearchNav-result-anchor {
  max-width: 100%;
  text-decoration: none;
}
.SiteSearchNav-result-anchor:hover {
  text-decoration: underline;
}

.SiteSearchNav-result-category {
  font-size: 12px;
}

.FullTextSearch-results {
  left: 0;
  right: 0;
  top: 136px;
}

.FullTextSearch-result-image-container {
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: var(--border-radius-card-large);
  overflow: hidden;
}

@media (max-width: 599px) {
  .FullTextSearch-result-image-container {
    min-height: 56px;
    min-width: 80px;
    max-height: 56px;
    max-width: 80px;
  }
}

@media (min-width: 600px) {
  .FullTextSearch-result-image-container {
    min-height: 120px;
    min-width: 160px;
    max-height: 120px;
    max-width: 160px;
  }
}

.FullTextSearch-vendor-image {
  border-width: 1px;
  border-style: solid;
}

.FullTextSearch-icon-container {
  border-width: 2px;
  border-style: solid;
}

@media (min-width: 600px) {
  .FullTextSearch-icon {
    height: 64px;
    width: 64px;
  }
}

.FullTextSearch-result-link {
  margin: auto;
  display: block;
}

@media (max-width: 599px) {
  .FullTextSearch-result-image {
    margin: auto;
    max-height: 56px;
    max-width: 80px;
  }
}

@media (min-width: 600px) {
  .FullTextSearch-result-image {
    margin: auto;
    max-height: 120px;
    max-width: 160px;
  }
}

@media (max-width: 767px) {
  .SiteSearchNav-toolbar {
    overflow: auto;
    padding-bottom: 32px;
  }

  .SiteSearchNav-search-button-wrapper {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    background: white;
    box-shadow: var(--card-box-shadow);
  }

  .SiteSearchNav-search-button-wrapper .CTA-block-primary {
    display: block;
    max-width: 100%;
    text-align: center;
    width: 100%;
  }
}

.FullTextSearch-result-title {
  text-decoration: none;
}
