/* -------------------------- */
/* Desktop (Default) Styles   */
/* -------------------------- */

/* Carousel Card Container */
.carousel-card {
  max-width: 1128px; /* Constrained as specified */
  margin: 0 auto;
  border-radius: var(--border-radius-card-medium);
  overflow: hidden;
}

/* Two-Column Layout */
.card-columns {
  display: flex;
  flex-direction: row;
  width: 100%;
}

/* Left Column: Preview Image and Thumbnails */
.card-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.card-main-image-wrapper {
  position: relative;
  width: 100%;
}

.card-main-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  border-top-left-radius: var(--border-radius-card-medium);
  border-top-right-radius: var(--border-radius-card-medium);
}

.card-award-badge {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #000;
  border-radius: 3px;
  font-size: 0.9rem;
  font-weight: bold;
  width: 70px;
}

/* Thumbnails */
.card-thumbnails {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: 4px;
  width: 100%;
}

.thumbnail {
  overflow: hidden;
  flex: 1;
  max-width: 35%;
}

.thumbnail:first-of-type {
  border-bottom-left-radius: var(--border-radius-card-medium);
}

.thumbnail:last-of-type {
  border-bottom-right-radius: var(--border-radius-card-medium);
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Right Column: Description and Text */
.vendor-logo-container {
  width: 100px;
  height: 100px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-card-large);
  background-color: var(--colour-white);
  box-shadow: 0px 18.75px 37.5px 0px rgba(59, 71, 104, 0.15);
}

.vendor-logo {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.card-header {
  display: flex;
  align-items: center;
}

.card-description {
  display: -webkit-box;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  /* Overflow required to work for clamping */
  overflow: hidden;
}

.card-stats div {
  min-width: 120px;
}

/* Statistics */
.stat-icon {
  width: 20px;
  height: 20px;
}

/* Navigation Buttons */
.cards-navigation-button {
  position: absolute;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  fill: #fff;
  opacity: 0.75;
  box-shadow: var(--action-card-box-shadow);
  background: white;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.cards-navigation-button--prev {
  left: calc(-1 * clamp(0px, (100vw - 1165px) / 2, 80px));
}

.cards-navigation-button--next {
  right: calc(-1 * clamp(0px, (100vw - 1165px) / 2, 80px));
}

/* -------------------------- */
/* Mobile Styles (Single Column) */
/* -------------------------- */
@media (max-width: 768px) {
  .premium-placement-container {
    margin: 0 -16px;
  }

  .premium-placement-container > * {
    padding: 0 16px;
  }

  .carousel-card.carousel-card.carousel-card.carousel-card {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'header'
      'carousel'
      'description';
    gap: 1rem;
    transition: transform 0.3s ease;
  }

  .card-columns {
    display: contents;
  }

  .card-left {
    grid-area: carousel;
    padding: 0 16px;
  }

  .card-right {
    display: contents;
  }

  .card-right > * {
    margin: 0 16px;
  }

  /* Place the header first */
  .card-header {
    grid-area: header;
  }

  /* The description, stats, and CTA become the "description" area */
  .card-description {
    grid-area: description;
  }

  .card-stats {
    justify-content: space-around;
  }

  .card-stats div {
    min-width: auto;
  }

  .card-main-image,
  .vendor-logo {
    margin: 0 auto;
  }

  .card-award-badge {
    right: 10px;
    left: auto;
    width: 50px;
  }
}

.cards-pagination-bullets.cards-pagination-bullets.cards-pagination-bullets {
  box-sizing: content-box;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: var(--space-040) var(--space-020) 0;
  text-align: center;
  transform: translateX(-50%);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  display: block;
}

.cards-pagination-bullet {
  background-color: var(--colour-neutral-050);
  border: 0;
  display: inline-block;
  height: var(--space-010);
  width: var(--space-010);
  margin: var(--space-005);
  outline: none;
  padding: 0;
  position: relative;
  transition: transform 0.2s ease, left 0.2s ease;
  transform: scale(0.4);
}

.cards-pagination-bullet.is-active {
  background-color: var(--colour-neutral-100);
  transform: scale(1);
}

.cards-pagination-bullet.is-active-prev,
.cards-pagination-bullet.is-active-next {
  transform: scale(0.7);
}

/* Swiper specific styles - since it's still imposible to import swiper.css styles without breaking other carousels  */

:host {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
.js-vendor-premium-placement-wrapper.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
  display: block;
}
.js-vendor-premium-placement-wrapper .swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}
.js-vendor-premium-placement-wrapper .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(
    --swiper-wrapper-transition-timing-function,
    initial
  );
  box-sizing: content-box;
}
.js-vendor-premium-placement-wrapper .swiper-android .swiper-slide,
.js-vendor-premium-placement-wrapper .swiper-ios .swiper-slide,
.js-vendor-premium-placement-wrapper .swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.js-vendor-premium-placement-wrapper .swiper-horizontal {
  touch-action: pan-y;
}
.js-vendor-premium-placement-wrapper .swiper-vertical {
  touch-action: pan-x;
}
.js-vendor-premium-placement-wrapper .swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}
.js-vendor-premium-placement-wrapper .swiper-slide-invisible-blank {
  visibility: hidden;
}
/* Auto Height */
.js-vendor-premium-placement-wrapper .swiper-autoheight,
.js-vendor-premium-placement-wrapper .swiper-autoheight .swiper-slide {
  height: auto;
}
.js-vendor-premium-placement-wrapper .swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}
.js-vendor-premium-placement-wrapper .swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* CSS Mode */
.js-vendor-premium-placement-wrapper .swiper-css-mode > .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}
.js-vendor-premium-placement-wrapper
  .swiper-css-mode
  > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}
.js-vendor-premium-placement-wrapper
  .swiper-css-mode
  > .swiper-wrapper
  > .swiper-slide {
  scroll-snap-align: start start;
}
.js-vendor-premium-placement-wrapper
  .swiper-css-mode.swiper-horizontal
  > .swiper-wrapper {
  scroll-snap-type: x mandatory;
}
.js-vendor-premium-placement-wrapper
  .swiper-css-mode.swiper-vertical
  > .swiper-wrapper {
  scroll-snap-type: y mandatory;
}
.js-vendor-premium-placement-wrapper
  .swiper-css-mode.swiper-free-mode
  > .swiper-wrapper {
  scroll-snap-type: none;
}
.js-vendor-premium-placement-wrapper
  .swiper-css-mode.swiper-free-mode
  > .swiper-wrapper
  > .swiper-slide {
  scroll-snap-align: none;
}
.js-vendor-premium-placement-wrapper
  .swiper-css-mode.swiper-centered
  > .swiper-wrapper::before {
  content: '';
  flex-shrink: 0;
  order: 9999;
}
.js-vendor-premium-placement-wrapper
  .swiper-css-mode.swiper-centered
  > .swiper-wrapper
  > .swiper-slide {
  scroll-snap-align: center center;
  scroll-snap-stop: always;
}
.js-vendor-premium-placement-wrapper
  .swiper-css-mode.swiper-centered.swiper-horizontal
  > .swiper-wrapper
  > .swiper-slide:first-child {
  margin-inline-start: var(--swiper-centered-offset-before);
}
.js-vendor-premium-placement-wrapper
  .swiper-css-mode.swiper-centered.swiper-horizontal
  > .swiper-wrapper::before {
  height: 100%;
  min-height: 1px;
  width: var(--swiper-centered-offset-after);
}
.js-vendor-premium-placement-wrapper
  .swiper-css-mode.swiper-centered.swiper-vertical
  > .swiper-wrapper
  > .swiper-slide:first-child {
  margin-block-start: var(--swiper-centered-offset-before);
}
.js-vendor-premium-placement-wrapper
  .swiper-css-mode.swiper-centered.swiper-vertical
  > .swiper-wrapper::before {
  width: 100%;
  min-width: 1px;
  height: var(--swiper-centered-offset-after);
}
/* Slide styles start */

.js-vendor-premium-placement-wrapper .swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  box-sizing: border-box;
  border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
  border-radius: 50%;
  border-top-color: transparent;
}
.js-vendor-premium-placement-wrapper
  .swiper:not(.swiper-watch-progress)
  .swiper-lazy-preloader,
.js-vendor-premium-placement-wrapper
  .swiper-watch-progress
  .swiper-slide-visible
  .swiper-lazy-preloader {
  animation: swiper-preloader-spin 1s infinite linear;
}
.js-vendor-premium-placement-wrapper .swiper-lazy-preloader-white {
  --swiper-preloader-color: #fff;
}
.js-vendor-premium-placement-wrapper .swiper-lazy-preloader-black {
  --swiper-preloader-color: #000;
}
@keyframes swiper-preloader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Slide styles end */
.js-vendor-premium-placement-wrapper .swiper-virtual .swiper-slide {
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
}
.js-vendor-premium-placement-wrapper
  .swiper-virtual.swiper-css-mode
  .swiper-wrapper::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
.js-vendor-premium-placement-wrapper
  .swiper-virtual.swiper-css-mode.swiper-horizontal
  .swiper-wrapper::after {
  height: 1px;
  width: var(--swiper-virtual-size);
}
.js-vendor-premium-placement-wrapper
  .swiper-virtual.swiper-css-mode.swiper-vertical
  .swiper-wrapper::after {
  width: 1px;
  height: var(--swiper-virtual-size);
}
:root {
  --swiper-navigation-size: 44px;
  /*
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 10px;
  --swiper-navigation-color: var(--swiper-theme-color);
  */
}
.js-vendor-premium-placement-wrapper .swiper-button-prev,
.js-vendor-premium-placement-wrapper .swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}
.js-vendor-premium-placement-wrapper .swiper-button-prev.swiper-button-disabled,
.js-vendor-premium-placement-wrapper
  .swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.js-vendor-premium-placement-wrapper .swiper-button-prev.swiper-button-hidden,
.js-vendor-premium-placement-wrapper .swiper-button-next.swiper-button-hidden {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}
.js-vendor-premium-placement-wrapper
  .swiper-navigation-disabled
  .swiper-button-prev,
.js-vendor-premium-placement-wrapper
  .swiper-navigation-disabled
  .swiper-button-next {
  display: none !important;
}
.js-vendor-premium-placement-wrapper .swiper-button-prev svg,
.js-vendor-premium-placement-wrapper .swiper-button-next svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform-origin: center;
}
.js-vendor-premium-placement-wrapper .swiper-rtl .swiper-button-prev svg,
.js-vendor-premium-placement-wrapper .swiper-rtl .swiper-button-next svg {
  transform: rotate(180deg);
}
.js-vendor-premium-placement-wrapper .swiper-button-prev,
.js-vendor-premium-placement-wrapper .swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 10px);
  right: auto;
}
.js-vendor-premium-placement-wrapper .swiper-button-next,
.js-vendor-premium-placement-wrapper .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}
.js-vendor-premium-placement-wrapper .swiper-button-lock {
  display: none;
}
/* Navigation font start */
.js-vendor-premium-placement-wrapper .swiper-button-prev:after,
.js-vendor-premium-placement-wrapper .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}
.js-vendor-premium-placement-wrapper .swiper-button-prev:after,
.js-vendor-premium-placement-wrapper .swiper-rtl .swiper-button-next:after {
  content: 'prev';
}
.js-vendor-premium-placement-wrapper .swiper-button-next,
.js-vendor-premium-placement-wrapper .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}
.js-vendor-premium-placement-wrapper .swiper-button-next:after,
.js-vendor-premium-placement-wrapper .swiper-rtl .swiper-button-prev:after {
  content: 'next';
}
/* Navigation font end */
:root {
  /*
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0,0,0,0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-border-radius: 50%;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
  */
}
.js-vendor-premium-placement-wrapper .swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.js-vendor-premium-placement-wrapper
  .swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
.js-vendor-premium-placement-wrapper
  .swiper-pagination-disabled
  > .swiper-pagination,
.js-vendor-premium-placement-wrapper
  .swiper-pagination.swiper-pagination-disabled {
  display: none !important;
}
/* Common Styles */
.js-vendor-premium-placement-wrapper .swiper-pagination-fraction,
.js-vendor-premium-placement-wrapper .swiper-pagination-custom,
.js-vendor-premium-placement-wrapper
  .swiper-horizontal
  > .swiper-pagination-bullets,
.js-vendor-premium-placement-wrapper
  .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
}
/* Bullets */
.js-vendor-premium-placement-wrapper .swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.js-vendor-premium-placement-wrapper
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
}
.js-vendor-premium-placement-wrapper
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active {
  transform: scale(1);
}
.js-vendor-premium-placement-wrapper
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-main {
  transform: scale(1);
}
.js-vendor-premium-placement-wrapper
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}
.js-vendor-premium-placement-wrapper
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.js-vendor-premium-placement-wrapper
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.js-vendor-premium-placement-wrapper
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}
.js-vendor-premium-placement-wrapper .swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 8px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 8px)
  );
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}
.js-vendor-premium-placement-wrapper button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
}
.js-vendor-premium-placement-wrapper
  .swiper-pagination-clickable
  .swiper-pagination-bullet {
  cursor: pointer;
}
.js-vendor-premium-placement-wrapper .swiper-pagination-bullet:only-child {
  display: none !important;
}
.js-vendor-premium-placement-wrapper .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}
.js-vendor-premium-placement-wrapper
  .swiper-vertical
  > .swiper-pagination-bullets,
.js-vendor-premium-placement-wrapper
  .swiper-pagination-vertical.swiper-pagination-bullets {
  right: var(--swiper-pagination-right, 8px);
  left: var(--swiper-pagination-left, auto);
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}
.js-vendor-premium-placement-wrapper
  .swiper-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.js-vendor-premium-placement-wrapper
  .swiper-pagination-vertical.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
  display: block;
}
.js-vendor-premium-placement-wrapper
  .swiper-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.js-vendor-premium-placement-wrapper
  .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}
.js-vendor-premium-placement-wrapper
  .swiper-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet,
.js-vendor-premium-placement-wrapper
  .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  display: inline-block;
  transition: 200ms transform, 200ms top;
}
.js-vendor-premium-placement-wrapper
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.js-vendor-premium-placement-wrapper
  .swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}
.js-vendor-premium-placement-wrapper
  .swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.js-vendor-premium-placement-wrapper
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.js-vendor-premium-placement-wrapper
  .swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet,
.js-vendor-premium-placement-wrapper
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: 200ms transform, 200ms left;
}
.js-vendor-premium-placement-wrapper
  .swiper-horizontal.swiper-rtl
  > .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right;
}

/* a11y */
.js-vendor-premium-placement-wrapper .swiper .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
.js-vendor-premium-placement-wrapper .swiper-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.js-vendor-premium-placement-wrapper .swiper-grid > .swiper-wrapper {
  flex-wrap: wrap;
}
.js-vendor-premium-placement-wrapper .swiper-grid-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
}
.js-vendor-premium-placement-wrapper
  .swiper-fade.swiper-free-mode
  .swiper-slide {
  transition-timing-function: ease-out;
}
.js-vendor-premium-placement-wrapper .swiper-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
}
.js-vendor-premium-placement-wrapper .swiper-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.js-vendor-premium-placement-wrapper .swiper-fade .swiper-slide-active {
  pointer-events: auto;
}
.js-vendor-premium-placement-wrapper
  .swiper-fade
  .swiper-slide-active
  .swiper-slide-active {
  pointer-events: auto;
}
