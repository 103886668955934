/*
  Fertighaus Spacing and Grid – keep as agnostic as possible

  For margins and paddings, these are the letters' meaning:
  - m = margin
  - p = padding

    properties should be specified in the CSS in this order:
    all, top, right, bottom, left, horizontal, vertical

  - a = all properties
  - t = top
  - r = right
  - b = bottom
  - l = left
  - h = horizontal (sets left and right properties)
  - v = vertical (sets top and bottom properties)

  For grid elements, prefixed with -g for "grid"

  .f-g-r (defines a row)
    .f-g-c12 (defines a column with whatever span size)
*/

:root {
  --space-003: calc(var(--base-unit) * 0.3); /* 2.4px */
  --space-005: calc(var(--base-unit) * 0.5); /* 4px */
  --space-010: calc(var(--base-unit) * 1); /* 8px */
  --space-015: calc(var(--base-unit) * 1.5); /* 12px */
  --space-020: calc(var(--base-unit) * 2); /* 16px */
  --space-025: calc(var(--base-unit) * 2.5); /* 20px */
  --space-030: calc(var(--base-unit) * 3); /* 24px */
  --space-040: calc(var(--base-unit) * 4); /* 32px */
  --space-050: calc(var(--base-unit) * 5); /* 40px */
  --space-060: calc(var(--base-unit) * 6); /* 48px */
  --space-070: calc(var(--base-unit) * 7); /* 56px */
  --space-080: calc(var(--base-unit) * 8); /* 64px */
  --space-090: calc(var(--base-unit) * 9); /* 72px */
  --space-100: calc(var(--base-unit) * 10); /* 80px */
  --space-110: calc(var(--base-unit) * 11); /* 88px */
  --space-120: calc(var(--base-unit) * 12); /* 96px */
  --space-200: calc(var(--base-unit) * 20); /* 160px */
  --space-300: calc(var(--base-unit) * 32); /* 256px */
}

/* Spacing Helpers. */
/* Margins. */
.f-m-a-0 {
  margin: 0;
}
.f-m-a-auto {
  margin: auto;
}
.f-m-a-005 {
  margin: var(--space-005);
}
.f-m-a-010 {
  margin: var(--space-010);
}
.f-m-a-020 {
  margin: var(--space-020);
}
.f-m-v-005 {
  margin-top: var(--space-005);
  margin-bottom: var(--space-005);
}
.f-m-v-010 {
  margin-top: var(--space-010);
  margin-bottom: var(--space-010);
}
.f-m-v-020 {
  margin-top: var(--space-020);
  margin-bottom: var(--space-020);
}
.f-m-v-030 {
  margin-top: var(--space-030);
  margin-bottom: var(--space-030);
}
.f-m-v-040 {
  margin-top: var(--space-040);
  margin-bottom: var(--space-040);
}
.f-m-v-050 {
  margin-top: var(--space-050);
  margin-bottom: var(--space-050);
}
.f-m-h-auto {
  margin-left: auto;
  margin-right: auto;
}

.f-m-v-l-auto {
  margin-left: auto;
}

.f-m-v-r-auto {
  margin-right: auto;
}

.f-m-h-005 {
  margin-left: var(--space-005);
  margin-right: var(--space-005);
}
.f-m-h-010 {
  margin-left: var(--space-010);
  margin-right: var(--space-010);
}
.f-m-h-020 {
  margin-left: var(--space-020);
  margin-right: var(--space-020);
}
.f-m-h-030 {
  margin-left: var(--space-030);
  margin-right: var(--space-030);
}
.f-m-h-040 {
  margin-left: var(--space-040);
  margin-right: var(--space-040);
}
.f-m-t-003 {
  margin-top: var(--space-003);
}
.f-m-t-005 {
  margin-top: var(--space-005);
}
.f-m-t-010 {
  margin-top: var(--space-010);
}
.f-m-t-020 {
  margin-top: var(--space-020);
}
.f-m-t-030 {
  margin-top: var(--space-030);
}
.f-m-t-040 {
  margin-top: var(--space-040);
}
.f-m-t-050 {
  margin-top: var(--space-050);
}
.f-m-t-060 {
  margin-top: var(--space-060);
}
.f-m-t-070 {
  margin-top: var(--space-070);
}
.f-m-t-080 {
  margin-top: var(--space-080);
}
.f-m-t-100 {
  margin-top: var(--space-100);
}
.f-m-t-auto {
  margin-top: auto;
}
.f-m-r-005 {
  margin-right: var(--space-005);
}
.f-m-r-010 {
  margin-right: var(--space-010);
}
.f-m-r-020 {
  margin-right: var(--space-020);
}
.f-m-r-030 {
  margin-right: var(--space-030);
}
.f-m-r-040 {
  margin-right: var(--space-040);
}
.f-m-b-005 {
  margin-bottom: var(--space-005);
}
.f-m-b-010 {
  margin-bottom: var(--space-010);
}
.f-m-b-020 {
  margin-bottom: var(--space-020);
}
.f-m-b-030 {
  margin-bottom: var(--space-030);
}
.f-m-b-040 {
  margin-bottom: var(--space-040);
}
.f-m-b-050 {
  margin-bottom: var(--space-050);
}
.f-m-b-060 {
  margin-bottom: var(--space-060);
}
.f-m-b-080 {
  margin-bottom: var(--space-080);
}
.f-m-b-200 {
  margin-bottom: var(--space-200);
}
.f-m-l-auto {
  margin-left: auto;
}
.f-m-l-005 {
  margin-left: var(--space-005);
}
.f-m-l-010 {
  margin-left: var(--space-010);
}
.f-m-l-020 {
  margin-left: var(--space-020);
}
.f-m-l-030 {
  margin-left: var(--space-030);
}
.f-m-l-040 {
  margin-left: var(--space-040);
}
.f-m-l-100 {
  margin-left: var(--space-100);
}
@media (min-width: 768px) {
  .f-m-a-0\:m {
    margin: 0;
  }
  .f-m-a-010\:m {
    margin: var(--space-010);
  }
  .f-m-v-040\:m {
    margin-bottom: var(--space-040);
    margin-top: var(--space-040);
  }
  .f-m-t-020\:m {
    margin-top: var(--space-020);
  }
  .f-m-t-040\:m {
    margin-top: var(--space-040);
  }
  .f-m-t-050\:m {
    margin-top: var(--space-050);
  }
  .f-m-t-060\:m {
    margin-top: var(--space-060);
  }
  .f-m-t-070\:m {
    margin-top: var(--space-070);
  }
  .f-m-t-120\:m {
    margin-top: var(--space-120);
  }
  .f-m-r-010\:m {
    margin-right: var(--space-010);
  }
  .f-m-r-020\:m {
    margin-right: var(--space-020);
  }
  .f-m-r-040\:m {
    margin-right: var(--space-040);
  }
  .f-m-r-060\:m {
    margin-right: var(--space-060);
  }
  .f-m-l-020\:m {
    margin-left: var(--space-020);
  }
  .f-m-b-040\:m {
    margin-bottom: var(--space-040);
  }
}

/* Paddings. */
.f-p-a-0 {
  padding: 0;
}
.f-p-a-005 {
  padding: var(--space-005);
}
.f-p-a-010 {
  padding: var(--space-010);
}
.f-p-a-020 {
  padding: var(--space-020);
}
.f-p-a-030 {
  padding: var(--space-030);
}
.f-p-a-040 {
  padding: var(--space-040);
}
.f-p-v-005 {
  padding-top: var(--space-005);
  padding-bottom: var(--space-005);
}
.f-p-v-010 {
  padding-top: var(--space-010);
  padding-bottom: var(--space-010);
}
.f-p-v-020 {
  padding-top: var(--space-020);
  padding-bottom: var(--space-020);
}
.f-p-v-030 {
  padding-top: var(--space-030);
  padding-bottom: var(--space-030);
}
.f-p-v-040 {
  padding-top: var(--space-040);
  padding-bottom: var(--space-040);
}
.f-p-v-050 {
  padding-top: var(--space-050);
  padding-bottom: var(--space-050);
}
.f-p-v-060 {
  padding-top: var(--space-060);
  padding-bottom: var(--space-060);
}
.f-p-v-090 {
  padding-top: var(--space-090);
  padding-bottom: var(--space-090);
}
.f-p-v-100 {
  padding-top: var(--space-100);
  padding-bottom: var(--space-100);
}
.f-p-h-005 {
  padding-left: var(--space-005);
  padding-right: var(--space-005);
}
.f-p-h-010 {
  padding-left: var(--space-010);
  padding-right: var(--space-010);
}
.f-p-h-020 {
  padding-left: var(--space-020);
  padding-right: var(--space-020);
}
.f-p-h-030 {
  padding-left: var(--space-030);
  padding-right: var(--space-030);
}
.f-p-h-040 {
  padding-left: var(--space-040);
  padding-right: var(--space-040);
}
.f-p-h-060 {
  padding-left: var(--space-060);
  padding-right: var(--space-060);
}
.f-p-h-080 {
  padding-left: var(--space-080);
  padding-right: var(--space-080);
}
.f-p-t-005 {
  padding-top: var(--space-005);
}
.f-p-t-010 {
  padding-top: var(--space-010);
}
.f-p-t-020 {
  padding-top: var(--space-020);
}
.f-p-t-030 {
  padding-top: var(--space-030);
}
.f-p-t-040 {
  padding-top: var(--space-040);
}
.f-p-t-050 {
  padding-top: var(--space-050);
}
.f-p-t-060 {
  padding-top: var(--space-060);
}
.f-p-t-080 {
  padding-top: var(--space-080);
}
.f-p-t-100 {
  padding-top: var(--space-100);
}
.f-p-r-003 {
  padding-right: var(--space-003);
}
.f-p-r-005 {
  padding-right: var(--space-005);
}
.f-p-r-010 {
  padding-right: var(--space-010);
}
.f-p-r-020 {
  padding-right: var(--space-020);
}
.f-p-r-030 {
  padding-right: var(--space-030);
}
.f-p-b-005 {
  padding-bottom: var(--space-005);
}
.f-p-b-010 {
  padding-bottom: var(--space-010);
}
.f-p-b-020 {
  padding-bottom: var(--space-020);
}
.f-p-b-030 {
  padding-bottom: var(--space-030);
}
.f-p-b-040 {
  padding-bottom: var(--space-040);
}
.f-p-b-050 {
  padding-bottom: var(--space-050);
}
.f-p-b-060 {
  padding-bottom: var(--space-060);
}
.f-p-b-080 {
  padding-bottom: var(--space-080);
}
.f-p-b-100 {
  padding-bottom: var(--space-100);
}
.f-p-l-005 {
  padding-left: var(--space-005);
}
.f-p-l-010 {
  padding-left: var(--space-010);
}
.f-p-l-020 {
  padding-left: var(--space-020);
}
.f-p-l-030 {
  padding-left: var(--space-030);
}
.f-p-l-040 {
  padding-left: var(--space-040);
}
.f-p-l-050 {
  padding-left: var(--space-050);
}
.f-p-l-060 {
  padding-left: var(--space-060);
}
.f-p-l-070 {
  padding-left: var(--space-070);
}

@media (max-width: 1199px) {
  .f-p-a-010\:s {
    padding: var(--space-010);
  }
  .f-p-r-020\:s {
    padding-right: var(--space-020);
  }

  .f-p-t-010\:s {
    padding-top: var(--space-010);
  }

  .f-p-b-010\:s {
    padding-bottom: var(--space-010);
  }

  .f-p-l-020\:s {
    padding-left: var(--space-020);
  }

  .f-m-b-080\:s {
    margin-bottom: var(--space-080);
  }

  .f-p-b-080\:s {
    padding-bottom: var(--space-080);
  }

  .f-p-h-020\:s {
    padding-left: var(--space-020);
    padding-right: var(--space-020);
  }
}
@media (min-width: 768px) {
  .f-p-a-0\:m {
    padding: 0;
  }
  .f-p-a-020\:m {
    padding: var(--space-020);
  }
  .f-p-a-030\:m {
    padding: var(--space-030);
  }
  .f-p-a-040\:m {
    padding: var(--space-040);
  }
  .f-p-v-020\:m {
    padding-top: var(--space-020);
    padding-bottom: var(--space-020);
  }
  .f-p-v-060\:m {
    padding-top: var(--space-060);
    padding-bottom: var(--space-060);
  }
  .f-p-v-100\:m {
    padding-top: var(--space-100);
    padding-bottom: var(--space-100);
  }
  .f-p-h-020\:m {
    padding-left: var(--space-020);
    padding-right: var(--space-020);
  }
  .f-p-h-020\:l {
    padding-left: var(--space-020);
    padding-right: var(--space-020);
  }
  .f-p-h-030\:m {
    padding-left: var(--space-030);
    padding-right: var(--space-030);
  }
  .f-p-h-040\:m {
    padding-left: var(--space-040);
    padding-right: var(--space-040);
  }
  .f-p-h-050\:m {
    padding-left: var(--space-050);
    padding-right: var(--space-050);
  }
  .f-p-h-060\:m {
    padding-left: var(--space-060);
    padding-right: var(--space-060);
  }
  .f-p-h-080\:m {
    padding-left: var(--space-080);
    padding-right: var(--space-080);
  }
  .f-p-h-090\:m {
    padding-left: var(--space-090);
    padding-right: var(--space-090);
  }
  .f-p-t-010\:m {
    padding-top: var(--space-010);
  }
  .f-p-t-020\:m {
    padding-top: var(--space-020);
  }
  .f-p-t-030\:m {
    padding-top: var(--space-030);
  }
  .f-p-t-040\:m {
    padding-top: var(--space-040);
  }
  .f-p-t-060\:m {
    padding-top: var(--space-060);
  }
  .f-p-t-080\:m {
    padding-top: var(--space-080);
  }
  .f-p-r-060\:m {
    padding-right: var(--space-060);
  }
  .f-p-b-020\:m {
    padding-bottom: var(--space-020);
  }
  .f-p-b-030\:m {
    padding-bottom: var(--space-030);
  }
  .f-p-b-040\:m {
    padding-bottom: var(--space-040);
  }
  .f-p-b-060\:m {
    padding-bottom: var(--space-060);
  }
  .f-p-b-080\:m {
    padding-bottom: var(--space-080);
  }
  .f-p-l-120\:m {
    padding-left: var(--space-120);
  }
}

@media (min-width: 1200px) {
  .f-m-t-080\:l {
    margin-top: var(--space-080);
  }
  .f-m-r-030\:l {
    margin-right: var(--space-030);
  }
  .f-p-v-020\:l {
    padding-top: var(--space-020);
    padding-bottom: var(--space-020);
  }
  .f-p-t-080\:l {
    padding-top: var(--space-080);
  }
  .f-p-h-040\:l {
    padding-left: var(--space-040);
    padding-right: var(--space-040);
  }
  .f-p-l-020\:l {
    padding-left: var(--space-020);
  }
  .f-p-l-030\:l {
    padding-left: var(--space-030);
  }
}

/* Grid Helpers. */
.f-g-r {
  display: grid;
  grid-column-gap: calc(100% / 47);
  grid-template-columns: repeat(24, calc(100% / 47));
}

@media (max-width: 767px) {
  .f-g-r-\!wrap {
    margin-left: calc(var(--base-unit) * -2);
    margin-right: calc(var(--base-unit) * -2);
  }
}

.f-g-r.is-reversed {
  direction: rtl;
}

.f-g-r.is-reversed [class*='f-g-c'] {
  direction: ltr;
}

.f-g-c1 {
  grid-column: span 1;
}
.f-g-c2 {
  grid-column: span 2;
}
.f-g-c3 {
  grid-column: span 3;
}
.f-g-c4 {
  grid-column: span 4;
}
.f-g-c5 {
  grid-column: span 5;
}
.f-g-c6 {
  grid-column: span 6;
}
.f-g-c7 {
  grid-column: span 7;
}
.f-g-c8 {
  grid-column: span 8;
}
.f-g-c9 {
  grid-column: span 9;
}
.f-g-c10 {
  grid-column: span 10;
}
.f-g-c11 {
  grid-column: span 11;
}
.f-g-c12 {
  grid-column: span 12;
}
.f-g-c13 {
  grid-column: span 13;
}
.f-g-c14 {
  grid-column: span 14;
}
.f-g-c15 {
  grid-column: span 15;
}
.f-g-c16 {
  grid-column: span 16;
}
.f-g-c17 {
  grid-column: span 17;
}
.f-g-c18 {
  grid-column: span 18;
}
.f-g-c19 {
  grid-column: span 19;
}
.f-g-c20 {
  grid-column: span 20;
}
.f-g-c21 {
  grid-column: span 21;
}
.f-g-c22 {
  grid-column: span 22;
}
.f-g-c23 {
  grid-column: span 23;
}
.f-g-c24 {
  grid-column: span 24;
}

@media (min-width: 768px) {
  .f-g-c1\:m {
    grid-column: span 1;
  }
  .f-g-c2\:m {
    grid-column: span 2;
  }
  .f-g-c3\:m {
    grid-column: span 3;
  }
  .f-g-c4\:m {
    grid-column: span 4;
  }
  .f-g-c5\:m {
    grid-column: span 5;
  }
  .f-g-c6\:m {
    grid-column: span 6;
  }
  .f-g-c7\:m {
    grid-column: span 7;
  }
  .f-g-c8\:m {
    grid-column: span 8;
  }
  .f-g-c9\:m {
    grid-column: span 9;
  }
  .f-g-c10\:m {
    grid-column: span 10;
  }
  .f-g-c11\:m {
    grid-column: span 11;
  }
  .f-g-c12\:m {
    grid-column: span 12;
  }
  .f-g-c13\:m {
    grid-column: span 13;
  }
  .f-g-c14\:m {
    grid-column: span 14;
  }
  .f-g-c15\:m {
    grid-column: span 15;
  }
  .f-g-c16\:m {
    grid-column: span 16;
  }
  .f-g-c17\:m {
    grid-column: span 17;
  }
  .f-g-c18\:m {
    grid-column: span 18;
  }
  .f-g-c19\:m {
    grid-column: span 19;
  }
  .f-g-c20\:m {
    grid-column: span 20;
  }
  .f-g-c21\:m {
    grid-column: span 21;
  }
  .f-g-c22\:m {
    grid-column: span 22;
  }
  .f-g-c23\:m {
    grid-column: span 23;
  }
  .f-g-c24\:m {
    grid-column: span 24;
  }
}

@media (min-width: 1200px) {
  .f-g-c1\:l {
    grid-column: span 1;
  }
  .f-g-c2\:l {
    grid-column: span 2;
  }
  .f-g-c3\:l {
    grid-column: span 3;
  }
  .f-g-c4\:l {
    grid-column: span 4;
  }
  .f-g-c5\:l {
    grid-column: span 5;
  }
  .f-g-c6\:l {
    grid-column: span 6;
  }
  .f-g-c7\:l {
    grid-column: span 7;
  }
  .f-g-c8\:l {
    grid-column: span 8;
  }
  .f-g-c9\:l {
    grid-column: span 9;
  }
  .f-g-c10\:l {
    grid-column: span 10;
  }
  .f-g-c11\:l {
    grid-column: span 11;
  }
  .f-g-c12\:l {
    grid-column: span 12;
  }
  .f-g-c13\:l {
    grid-column: span 13;
  }
  .f-g-c14\:l {
    grid-column: span 14;
  }
  .f-g-c15\:l {
    grid-column: span 15;
  }
  .f-g-c16\:l {
    grid-column: span 16;
  }
  .f-g-c17\:l {
    grid-column: span 17;
  }
  .f-g-c18\:l {
    grid-column: span 18;
  }
  .f-g-c19\:l {
    grid-column: span 19;
  }
  .f-g-c20\:l {
    grid-column: span 20;
  }
  .f-g-c21\:l {
    grid-column: span 21;
  }
  .f-g-c22\:l {
    grid-column: span 22;
  }
  .f-g-c23\:l {
    grid-column: span 23;
  }
  .f-g-c24\:l {
    grid-column: span 24;
  }
}

@media all and (-ms-high-contrast: none) {
  .f-g-r {
    display: flex;
    flex-wrap: wrap;
  }

  [class*='f-g-c'] {
    margin: calc(calc(100% / 24) * 0.25);
  }

  .f-g-c1 {
    flex: 0 1 calc(calc(calc(100% / 24) * 1) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 1) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c2 {
    flex: 0 1 calc(calc(calc(100% / 24) * 2) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 2) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c3 {
    flex: 0 1 calc(calc(calc(100% / 24) * 3) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 3) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c4 {
    flex: 0 1 calc(calc(calc(100% / 24) * 4) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 4) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c5 {
    flex: 0 1 calc(calc(calc(100% / 24) * 5) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 5) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c6 {
    flex: 0 1 calc(calc(calc(100% / 24) * 6) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 6) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c7 {
    flex: 0 1 calc(calc(calc(100% / 24) * 7) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 7) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c8 {
    flex: 0 1 calc(calc(calc(100% / 24) * 8) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 8) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c9 {
    flex: 0 1 calc(calc(calc(100% / 24) * 9) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 9) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c10 {
    flex: 0 1 calc(calc(calc(100% / 24) * 10) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 10) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c11 {
    flex: 0 1 calc(calc(calc(100% / 24) * 11) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 11) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c12 {
    flex: 0 1 calc(calc(calc(100% / 24) * 12) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 12) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c13 {
    flex: 0 1 calc(calc(calc(100% / 24) * 13) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 13) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c14 {
    flex: 0 1 calc(calc(calc(100% / 24) * 14) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 14) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c15 {
    flex: 0 1 calc(calc(calc(100% / 24) * 15) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 15) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c16 {
    flex: 0 1 calc(calc(calc(100% / 24) * 16) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 16) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c17 {
    flex: 0 1 calc(calc(calc(100% / 24) * 17) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 17) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c18 {
    flex: 0 1 calc(calc(calc(100% / 24) * 18) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 18) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c19 {
    flex: 0 1 calc(calc(calc(100% / 24) * 19) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 19) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c20 {
    flex: 0 1 calc(calc(calc(100% / 24) * 20) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 20) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c21 {
    flex: 0 1 calc(calc(calc(100% / 24) * 21) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 21) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c22 {
    flex: 0 1 calc(calc(calc(100% / 24) * 22) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 22) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c23 {
    flex: 0 1 calc(calc(calc(100% / 24) * 23) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 23) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c24 {
    flex: 0 1 calc(calc(calc(100% / 24) * 24) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 24) - calc(calc(100% / 24) * 0.5));
  }
}

@media all and (min-width: 768px) and (-ms-high-contrast: none) {
  .f-g-c1\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 1) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 1) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c2\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 2) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 2) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c3\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 3) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 3) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c4\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 4) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 4) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c5\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 5) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 5) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c6\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 6) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 6) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c7\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 7) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 7) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c8\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 8) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 8) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c9\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 9) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 9) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c10\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 10) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 10) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c11\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 11) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 11) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c12\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 12) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 12) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c13\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 13) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 13) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c14\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 14) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 14) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c15\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 15) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 15) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c16\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 16) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 16) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c17\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 17) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 17) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c18\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 18) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 18) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c19\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 19) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 19) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c20\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 20) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 20) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c21\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 21) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 21) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c22\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 22) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 22) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c23\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 23) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 23) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c24\:m {
    flex: 0 1 calc(calc(calc(100% / 24) * 24) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 24) - calc(calc(100% / 24) * 0.5));
  }
}

@media all and (min-width: 1200px) and (-ms-high-contrast: none) {
  .f-g-c1\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 1) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 1) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c2\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 2) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 2) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c3\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 3) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 3) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c4\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 4) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 4) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c5\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 5) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 5) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c6\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 6) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 6) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c7\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 7) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 7) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c8\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 8) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 8) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c9\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 9) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 9) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c10\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 10) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 10) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c11\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 11) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 11) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c12\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 12) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 12) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c13\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 13) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 13) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c14\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 14) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 14) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c15\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 15) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 15) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c16\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 16) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 16) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c17\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 17) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 17) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c18\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 18) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 18) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c19\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 19) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 19) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c20\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 20) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 20) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c21\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 21) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 21) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c22\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 22) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 22) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c23\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 23) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 23) - calc(calc(100% / 24) * 0.5));
  }
  .f-g-c24\:l {
    flex: 0 1 calc(calc(calc(100% / 24) * 24) - calc(calc(100% / 24) * 0.5));
    max-width: calc(calc(calc(100% / 24) * 24) - calc(calc(100% / 24) * 0.5));
  }
}
