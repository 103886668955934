.FullTextSearch-results-container {
  margin-top: var(--navbar-height);
}

.FullTextSearch-results-container .TabsGenerator-tab-item {
  font-family: var(--fancy-font-family);
  border-bottom: solid 2px var(--colour-primary-600);
  opacity: 0.3;
  cursor: pointer;
}

.FullTextSearch-results-container .react-tabs__tab--selected {
  border-bottom: solid 2px var(--colour-primary-600);
  opacity: 1;
}

.TabsGenerator-tab-list {
  overflow: auto;
}

.TabsGenerator-tab-list.SADB-opportunity-detail-page-tabs-list.SADB-opportunity-detail-page-tabs-list {
  width: fit-content;
  margin: 0 0 0 var(--space-010);
  padding: 0;
}

.FullTextSearch-results-container
  > .TabsGenerator-tab-item.TabsGenerator-tab-item {
  border: none;
  width: revert;
  min-width: revert;
  padding: var(--space-010) var(--space-020);
  margin-right: var(--space-020);
}

.FullTextSearch-results-container
  > .TabsGenerator-tab-item.TabsGenerator-tab-item[data-selected] {
  border-bottom: solid 2px var(--colour-primary-600);
}

@media (min-width: 768px) {
  .TabsGenerator-tab-list {
    padding-left: calc(var(--base-unit) * 2);
  }
}

@media (min-width: 1200px) {
  .FullTextSearch-results-container .TabsGenerator-tab-item {
    text-align: center;
    padding-left: 0;
  }
}
