.review-page-standalone-header {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 100%;
  background-color: var(--colour-white);
  box-shadow: var(--action-card-box-shadow);
}

.review-page-standalone-header__logo {
  height: 42px;
}

.review-page-standalone__thank-you-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
}

.review-page-standalone__thank-you-icon svg {
  height: 48px;
  width: 48px;
}

.review-page-standalone__step_container {
  margin: 0 16px;
}
