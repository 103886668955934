.NewCTA__base {
  display: inline-block;
  cursor: pointer;
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  padding: 14px 40px;
}

/* Primary */

.NewCTA__primary--default {
  background-color: #86f890;
  border-radius: var(--border-radius-cta);
  border: 0 #86f890;
  box-shadow: 0 6px 18px -15px #3b4768, 0 5px 30px -5px rgb(134 248 144 / 60%);
  color: #3b4768;
}

.NewCTA__primary--default:hover {
  background: #86f890;
  border: 0;
  box-shadow: 0 6px 12px -8px #000, 0 5px 30px -5px rgb(134 248 144 / 60%);
  transform: translateY(-1px);
}

.NewCTA__primary--default:active {
  box-shadow: none;
}

.NewCTA__primary--active,
.NewCTA__primary--active:active {
  background-color: #86f890;
  box-shadow: none;
}

.NewCTA__primary--active:hover {
  box-shadow: none;
  transform: unset;
}

.NewCTA__primary--hover,
.NewCTA__primary--hover:hover {
  background: #86f890;
  color: #3b4768;
  box-shadow: 0 6px 12px -8px #000, 0 5px 30px -5px rgb(134 248 144 / 60%);
  transform: translateY(-1px);
}

.NewCTA__primary--hover:active {
  box-shadow: 0 6px 12px -8px #000, 0 5px 30px -5px rgb(134 248 144 / 60%);
}

.NewCTA__primary--disabled,
.NewCTA__primary--disabled:hover {
  cursor: auto;
  color: #fff;
  background-color: #b8bbc3;
  border: 0;
  box-shadow: none;
  transform: none;
}

/* Secondary */

.NewCTA__secondary--default {
  background-color: #3b4768;
  border-radius: var(--border-radius-cta);
  border: 0 #3b4768;
  box-shadow: 0 6px 18px -15px #3b4768, 0 5px 30px -5px rgba(61, 121, 182, 0.33);
  color: #fff;
}

.NewCTA__secondary--default:hover {
  background: #3b4768;
  border: 0;
  box-shadow: 0 6px 12px -8px #3b4768, 0 5px 30px -5px rgba(61, 121, 182, 0.33);
  transform: translateY(-1px);
}

.NewCTA__secondary--default:active {
  box-shadow: none;
}

.NewCTA__secondary--active,
.NewCTA__secondary--active:active {
  background-color: #3b4768;
  box-shadow: none;
}

.NewCTA__secondary--active:hover {
  box-shadow: none;
  transform: unset;
}

.NewCTA__secondary--hover,
.NewCTA__secondary--hover:hover {
  background: #3b4768;
  box-shadow: 0 6px 12px -8px #1f2e49, 0 5px 30px -5px rgba(61, 121, 182, 0.33);
  transform: translateY(-1px);
}

.NewCTA__secondary--hover:active {
  box-shadow: 0 6px 12px -8px #1f2e49, 0 5px 30px -5px rgba(61, 121, 182, 0.33);
}

.NewCTA__secondary--disabled,
.NewCTA__secondary--disabled:hover {
  cursor: auto;
  color: #fff;
  background-color: #b8bbc3;
  border: 0;
  box-shadow: none;
  transform: none;
}

/* Tertiary */

.NewCTA__tertiary--default {
  background-color: unset;
  border: solid 2px #3b4768;
  border-radius: var(--border-radius-cta);
  color: #3b4768;
}

.NewCTA__tertiary--default:hover {
  background: unset;
  color: #3d79b6;
  border: 2px solid #3d79b6;
  box-shadow: none;
  transform: unset;
}

.NewCTA__tertiary--default:active {
  color: #2f3a58;
  border: 2px solid #2f3a58;
  box-shadow: none;
}

.NewCTA__tertiary--active,
.NewCTA__tertiary--active:active {
  border: 2px solid #2f3a58;
  box-shadow: none;
}

.NewCTA__tertiary--active:hover {
  color: #2f3a58;
  border: 2px solid #2f3a58;
  box-shadow: none;
  transform: unset;
}

.NewCTA__tertiary--hover,
.NewCTA__tertiary--hover:hover {
  background: unset;
  color: #3d79b6;
  border: 2px solid #3d79b6;
  box-shadow: none;
  transform: unset;
}

.NewCTA__tertiary--hover:active {
}

.NewCTA__tertiary--disabled,
.NewCTA__tertiary--disabled:hover {
  cursor: auto;
  color: #b8bbc3;
  background-color: unset;
  border: 2px solid #b8bbc3;
  box-shadow: none;
  transform: none;
}
