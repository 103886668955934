.FormRadio-wrapper {
  display: inline-block;
  position: relative;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .FormRadio-wrapper:hover {
    cursor: pointer;
  }
}

.FormRadio-wrapper.is-full-width {
  max-width: 100%;
  width: 100%;
}

.FormRadio-wrapper-icon {
  max-width: 160px;
  width: 40%;
  padding: var(--space-010);
}

.FormRadio-wrapper-icon {
  border-width: 1px;
  border-style: solid;
}

.FormRadio-wrapper-icon.is-checked {
  border-color: var(--colour-tertiary-400);
}

.FormRadio-wrapper-basic {
  display: block;
  background: none;
}

.FormRadio-wrapper-basic-block {
  border: 1px solid transparent;
}

.FormRadio-wrapper-basic-block.is-checked {
  border-color: var(--colour-tertiary-400);
}

.FormRadio-wrapper-form:first-child {
  border-radius: var(--border-radius-card-large) 0 0
    var(--border-radius-card-large);
}

.FormRadio-wrapper-form + .FormRadio-wrapper-form {
  margin-left: -1px;
}

.FormRadio-wrapper-form:last-child {
  border-radius: 0 var(--border-radius-card-large)
    var(--border-radius-card-large) 0;
}

.FormRadio-wrapper-form {
  border-width: 1px;
  border-style: solid;
  padding: 12px var(--space-030);
}

.FormRadio-wrapper-form.is-checked {
  position: relative;
  z-index: 1;
}

.FormRadio {
  float: left;
  margin-left: -99999px;
}

.FormRadio-dot {
  background: var(--colour-white);
  border: 2px solid var(--colour-neutral-050);
  border-radius: 24px;
  display: inline-block;
  height: 24px;
  position: relative;
  vertical-align: middle;
  width: 24px;
}

.FormRadio:checked ~ .FormRadio-dot,
.FormRadio-dot.is-checked {
  border-color: var(--radio-checked-color);
}

.FormRadio:checked ~ .FormRadio-dot:after,
.FormRadio-dot.is-checked:after {
  background: var(--radio-checked-color);
  border-radius: 50%;
  content: '';
  height: 13px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 13px;
}

.is-corner-dot {
  position: absolute;
  left: 8px;
  top: 0;
}

.FormRadio-wrapper-block {
  border-style: solid;
  border-width: 1px;
  min-height: 112px; /* This is here for IE11 */
}
