.CardCarousel-bullets {
  box-sizing: content-box;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: var(--space-020) var(--space-020) 0;
  text-align: center;
  transform: translateX(-50%);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

.CardCarousel-bullet {
  background-color: var(--colour-neutral-050);
  border: 0;
  display: inline-block;
  height: var(--space-010);
  width: var(--space-010);
  margin: var(--space-005);
  outline: none;
  padding: 0;
  position: relative;
  transition: transform 0.2s ease, left 0.2s ease;
  transform: scale(0.4);
}

.CardCarousel-bullet.is-active {
  background-color: var(--colour-primary-600);
  transform: scale(1);
}

.CardCarousel-bullet.is-active-prev,
.CardCarousel-bullet.is-active-next {
  transform: scale(0.7);
}

.CardCarousel-arrow {
  background: var(--colour-white) !important;
  height: 32px;
  opacity: 0.6;
  position: absolute;
  top: calc(50% - 16px);
  transition: opacity 0.2s ease;
  transform: translateY(-50%);
  width: 32px;
  z-index: 1;
}

.CardCarousel-arrow-next {
  right: 0;
}

.CardCarousel-arrow-prev {
  left: 0;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .CardCarousel-wrapper:hover .CardCarousel-arrow {
    opacity: 1;
  }

  .CardCarousel-wrapper .CardCarousel-arrow:hover {
    background-color: var(--colour-primary-600) !important;
  }

  .CardCarousel-wrapper .CardCarousel-arrow:hover use {
    stroke: var(--colour-white);
  }
}

.CardCarousel-arrow.is-disabled {
  display: none;
}

.CardCarousel-wrapper-initialized .Card:not(.is-visible) {
  visibility: hidden;
}

.CardCarousel-wrapper-initialized .Card.is-visible {
  opacity: 1;
}
