.VendorCarousel-cards {
  transition-timing-function: linear;
}

.VendorCarousel-card {
  flex: 0 0 261px;
  height: 261px;
  width: 261px;
}

.VendorCarousel-card-img {
  left: 50%;
  max-height: 120px;
  max-width: 200px;
  position: absolute;
  pointer-events: none;
  top: 50%;
  transform: translate(-50%, -50%);
}
