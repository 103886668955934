.FormText-wrapper {
  background: var(--colour-white);
  border-radius: var(--border-radius-inputs);
  cursor: text;
  font-size: var(--base-font-size);
  height: 48px;
  max-width: 480px;
}

.FormText-wrapper:not(.FormPhone-wrapper) {
  padding-bottom: 10px;
  padding-top: 22px;
}

.FormText-wrapper ::placeholder {
  color: var(--colour-neutral-100);
}

.FormText-wrapper ::-ms-input-placeholder {
  color: var(--colour-neutral-100);
}

.FormText-wrapper :-ms-input-placeholder {
  color: var(--colour-neutral-100);
}

.FormText-wrapper:focus-within {
  border-color: var(--colour-neutral-200) !important;
}

.FormText-wrapper.is-full-width {
  max-width: 100%;
  width: 100%;
}

@media (max-width: 767px) {
  .full-width\:s {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .FormText-wrapper.is-block\:s {
    max-width: 100%;
    width: 100%;
  }
}

.FormText-wrapper.has-border {
  border-style: solid;
  border-width: 1px;
}

.FormText-wrapper.is-disabled {
  cursor: pointer;
  pointer-events: none;
}

.FormTextLabel {
  top: 10px;
  transition: transform 0.2s ease, top 0.2s ease;
  transform-origin: top left;
  pointer-events: none;
}

.FormTextLabel.has-value,
.FormText-wrapper:focus-within .FormTextLabel {
  top: 4px;
  transform: scale(0.7);
}

.FormText {
  background: rgba(255, 255, 255, 0.0001);
  border: 0;
  cursor: text;
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: -2px 0 -1px; /* Fixes line-height weirdness */
  outline: 0;
  text-align: inherit;
  width: 100%;
}

.FormText--disabled input {
  color: var(--colour-neutral-400);
}

.FormText.is-password {
  padding: 0 var(--space-030) 0 0;
}

.FormText-password-toggle {
  right: 10px;
  top: 12px;
}

/* Remove autofill blue background on Chrome */
.FormText:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px var(--colour-white) inset;
}

.FormTextGeosuggest {
  padding: calc(var(--space-020) - 2px); /* Remove 1px from the border */
  margin: 0;
}
