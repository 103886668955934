.ActionCardTemplate {
  height: 128px;
  width: 140px;
  transition: transform 0.2s ease;
  border-radius: var(--border-radius-cta);
}

.ActionCardTemplate:not(.is-disabled):after {
  box-shadow: var(--action-card-box-shadow);
  transition: box-shadow 0.2s ease;
}

.ActionCardTemplate.has-strong-shadow:not(.is-disabled):after {
  box-shadow: 0 5px 10px -1px rgba(59, 71, 104, 0.05), 0 5px 6px -3px #000000,
    0 2px 40px 0 rgba(59, 71, 104, 0.05);
}

.ActionCardTemplate.is-disabled:after {
  box-shadow: var(--card-box-shadow);
}

@media (max-width: 371px) {
  .ActionCardTemplate {
    height: 126px;
    width: 138px;
  }
}

@media (min-width: 372px) and (max-width: 767px) {
  .ActionCardTemplate {
    height: 126px;
    width: 150px;
  }
}

@media (min-width: 768px) {
  .ActionCardTemplate {
    height: 150px;
    width: 150px;
  }
}

@media (max-width: 371px) {
  .ActionCardTemplate .f-t-tbody {
    font-size: 0.9rem !important;
  }
}

.ActionCardTemplate:after {
  border: 1px solid transparent;
  border-radius: var(--border-radius-cta);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.ActionCardTemplate.is-active:after {
  border-color: var(--colour-secondary);
  border-width: 2px;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .ActionCardTemplate:not(.is-disabled):hover {
    transform: translateY(-1px);
  }

  .ActionCardTemplate:not(.is-disabled):active:after,
  .ActionCardTemplate:not(.is-disabled):hover:after {
    box-shadow: var(--action-card-box-shadow-hover);
  }
}

.ActionCardTemplate-large {
  height: 192px;
}

.ActionCardTemplate-compact {
  height: 160px;
}

@media (max-width: 371px) {
  .ActionCardTemplate-large {
    width: 140px;
  }

  .ActionCardTemplate-large .f-t-tbody {
    font-size: 0.9rem !important;
  }
}

@media (min-width: 372px) and (max-width: 819px) {
  .ActionCardTemplate-large {
    width: 166px;
  }
  .ActionCardTemplate-compact {
    width: 168px;
  }
}

@media (min-width: 820px) {
  .ActionCardTemplate-large {
    width: 178px;
  }
  .ActionCardTemplate-compact {
    width: 168px;
  }
}

@media (max-width: 371px) {
  .ActionCardTemplate-large {
    width: 138px;
  }
  .ActionCardTemplate-compact {
    width: 168px;
  }
}

.ActionCardTemplate-holder {
  flex: 0 0 auto;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
}

.ActionCardTemplate-holder--compact {
  align-items: center;
  height: 120px;
}

.QuestionRadio-card-tiles.QuestionBudget .ActionCardTemplate-holder--compact {
  height: 110px;
}

.ActionCardTemplate-holder.ActionCardTemplate-holder--stretched {
  flex: 1 0 auto;
}

.ActionCardTemplate-holder--with-image {
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-cta) var(--border-radius-cta) 0 0;
}

.ActionCardTemplate-holder.ActionCardTemplate-holder--with-image.ActionCardTemplate-holder--with-small-image {
  padding: 0;
  border-radius: var(--border-radius-cta) var(--border-radius-cta) 0 0;
}

.ActionCardTemplate-holder--with-small-image img {
  height: 92px;
  width: 100%;
  object-fit: fill;
}

.QuizWidget-wrap .ActionCardTemplate-holder--with-small-image img {
  height: 104px;
}

.ActionCardTemplate-image {
  max-height: 136px;
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.ActionCardTemplate-text {
  flex: 1 0 auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  overflow: hidden;
  border-radius: 0 0 var(--border-radius-cta) var(--border-radius-cta);
}

.ActionCardTemplate-text--compact {
  height: 40px;
}

.QuestionRadio-card-tiles.QuestionBudget .ActionCardTemplate-text--compact {
  height: 50px;
}

.ActionCardTemplate-text > span {
  font-size: 1rem;
  transition: color 0.2s ease;
}

.ActionCardTemplate-text--compact > span {
  font-size: 14px;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .ActionCardTemplate:hover .ActionCardTemplate-text > span {
    color: var(--colour-tertiary-400);
  }
}

.ActionCardTemplate-image-holder {
  height: 80px;
}

@media (max-width: 767px) {
  .ActionCardTemplate-icon {
    height: 56px;
    width: 56px;
  }
}

@media (-ms-high-contrast: none) {
  .ActionCardTemplate-image-holder > img {
    flex: 0 0 auto;
  }
}
