/*
  Fertighaus Reset + Normalize – keep as basic as possible
*/

*,
*:before,
*:after {
  box-sizing: border-box;
}

/*! normalize.css v8.0.1 - edited */

/* Document
   ========================================================================== */

/**
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */

html {
  -webkit-text-size-adjust: 100%; /* 2 */
  overflow-y: scroll;
}

/* Sections
   ========================================================================== */

/**
 * Remove the margin in all browsers.
 */

body {
  margin: 0;
  max-width: 100vw;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * Render the `main` element consistently in IE.
 */

main {
  display: block;
}

/* Grouping content
   ========================================================================== */

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/* Text-level semantics
   ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
}

/* Remove spacing and quotation marks */

blockquote {
  margin: 0;
}

q::after,
q::before {
  content: '';
}

q {
  font-style: normal;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */

/**
 * Remove the border on images inside links in IE 10.
 */

img {
  border-style: none;
}

/* Forms
   ========================================================================== */

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Misc
   ========================================================================== */

/**
 * Add the correct display in IE 10.
 */

[hidden] {
  display: none;
}

/* normalise.css ends here
   ========================================================================== */

/**
 * Gets rid of element outline for mouse users, but keeps it for keyboard users
 */

:focus:not(:focus-visible) {
  outline: none;
}

:focus-visible {
  outline: 2px solid var(--colour-primary-600);
}

/**
 * Sharper images for Chrome, plus max-width
 */

img {
  height: auto;
  /* image-rendering: -webkit-optimize-contrast; */
  max-width: 100%;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * We don't want SVG to capture clicks
 */

svg,
img[src$='.svg'] {
  pointer-events: none;
}

button {
  font-family: inherit;
}

ol {
  padding-left: var(--space-030);
}

ol li:not(.f-m-a-0) {
  padding-left: var(--space-010);
}

/* On-Demand Resets */

.f-\!list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.f-\!underline {
  text-decoration: none !important;
}

.f-\!button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

dl,
dd {
  margin: 0;
}
