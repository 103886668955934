/*
  Fertighaus Colours – keep as agnostic as possible

  Letters' meaning:
  - fo = foreground
  - ba = background
  - bo = border
  - fi = fill (for SVG <path> elements)
  - st = stroke (for SVG <path> elements)

  - pri = primary
  - sec = secondary
  - ter = tertiary
  - suc = success
  - err = error
  - war = warning
  - awa = award
*/

:root {
  /* semantic variables */
  --colour-primary-800: #313b56;
  --colour-primary-600: #3b4768;
  --colour-primary-600-050: rgba(59, 71, 104, 0.5);
  --colour-primary-400: #606a85;
  --colour-primary-035: #e7e8ec;

  --colour-secondary: #86f890;

  --colour-tertiary-200: #5187c1;
  --colour-tertiary-400: #1f64af;

  --colour-award: #f8bd32;
  --colour-award-075: #ffc233;

  --colour-warning: #ffa366;

  --colour-error: #f53158;

  --colour-paper-015: #fff9ec;
  --colour-paper-025: #ffe8b3;

  --colour-success-025: #d8f8ef;
  --colour-success-050: #80e7cc;
  --colour-success-100: #2bd0a4;
  --colour-success-400: #177059;

  --colour-neutral-025: #f0f1f2;
  --colour-neutral-050: #d2d4d9;
  --colour-neutral-100: #b6b9c0;
  --colour-neutral-200: #7e8491;
  --colour-neutral-400: #5e6371;
  --colour-neutral-600: #434751;

  --colour-background-010: #fbfcfe;
  --colour-background-015: #f7fafc;
  --colour-background-025: #f3f7fb;
  --colour-background-050: #c4d6ea;

  --accent-green-200: #1e9575;

  --colour-white: #fff;
  --colour-black: #000;

  /* contextual variables */

  --cta-primary-hover-box-shadow: 0 6px 12px -8px var(--colour-black),
    0 5px 30px -5px rgba(127, 235, 137, 0.6);
  --cta-primary-box-shadow: 0 6px 18px -15px var(--colour-primary-600),
    0 5px 30px -5px var(--colour-secondary);
  --cta-primary-box-shadow-with-opacity: 0 6px 18px -15px var(--colour-primary-600),
    0 5px 30px -5px rgba(127, 235, 137, 0.6);
  --cta-tertiary-inverted-hover-box-shadow: 0 6px 18px -15px var(--colour-background-010),
    0 5px 30px -5px var(--colour-background-015);
  --quiz-step-transition-background: rgba(243, 247, 251, 0.9);
  --cta-error-box-shadow-with-opacity: 0 6px 18px -15px var(--colour-error),
    0 5px 30px -5px rgba(195, 9, 46, 0.6);

  --border-radius-cta: 4px;
  --border-radius-inputs: 4px;
  --border-radius-card-medium: 4px;
  --border-radius-card-large: 6px;
  /*background025 colour with 0.9 opacity*/

  --text-main-on-dark: var(--colour-white);
  --text-main-on-light: var(--colour-primary-600);
  --text-on-secondary: var(--colour-primary-600);
  --text-accent-on-dark: var(--colour-secondary);
  --text-accent-on-light: var(--colour-secondary);

  --hero-background-overlay: linear-gradient(
    rgba(59, 71, 104, 0.85),
    rgba(59, 71, 104, 0.85)
  );
  --hero-background-overlay-light: linear-gradient(
    rgba(244, 246, 251, 0.85),
    rgba(244, 246, 251, 0.85)
  );
  --hero-background-overlay-mobile: linear-gradient(
    rgba(59, 71, 104, 0.85) 40%,
    rgba(59, 71, 104, 0.85) 55%
  );
  --hero-background-overlay-light-mobile: linear-gradient(
    rgba(244, 246, 251, 0.85) 40%,
    rgba(244, 246, 251, 0.85) 55%
  );
  --hero-background-overlay-bar-tiles: linear-gradient(
    rgba(59, 71, 104, 0.4),
    rgba(59, 71, 104, 0.4)
  );

  /* templates/PageFooter: START */
  --page-footer-background-top: var(--colour-primary-400);
  --page-footer-background-mid: var(--colour-primary-600);
  --page-footer-background-bottom: var(--colour-primary-800);

  --page-footer-text: var(--colour-white);
  --page-footer-text-link: var(--colour-white);
  --page-footer-text-link-alt: var(--colour-white);
  /* templates/PageFooter: END */

  --search-result-background: var(--colour-background-010);
  --radio-checked-color: var(--colour-success-100);

  --guidebook-activation-modal-shadow: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(59, 71, 104, 0.6) 100%
  );
}

/* Foregrounds */
.f-c-fo-awa {
  color: var(--colour-award);
}

.f-c-fo-suc400 {
  color: var(--colour-success-400);
}

.f-c-fo-err {
  color: var(--colour-error);
}

.f-c-fo-war {
  color: var(--colour-warning);
}

.f-c-fo-neu025 {
  color: var(--colour-neutral-025);
}
.f-c-fo-neu100 {
  color: var(--colour-neutral-100);
}
.f-c-fo-neu200 {
  color: var(--colour-neutral-200);
}
.f-c-fo-neu400 {
  color: var(--colour-neutral-400);
}

/* Foregrounds on hover */
@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .f-c-fo_h-neu400:hover {
    color: var(--colour-neutral-400);
  }
}

/* Borders */

.f-c-bo-awa {
  border-color: var(--colour-award);
}
.f-c-bo-whi {
  border-color: var(--colour-white);
}
.f-c-bo-neu025 {
  border-color: var(--colour-neutral-025);
}
.f-c-bo-neu025 {
  border-color: var(--colour-neutral-025);
}
.f-c-bo-neu050 {
  border-color: var(--colour-neutral-050);
}

/* Foregrounds. Add on-need basis. */
.f-c-fo-bac025 {
  color: var(--colour-background-025);
}
.f-c-fo-neu100 {
  color: var(--colour-neutral-100);
}
.f-c-fo-neu400 {
  color: var(--colour-neutral-400);
}

/* Backgrounds. Add on-need basis. */
.f-c-ba-awa {
  background-color: var(--colour-award);
}
.f-c-ba-err {
  background-color: var(--colour-error);
}
.f-c-ba-war {
  background-color: var(--colour-warning);
}
.f-c-ba-pri400 {
  background-color: var(--colour-primary-400);
}
.f-c-ba-pri600 {
  background-color: var(--colour-primary-600);
}
.f-c-ba-pri800 {
  background-color: var(--colour-primary-800);
}
.f-c-ba-ter {
  background-color: var(--colour-tertiary-400);
}
.f-c-ba-suc025 {
  background-color: var(--colour-success-025);
}
.f-c-ba-suc050 {
  background-color: var(--colour-success-050);
}
.f-c-ba-suc100 {
  background-color: var(--colour-success-100);
}
.f-c-ba-suc400 {
  background-color: var(--colour-success-400);
}
.f-c-ba-acc200 {
  background-color: var(--accent-green-200);
}
.f-c-ba-bac010 {
  background-color: var(--colour-background-010);
}
.f-c-ba-bac015 {
  background-color: var(--colour-background-015);
}
.f-c-ba-bac025 {
  background-color: var(--colour-background-025);
}
.f-c-ba-bac050 {
  background-color: var(--colour-background-050);
}
.f-c-ba-neu025 {
  background-color: var(--colour-neutral-025);
}
.f-c-ba-neu050 {
  background-color: var(--colour-neutral-050);
}
.f-c-ba-neu100 {
  background-color: var(--colour-neutral-100);
}
.f-c-ba-pa015 {
  background-color: var(--colour-paper-015);
}
.f-c-ba-pa025 {
  background-color: var(--colour-paper-025);
}
.f-c-ba-bla {
  background-color: var(--colour-black);
}
.f-c-ba-transparent {
  background-color: transparent;
}

/* Background-modifiers. */
.is-section:nth-of-type(odd) {
  background: var(--colour-background-025);
}
.is-section:nth-of-type(even) {
  background: var(--colour-white);
}

/* Borders. Add on-need basis. */
.f-c-bo-war {
  border-color: var(--colour-warning);
}
.f-c-bo-suc400 {
  border-color: var(--colour-success-400);
}
.f-c-bo-bac025 {
  border-color: var(--colour-background-025);
}
