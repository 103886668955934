.reviews-section-vendor-summary__container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-020);
}

.reviews-section-vendor-summary {
  width: 100%;
  background: var(--colour-white);
  box-shadow: var(--action-card-box-shadow);
  padding: var(--space-020) 0;
}

.reviews-section-vendor-summary__rating-info {
  flex: 1;
}

.reviews-section-vendor-summary__rating-info svg {
  margin-right: 4px;
}

.reviews-section-vendor-summary__rating-info svg:last-of-type {
  margin-right: 0;
}

.reviews-section-vendor-summary__rating-info .f-t-tsection {
  margin-left: var(--space-010);
  font-size: 24px;
}

.reviews-section-vendor-summary__logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  border-radius: var(--border-radius-card-large);
  background: var(--colour-white);
  box-shadow: var(--action-card-box-shadow);
  padding: 4px;
}

.reviews-section-vendor-summary__logo-wrapper img {
  object-fit: contain;
  max-height: 100%;
  object-position: center;
}

.reviews-section-vendor-summary__tooltip.MuiTooltip-tooltip {
  background: var(--colour-white);
  box-shadow: 0 0 30px 0 rgba(59, 71, 104, 0.3);
  padding: var(--space-020);
  color: var(--colour-primary-600);
  font-family: var(--base-font-family);
  font-size: 12px;
  line-height: 166%;
}
.reviews-section-vendor-summary__tooltip .MuiTooltip-arrow {
  color: var(--colour-white);
}

.reviews-section-filters-container,
.reviews-section-filters-explanation-box {
  padding: var(--space-020);
  background: var(--colour-white);
  box-shadow: var(--action-card-box-shadow);
}

.reviews-section-filters-container {
  margin: var(--space-020) -16px;
}

.reviews-section-filters-explanation-box {
  margin: var(--space-020) -16px var(--space-040) -16px;
}

.reviews-section-filters-container .FormSelect-wrapper {
  border-color: var(--colour-neutral-100);
}

.reviews-section-rating-label {
  margin-left: var(--space-010);
}

.reviews-section-rating-label:before {
  content: '·';
  margin-right: var(--space-010);
}

.reviews-section-location-remove {
  --input-height: 50px;
  --icon-height: 24px;

  position: absolute;
  top: calc((var(--input-height) - var(--icon-height)) / 2);
  right: var(--space-010);
  cursor: pointer;
}

.reviews-section-rating-distribution-background {
  position: relative;
  height: 16px;
  flex: 1;
  border-radius: 8px;
  margin: 0 var(--space-020);
  background: var(--colour-background-025);
  overflow: hidden;
}

.reviews-section-rating-distribution-fill {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: var(--colour-primary-600);
  border-radius: 8px;
}

.reviews-section-rating-distribution-checkbox {
  height: 24px;
  cursor: pointer;
}

.reviews-section-rating-distribution-checkbox span {
  height: 24px;
  line-height: 24px;
}

.reviews-section-rating-distribution-label {
  height: 24px;
  line-height: 24px;
}

.reviews-section-rating-distribution-container {
  display: flex;
  flex-direction: column;
  gap: var(--space-030);
  margin-top: var(--space-030);
}

.reviews-section-vendor-summary__supplementary-box {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.reviews-section-vendor-summary__supplementary-text svg {
  margin-right: var(--space-010);
}

.review-card {
  padding: var(--space-030) var(--space-020) var(--space-040) var(--space-020);
  background: var(--colour-white);
  margin-bottom: var(--space-020);
  box-shadow: var(--action-card-box-shadow);
}

.review-card__content {
  margin-top: var(--space-020);
  border-top: 1px solid var(--colour-neutral-050);
}

.review-card__top-info {
  display: flex;
  flex-direction: column;
}

.review-card__top-info svg {
  margin-right: 4px;
}

.review-card__top-info svg:last-of-type {
  margin-right: 0;
}

.reviews-section-content {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--space-040);
}

.reviews-section-results {
  width: 100vw;
  margin: -16px;
  position: relative;
}

.reviews-section-results__blur {
  filter: blur(10px);
}

.reviews-section-results__init-spinner {
  position: absolute;
  top: var(--space-040);
  left: 50%;
  transform: translateX(-50%);
}

.review-card__avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: var(--colour-white);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  font-size: 24px;
  font-family: var(--fancy-font-family);
  font-weight: bold;
  margin-right: var(--space-010);
  background: var(--colour-tertiary-200);
}

.review-card__reply-box {
  position: relative;
  padding: var(--space-020);
  margin-top: var(--space-020);
  border-radius: var(--border-radius-card-large);
  background: var(--colour-background-025);
  border-left: 8px solid var(--colour-neutral-050);
}

.review-card__reply-icon-box {
  position: absolute;
  top: var(--space-020);
  left: var(--space-020);
}

.review-card__reply-top {
  display: flex;
  flex-direction: column;
  margin: 0 0 12px 34px;
}

.review-card__reply-top .f-t-body {
  line-height: 1;
}

@media (min-width: 900px) {
  .reviews-section-vendor-summary {
    padding: var(--space-040) 0;
    margin-bottom: var(--space-020);
  }

  .reviews-section-rating-distribution-container {
    margin-top: var(--space-040);
  }
  .reviews-section-filters-container {
    min-width: 350px;
    max-width: 350px;
    margin: 0;
    padding: var(--space-040);
  }

  .reviews-section-filters-explanation-box {
    max-width: 350px;
    margin: 16px 0 0 0;
    padding: var(--space-040);
  }

  .reviews-section-vendor-summary__rating-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .reviews-section-vendor-summary__rating-info svg {
    margin-right: 8px;
  }

  .reviews-section-results {
    width: 100%;
    margin: 0;
  }

  .reviews-section-vendor-summary__reviews-amount {
    font-size: 18px;
  }

  .reviews-section-vendor-summary__rating-info-headline {
    font-size: 32px;
    margin-bottom: var(--space-020);
    line-height: normal;
  }

  .reviews-section-vendor-summary__rating-info .f-t-tsection {
    margin-left: var(--space-030);
    font-size: 32px;
  }

  .reviews-section-vendor-summary__tooltip.MuiTooltip-tooltip {
    max-width: 360px;
    font-size: 14px;
  }

  .reviews-section-content {
    flex-direction: row;
    gap: var(--space-020);
    align-items: flex-start;
    padding-bottom: var(--space-080);
  }

  .reviews-section-vendor-summary__logo-wrapper {
    height: 200px;
    width: 200px;
    padding: var(--space-020);
    border-radius: var(--border-radius-card-large);
  }

  .reviews-section-vendor-summary__container {
    gap: var(--space-040);
  }

  .reviews-section-rating-distribution-checkbox span {
    font-size: 18px;
  }

  .reviews-section-vendor-summary__supplementary-box {
    padding-top: var(--space-120);
    gap: var(--space-020);
  }

  .reviews-section-vendor-summary__metadata {
    margin-top: var(--space-010);
  }

  .review-card {
    padding: var(--space-040);
  }

  .review-card__top-info {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .review-card__reviewer-name.f-t-body {
    font-size: 18px;
  }

  .review-card__reply-box {
    padding: var(--space-020) var(--space-070) var(--space-040) var(--space-080);
  }

  .review-card__reply-icon-box {
    top: 24px;
    left: 24px;
  }
  .review-card__reply-icon-box svg {
    width: 24px;
    height: 24px;
  }

  .review-card__reply-top {
    flex-direction: row;
    align-items: center;
    margin-left: 0;
  }
}
