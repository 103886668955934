@media (max-width: 767px) {
  .OurPromisesSection-heading {
    align-items: flex-end;
    color: var(--colour-white);
    display: flex;
    height: 100vw;
    padding-bottom: var(--space-010);
    position: absolute;
    top: 0;
  }

  @media (orientation: landscape) {
    .OurPromisesSection-heading {
      height: 50vh;
    }
  }
}

@media (max-width: 767px) {
  .OurPromisesSection-image-wrapper {
    margin-left: calc(var(--base-unit) * -2);
    margin-right: calc(var(--base-unit) * -2);
  }

  .OurPromisesSection-image-wrapper:after {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media (orientation: landscape) {
    .OurPromisesSection-image-wrapper {
      height: 50vh;
      overflow: hidden;
    }

    .OurPromisesSection-image {
      position: absolute;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media (min-width: 768px) {
  .OurPromisesSection-image-wrapper {
    box-sizing: content-box;
    height: 100%;
    width: 100%;
  }

  .OurPromisesSection-image {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: 768px) and (max-width: 1159px) {
  .OurPromisesSection-image-wrapper {
    padding-right: calc(var(--space-020) * -1);
    transform: translateX(calc(var(--space-020) * -1));
  }
}

@media (min-width: 1160px) {
  .OurPromisesSection-image-wrapper {
    padding-right: calc(0.5 * (100vw - 1128px));
    transform: translateX(-50vw) translateX(564px);
  }
}

.OurPromisesSection-icon {
  left: 0;
  position: absolute;
  top: 0;
}

@media (max-width: 767px) {
  .OurPromisesSection-icon {
    height: 35px;
    width: 35px;
  }
}

@media (min-width: 768px) {
  .OurPromisesSection-icon {
    height: 54px;
    width: 54px;
  }
}

@media (min-width: 768px) {
  .OurPromisesSection-content {
    /* calc(100% / 47) is our grid-gap */
    margin-left: calc(calc(100% / 47) * 6);
    margin-right: calc(calc(100% / 47) * 6);
  }
}

/* IE 11 */
@media (-ms-high-contrast: none) {
  .OurPromisesSection-image-column {
    margin: 0;
  }
}
