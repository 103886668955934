.HLP-hero__wrapper {
  width: 1160px;
  margin: calc(var(--base-unit) * 22 + var(--navbar-height)) auto
    calc(var(--base-unit) * 22) auto;
  background-color: rgba(249, 249, 248, 0.8);
  padding: calc(var(--base-unit) * 4);
}

.HLP-hero-content__heading-drop-down-widget {
  text-align: center;
  font-weight: 400;
  font-family: var(--fancy-font-family);
}

.HLP-hero-sub-heading.HLP-hero-sub-heading--drop-down-widget {
  text-align: center;
  font-size: 20px;
}

.HLP-hero-quiz-dropdown {
  width: 610px;
  height: 115px;
  padding: calc(var(--base-unit) * 2);
  background-color: var(--colour-white);
  margin: calc(var(--base-unit) * 4) auto;
  border-radius: var(--border-radius-card-large);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: var(--base-unit) calc(var(--base-unit) * 2);
  grid-template-areas:
    'question callout-text'
    'dropdown CTA';
}

.HLP-hero-quiz-dropdown__question {
  grid-area: question;
  font-family: var(--base-font-family);
  font-weight: 700;
}

.HLP-hero-quiz-dropdown__callout-text {
  grid-area: callout-text;
  text-align: right;
  color: #5e5e5e;
  font-family: var(--base-font-family);
  font-size: 14px;
}

.HLP-hero-quiz-dropdown__callout-text > svg {
  color: rgba(0, 0, 0, 0.7);
}

.HLP-hero-quiz-dropdown__dropdown {
  grid-area: dropdown;
}

.HLP-hero-quiz-dropdown__dropdown .FormSelect {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.HLP-hero-quiz-dropdown__dropdown .FormSelect-wrapper > * {
  font-family: var(--base-font-family);
}

.HLP-hero-quiz-dropdown__dropdown .FormSelect-wrapper {
  overflow: initial;
}

.HLP-hero-quiz-dropdown__CTA {
  grid-area: CTA;
}

.HLP-hero-quiz-dropdown__CTA > .CTA-block-primary {
  margin-top: 0;
  width: 100%;
  font-family: var(--base-font-family);
}

.HLP-hero.HLP-hero--drop-down-widget .HLP-hero-trust-elements__text {
  color: var(--colour-black);
}

.HLP-hero-value-ticks-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.HLP-hero-value-ticks-wrapper > * {
  font-family: var(--base-font-family);
}

@media (min-width: 768px) {
  .HLP-hero--drop-down-widget .HLP-hero-content.is-main {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .HLP-hero.HLP-hero.HLP-hero--drop-down-widget {
    background-size: initial;
  }

  .HLP-hero__wrapper {
    margin: 0;
    padding-top: calc(var(--base-unit) * 11);
    padding-left: calc(var(--base-unit) * 2);
    padding-right: calc(var(--base-unit) * 2);
  }

  .HLP-hero--drop-down-widget .HLP-hero-content {
    margin: 0;
    padding-bottom: 0;
  }

  .HLP-hero-content__heading-drop-down-widget {
    text-align: left;
    font-size: 26px;
    margin-bottom: var(--base-unit);
  }

  .HLP-hero-sub-heading.HLP-hero-sub-heading--drop-down-widget {
    text-align: left;
    font-size: 18px;
    font-family: var(--base-font-family);
  }

  .HLP-hero-quiz-dropdown {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    min-height: 205px;
    border-radius: var(--border-radius-card-large);
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: var(--base-unit) 0;
    grid-template-areas:
      'question question'
      'dropdown dropdown'
      'callout-text callout-text'
      'CTA CTA';
  }

  .HLP-hero-value-ticks-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(256, 256, 256, 0.75);
    border-radius: var(--border-radius-card-large);
    padding: var(--base-unit);
  }
}

@media (max-width: 1200px) {
  .HLP-hero__wrapper {
    width: 100%;
  }
}
