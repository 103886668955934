@media (max-width: 767px) {
  .PaidLandingPage-hero {
    background: url({{ CDN_S }}images/at_f/PaidLandingPage/Mobile-768x648.jpg)
      no-repeat 50% 50% / cover;
  }
}

@media (min-width: 768px) {
  .PaidLandingPage-hero {
    background: url({{ CDN_S }}images/at_f/PaidLandingPage/Desktop-Tablet.jpg)
      no-repeat 50% 50% / cover;
  }
}

@media (max-width: 767px) {
  .PaidLandingPage-header-USPs {
    background: var(--colour-white);
    float: left;
    height: var(--navbar-height);
    margin-left: calc(var(--space-020) * -1);
    margin-right: calc(var(--space-020) * -1);
    overflow: hidden;
    padding-left: var(--space-020);
    padding-right: var(--space-020);
    width: 100vw;
  }
}

@media (min-width: 768px) {
  .PaidLandingPage-header-USPs {
    float: right;
  }
}

@media (max-width: 767px) {
  .PaidLandingPage-header-USP {
    color: var(--colour-black);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 2px 0;
  }
}

@media (min-width: 768px) {
  .PaidLandingPage-header-USP {
    color: var(--colour-white);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}

.PaidLandingPage {
  clear: left;
}

.PaidLandingPage-vendors {
  margin-left: calc(var(--space-020) * -1);
  margin-right: calc(var(--space-020) * -1);
}

@media (max-width: 938px) {
  .PaidLandingPage-vendors {
    overflow: auto;
  }
}

.PaidLandingPage-vendor {
  align-self: center;
  box-sizing: content-box;
  filter: grayscale(1);
  margin: auto;
}

.PaidLandingPage-vendor-colour {
  align-self: center;
  box-sizing: content-box;
  margin: auto;
}

.PageLandingPage-flow-icon {
  height: auto;
  width: 100%;
}

.PageLandingPage-flow-icon use {
  fill: none;
  stroke-width: 2;
}

.PageLandingPage-flow-number {
  white-space: nowrap;
}

.PaidLandingPage-CTA-icon {
  top: 50%;
}

@media (max-width: 767px) {
  .PaidLandingPage-CTA-icon {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 768px) {
  .PaidLandingPage-CTA-icon {
    right: 15%;
    transform: translate(0, -50%);
  }
}

.PaidLandingPage-CTA-icon use {
  stroke-width: 12;
}

@media all and (-ms-high-contrast: none) {
  .PaidLandingPage-CTA-icon use {
    stroke-width: 1;
  }
}
