body.only-top-navigation .PageFooterLinks {
  display: none;
}

body.only-top-navigation .PageFooter-wrapper {
  display: none;
}

.BudgetAssistantPage {
  min-height: calc(100vh - var(--navbar-height));
}

@media (max-width: 767px) {
  .BudgetAssistantPage-skip {
    float: right;
  }

  .BudgetAssistantPage-back {
    float: left;
  }
}

/* ProgressBar.jsx */
.ProgressBar-wrapper {
  justify-content: center;
}

@media (min-width: 768px) {
  .ProgressBar-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
    width: 960px;
  }
}

.ProgressBar-group-bar {
  line-height: var(--base-unit);
}

.ProgressBar-group-element {
  height: var(--base-unit);
}

.ProgressBar-group-element + .ProgressBar-group-element {
  border-left: 1px dotted var(--colour-primary-600);
}

.ProgressBar-group-element.is-active {
  background-color: var(--colour-success-400);
}

@media (max-width: 767px) {
  .ProgressBar-group-element {
    flex: 1;
  }
}

@media (min-width: 768px) {
  .ProgressBar-group-element {
    width: 80px;
  }

  .ProgressBar-group-element:first-child {
    border-radius: var(--border-radius-card-medium) 0 0
      var(--border-radius-card-medium);
  }

  .ProgressBar-group-element:last-child {
    border-radius: 0 var(--border-radius-card-medium)
      var(--border-radius-card-medium) 0;
  }
}

@media (max-width: 767px) {
  .ProgressBar-wrapper-mobile {
    flex: 1;
  }
}

@media (min-width: 768px) {
  .ProgressBar-group-wrapper {
    max-width: 100%;
    width: 100%;
  }

  .ProgressBar-group-wrapper .is-dimmed {
    opacity: 0.4;
  }

  .ProgressBar-group-wrapper + .ProgressBar-group-wrapper {
    margin-left: var(--space-010);
  }
}

/* Step Styles */
.StepSubtitle {
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
}

.StepIllustration {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .StepIllustration {
    height: 176px;
    width: 224px;
  }
}

@media (min-width: 768px) {
  .StepIllustration {
    height: 208px;
    width: 256px;
  }
}

.StepInput {
  border-style: solid;
  border-width: 1px;
  font-size: 24px;
  font-weight: 700;
  padding: var(--space-010) !important;
  width: 160px;
}

.StepInput-small {
  width: 100px;
}

.StepInput-medium {
  width: 140px;
}

@media (max-width: 347px) {
  .StepInput-medium {
    width: 100px;
  }
}

.StepInput-inline {
  vertical-align: middle;
}

@media (min-width: 768px) {
  .StepInput-monthly-rate {
    margin-left: 190px;
  }
}

.StepInput .FormText {
  margin: 0;
}

.StepSlider {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.StepLocation-input-container {
  margin: var(--space-010) auto var(--space-020);
  max-width: 330px;
}

.StepLocation-input {
  width: 100%;
}

.StepLocation-finder {
  display: block;
}

.StepMonthlyRate-bar {
  margin: 0 auto;
  max-width: 720px;
}

@media (max-width: 767px) {
  .StepMonthlyRate-bar {
    height: 18px;
    margin-bottom: var(--space-010);
  }
}

@media (min-width: 768px) {
  .StepMonthlyRate-bar {
    margin-bottom: var(--space-060);
    margin-top: 80px;
  }
}

@media (max-width: 767px) {
  .StepExpansionStage-columns {
    text-align: center;
  }

  .StepExpansionStage-column {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .StepExpansionStage-columns {
    display: flex;
    justify-content: center;
  }

  .StepExpansionStage-column {
    flex: 1 0 30%;
    max-width: 290px;
    margin-left: var(--space-020);
    margin-right: var(--space-020);
    text-align: center;
  }
}

.StepResults-summary-wrapper {
  border-top-style: solid;
  border-top-width: 1px;
}

.StepResults-summary-detail {
  cursor: default;
}

@media (max-width: 1199px) {
  .StepResults-summary-wrapper {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .StepResults-summary-block {
    min-width: 100%;
  }

  .StepResults-summary-totals {
    margin-left: auto;
  }

  .StepResults-summary-bar {
    height: 16px;
    margin-bottom: var(--space-010);
    margin-top: var(--space-010);
    width: 100%;
  }

  .StepResults-summary-bar-right-aligned {
    justify-content: flex-end;
  }

  .StepResults-summary-bar-item {
    height: 100% !important;
  }

  .StepResults-summary-symbol {
    display: none;
  }

  .StepResults-summary-detail {
    margin-right: var(--space-010);
  }
}

@media (min-width: 400px) and (max-width: 1199px) {
  .StepResults-summary-cta {
    display: block;
    text-align: center;
    width: 360px;
  }
}

@media (min-width: 1200px) {
  .StepResults-summary-block {
    width: 23%;
  }

  .StepResults-summary-block-final {
    margin-left: 4%;
    width: 27%;
  }

  .StepResults-summary-block-final-wrapper {
    border-left-style: solid;
    border-left-width: 2px;
    padding-left: 80px;
    position: relative;
  }

  .StepResults-summary-block-final-wrapper:before {
    background: #fff;
    border: 2px solid var(--colour-primary-600);
    content: '';
    height: 24px;
    left: -14px;
    position: absolute;
    top: 32px;
    transform: rotate(135deg);
    width: 24px;
  }

  .StepResults-summary-block-final-wrapper:after {
    background: #fff;
    content: '';
    height: 38px;
    left: -20px;
    position: absolute;
    top: 25px;
    width: 18px;
  }

  .StepResults-summary-heading {
    margin-bottom: var(--space-020);
    width: 100%;
  }

  .StepResults-summary-bar {
    flex-direction: column;
    height: 192px;
    margin-right: var(--space-020);
    width: 84px;
  }

  .StepResults-summary-bar-costs {
    justify-content: flex-end;
  }

  .StepResults-summary-bar-item {
    width: 100% !important;
  }

  .StepResults-summary-details {
    flex-direction: column;
  }

  .StepResults-summary-detail {
    margin-bottom: var(--space-010);
  }

  .StepResults-summary-totals {
    margin-top: var(--space-020);
    order: 4;
  }

  .StepResults-summary-symbol {
    flex: 1;
    order: 5;
  }

  .StepResults-summary-cta {
    text-align: center;
    width: 100%;
  }
}

.StepResults-summary-warning-icon {
  float: left;
  margin-left: calc(var(--space-030) * -1);
}

/* User Account Tweaks */

.UserStepResults-panel-arrow {
  height: 32px;
  left: 50%;
  top: 45px;
  transform: translateX(-50%);
  position: absolute;
  width: 96px;
}

@media (max-width: 1199px) {
  .UserStepResults,
  .UserStepResults-footer {
    margin: -48px -16px 0;
  }

  .UserStepResults-summary-bar {
    height: 16px;
    margin-bottom: var(--space-010);
    margin-top: var(--space-010);
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .UserStepResults,
  .UserStepResults-footer {
    margin: -48px -32px 0;
  }

  .UserStepResults-summary-block-final {
    margin-left: 2%;
    width: 29%;
  }

  .UserStepResults-summary-block-final-wrapper {
    border-left-style: solid;
    border-left-width: 2px;
    padding-left: 32px;
    position: relative;
  }

  .UserStepResults-summary-block-final-wrapper:before {
    background: #fff;
    border: 2px solid var(--colour-primary-600);
    content: '';
    height: 24px;
    left: -14px;
    position: absolute;
    top: 32px;
    transform: rotate(135deg);
    width: 24px;
  }

  .UserStepResults-summary-block-final-wrapper:after {
    background: #fff;
    content: '';
    height: 38px;
    left: -20px;
    position: absolute;
    top: 25px;
    width: 18px;
  }

  .UserStepResults-summary-bar {
    flex-direction: column;
    height: 192px;
    margin-right: var(--space-020);
    width: 64px;
  }
}
