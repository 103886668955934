.SearchResultsTile-item use {
  stroke: currentColor;
}

.SearchResults__wrapper.is-light {
  background: var(--colour-background-010);
}

.SearchResultsTile-image-container {
  display: block;
  height: 270px;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
}

.SearchResultsTile-image-container--has-full-radius {
  border-radius: 8px;
}

.SearchResultsTile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.SearchResultsTile-logo {
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: 100px;
  max-height: 100px;
  background-color: var(--colour-white);
  border-radius: var(--border-radius-small);
  padding: var(--space-010);
}

.SearchResultsTile-badge {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 100px;
  max-height: 100px;
  max-width: 50px;
}

.SearchResultsTile-image-container.SearchResultsTile-image-container--has-border {
  border-top: 8px solid var(--colour-primary-600);
}

.SearchResultsTile-thumbnails-container {
  cursor: pointer;
}

.SearchResultsTile-house-thumbnail {
  max-height: 90px;
}

.SearchResultsTile-house-thumbnail-wrapper {
  max-height: 90px;
  max-width: 33%;
  overflow: hidden;
}

.SearchResultsTile-house-thumbnail-wrapper:first-child {
  border-bottom-left-radius: 8px;
}

.SearchResultsTile-house-thumbnail-wrapper:last-child {
  border-bottom-right-radius: 8px;
}

.SearchResultsVendor-vendor-info {
  cursor: pointer;
}

.SearchResultsVendor-vendor-name-slogan.SearchResultsVendor-vendor-name-slogan--is-column {
  max-width: 60%;
}

.SearchResultsTile-premium-badge {
  border-radius: var(--border-radius-small);
}

.SearchResultsTile-item .SearchResultsVendor_review-container {
  cursor: pointer;
}

.SearchResultsTile-item .SearchResultsVendor_rating {
  word-break: keep-all;
}

.SearchResultsTile-sa-info-portrait {
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.SearchResultsTile-sa-info-portrait.SearchResultsTile-sa-info-portrait--placeholder {
  background-color: var(--colour-background-050);
}

.SearchResultsTile-sa-info-title-placeholder {
  height: 21px;
  width: 200px;
  border-radius: 2px;
  background-color: var(--colour-background-050);
}

.SearchResultsTile-sales-agent-info {
  border-bottom-left-radius: var(--border-radius-card-medium);
  border-bottom-right-radius: var(--border-radius-card-medium);
}

.SearchResultsTile-sa-info-action-placeholder {
  height: 14px;
  width: 160px;
  border-radius: 2px;
  background-color: var(--colour-background-050);
}

.SearchResultsTile-sales-info-arrow.SearchResultsTile-sales-info-arrow--unfolded {
  transform: rotate(180deg);
}

@media (max-width: 359px) {
  .SearchResultsTile-item {
    width: calc(100% - 32px);
  }
}

@media (min-width: 360px) and (max-width: 1055px) {
  .SearchResultsTile-item {
    max-width: 360px;
    min-width: 320px;
    flex: 1 0 320px;
  }
}

@media (min-width: 1056px) {
  .SearchResultsTile-item {
    flex: 1 0 100%;
    max-width: calc(33.33333% - 32px);
  }

  .SearchResultsFilters-set-location-modal {
    max-width: 360px;
  }
}
