.HLP-hero {
  background-position: 50% 0;
  background-repeat: no-repeat;
}

.HLP-hero-text {
  max-width: 38rem;
}

.QuizWidget {
  display: flex;
}

.QuizWidget__option {
  display: flex;
  flex-direction: column;
}

.HLP-pre-heading {
  color: var(--text-on-secondary);
  background-color: var(--colour-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: var(--space-010) var(--space-020);
  text-align: center;
}

.HLP-hero-content--bar-tiles {
  margin: 64px 0;
}

.HLP-hero-content-heading--bar-tiles,
.HLP-hero-sub-heading--bar-tiles {
  max-width: 744px;
}

.QuizWidget--bar-tiles {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 368px;
  gap: 8px;
  margin-top: 32px;
}

.QuizWidget--bar-tiles a {
  margin: 0;
}

.QuizWidget--bar-tiles .QuizWidget__option.ActionCardTemplate {
  width: 100%;
  height: 44px;
  flex-direction: row;
  justify-content: flex-start;
  padding: 12px 16px;
  gap: 16px;
}
.QuizWidget--bar-tiles .QuizWidget__option svg.f-icon-100 {
  width: 20px;
  height: 20px;
  vertical-align: initial;
}
.QuizWidget--bar-tiles svg use {
  stroke-width: 1px;
}

.HLP-hero--bar-tiles .CTA-block-primary {
  max-width: 368px;
}

.QuizWidget--bar-tiles
  .ActionCardTemplate-text.ActionCardTemplate-text.ActionCardTemplate-text {
  background: transparent;
  flex: 1;
  justify-content: flex-start;
}

.QuizWidget--bar-tiles .ActionCardTemplate-text > span {
  font-size: 14px;
}

.QuizWidget--bar-tiles
  .QuizWidget__option.ActionCardTemplate
  > .ActionCardTemplate-holder {
  display: block;
  height: auto;
  width: auto;
  padding: 0;
}

.HLP-Hero-pre-heading--bar-tiles {
  display: inline-block;
  color: var(--colour-black);
  background: var(--colour-secondary);
  padding: 4px 14px;
  border-radius: var(--border-radius-card-large);
  font-weight: 700;
}

@media (max-width: 767px) {
  .HLP-hero-content {
    margin-top: 256px;
  }

  .QuizWidget__option {
    width: 100%;
    height: 50px;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 10px;
    gap: 8px;
  }

  .QuizWidget__option > .ActionCardTemplate-holder {
    width: initial;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .QuizWidget__option svg {
    display: block;
  }

  .QuizWidget__option svg.f-icon-100 {
    height: 28px;
    width: 28px;
  }

  .QuizWidget__option svg.f-icon-100 use {
    stroke-width: 1.5;
  }

  .QuizWidget__option > .ActionCardTemplate-text {
    width: initial;
    z-index: 1;
    justify-content: flex-start;
  }
}

@media (min-width: 768px) {
  .HLP-pre-heading {
    padding: var(--space-020);
  }

  .HLP-hero-content--bar-tiles {
    margin: 128px 0;
  }

  .QuizWidget--bar-tiles {
    margin-top: 48px;
  }

  .HLP-hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .HLP-hero-content.is-main {
    padding-bottom: 8vh;
    padding-top: calc(8vh + var(--navbar-height));
  }
}

@media (max-width: 767px) {
  .HLP-hero-spa1,
  .HLP-hero-spa2,
  .HLP-hero-spa3 {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .QuizWidget__option.ActionCardTemplate {
    width: 177px;
    height: 179px;
  }

  .QuizWidget__option.ActionCardTemplate > .ActionCardTemplate-text {
    background-color: var(--colour-background-015);
  }

  .QuizWidget__option.ActionCardTemplate > .ActionCardTemplate-holder {
    align-items: center;
    height: 123px;
  }

  .QuizWidget__option.ActionCardTemplate > .ActionCardTemplate-holder img {
    height: 123px;
    max-height: 100% !important;
  }

  .QuizWidget__option.ActionCardTemplate:after {
    box-shadow: var(--action-card-box-shadow);
  }

  .HLP-hero-spa1 {
    max-width: 270px;
  }

  .HLP-hero-spa2 {
    margin-left: auto;
    margin-right: auto;
  }

  .HLP-hero-spa3 {
    max-width: 300px;
    float: right;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .HLP-hero-spa2 {
    max-width: 260px;
  }
}

@media (min-width: 1200px) {
  .HLP-hero-spa2 {
    max-width: 320px;
  }
}

@media (max-width: 374px) {
  .HLP-hero-heading {
    font-size: 1.75rem;
  }
}

@media (max-width: 767px) {
  .HLP-hero-heading {
    align-items: flex-start;
    color: var(--colour-white);
    display: flex;
    flex-direction: column;
    height: 100vw;
    justify-content: flex-end;
    padding-bottom: var(--space-010);
    position: absolute;
    top: 0;
  }

  @media (orientation: landscape) {
    .HLP-hero-heading {
      height: 50vh;
    }
  }
}

@media (min-width: 768px) {
  .HLP-hero-heading {
    font-size: 2.5rem;
    max-width: 38rem;
  }
}

@media (min-width: 768px) {
  .HLP-hero-rating-container {
    border-left-style: solid;
    border-left-width: 2px;
    padding-left: var(--space-020);
  }
}

.HLP-hero-rating-stars {
  bottom: 0.062rem;
}

.HLP-hero-rating-star {
  height: 0.875rem;
  width: 0.875rem;
}

@media (max-width: 767px) {
  .HLP-hero-image-wrapper {
    margin-left: calc(var(--base-unit) * -2);
    margin-right: calc(var(--base-unit) * -2);
  }

  .HLP-hero-image-wrapper:after {
    background: linear-gradient(
      180deg,
      rgba(37, 41, 76, 0.7) 0%,
      rgba(37, 41, 76, 0.2) 20%,
      rgba(0, 0, 0, 0.6) 60%,
      rgba(0, 0, 0, 0.9) 100%
    );
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media (orientation: landscape) {
    .HLP-hero-image-wrapper {
      height: 50vh;
      overflow: hidden;
    }

    .HLP-hero-image {
      position: absolute;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media (min-width: 768px) {
  .HLP-hero-image-wrapper {
    box-sizing: content-box;
    height: 100%;
    width: 100%;
  }

  .HLP-hero-image {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .HLP-hero-image-wrapper:after {
    background: linear-gradient(
        180deg,
        rgba(59, 71, 103, 0.6) 0%,
        rgba(59, 71, 103, 0) 100%
      )
      no-repeat top left / 100% 100%;
    content: '';
    height: 200px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

@media (min-width: 768px) and (max-width: 1159px) {
  .HLP-hero-image-wrapper {
    padding-right: var(--space-020);
  }
}

@media (min-width: 1160px) {
  .HLP-hero-image-wrapper {
    padding-right: calc(0.5 * (100vw - 1128px));
  }
}

@media (min-width: 768px) {
  .HLP-hero-text {
    font-size: 1.25rem;
  }
}

@media (max-width: 374px) {
  .QuizWidget-wrap > .QuizWidget {
    margin-left: -4px;
    margin-right: -4px;
  }
}

@media (min-width: 665px) {
  .QuizWidget-wrap,
  .QuizWidget-wrap > .QuizWidget {
    max-width: 100% !important;
  }
}

/* IE 11 */
@media (-ms-high-contrast: none) {
  .HLP-hero-image-column {
    margin: 0;
  }
}

.HLP-hero-image-column + .HLP-hero-content {
  margin: 0;
}

/* ATF fields | NR-1785 --- START */
.HolisticLandingPage-full-screen-wrapper {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.HolisticLandingPage-full-screen-overlays {
  height: 100%;
  position: absolute;
  width: 100%;
}

.HolisticLandingPage-full-screen-overlays:before,
.HolisticLandingPage-full-screen-overlays:after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  height: 162px;
}

.HolisticLandingPage-full-screen-overlays:before {
  top: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
}

.HolisticLandingPage-full-screen-overlays:after {
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
}

.HolisticLandingPage-atf-primary-widget {
  max-width: 750px;
}
.HolisticLandingPage-atf-primary-widget > ul {
  max-width: 640px;
}
.HolisticLandingPage-atf-primary-widget.finance-widget {
  max-width: 100%;
}

@media (min-width: 480px) {
  .HolisticLandingPage-atf-primary-widget-cta {
    flex: 1 1 50%;
  }

  .QuestionRadio-wrap.has-four-elements {
    max-width: none;
  }
}

.HolisticLandingPage-atf-text {
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.HolisticLandingPage-atf-text-arrow {
  margin-left: auto;
  margin-right: auto;
}

/* ATF fields | NR-1785 --- END */

.HolisticLandingPage-vendors-logos {
  margin-top: auto;
  z-index: 1;
}

@media (max-width: 480px) {
  .HolisticLandingPage-vendors-logos {
    position: static;
    transform: none;
  }
}

.HolisticLandingPage-vendors {
  margin-left: -16px;
  margin-right: -16px;
  overflow: auto;
}

.HolisticLandingPage-vendor {
  align-self: center;
  box-sizing: content-box;
  margin: auto;
}

.HLP-block {
  position: relative;
  z-index: 1;
}

.HLP-block:nth-child(2n + 1) {
  background-color: var(--colour-background-025);
  background-image: linear-gradient(
      0deg,
      rgba(59, 71, 104, 0) 0%,
      rgba(59, 71, 104, 0.02) 100%
    ),
    linear-gradient(
      180deg,
      rgba(59, 71, 104, 0) 0%,
      rgba(59, 71, 104, 0.02) 100%
    );
  background-size: 100% 10rem;
  background-position: top left, bottom left;
  background-repeat: no-repeat;
}

.HLP-block-tertiary {
  background-color: var(--colour-primary-600);
}

.HLP-block-tertiary:nth-child(2n + 1) {
  background-color: var(--colour-primary-600);
}

.HLP-block:empty {
  display: none;
}

.nr3637-bulletpoint-text {
  line-height: 1.33;
}

/* IM-ADS */
.IM-ADS_wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.IM-ADS_container {
  width: 0;
  position: relative;
}

.IM-ADS_skyscraper,
.IM-ADS_superbanner,
.IM-ADS_hausbau {
  display: block;
  visibility: hidden;
}

@media (min-width: 1400px) {
  .IM-ADS_skyscraper,
  .IM-ADS_superbanner,
  .IM-ADS_hausbau {
    visibility: revert;
    position: absolute;
  }

  .IM-ADS_skyscraper {
    top: calc(90px + 56px);
    right: 0;
  }

  .IM-ADS_superbanner {
    top: 56px;
    right: 0;
  }

  .IM-ADS_hausbau {
    /* right: 0;
    top: 500px; */
  }
}
