.HLP-header-USP:last-of-type {
  margin-right: 0;
}

.HLP-header-USPs .HLP-hero-rating-star:not(.HLP-hero-rating-star--empty) * {
  fill: var(--colour-success-100);
}

@media (max-width: 767px) {
  .HLP-header-USP {
    display: none;
  }
  .HLP-header-USP:first-of-type {
    display: inline-block;
  }
}
