.FormCustomSelect-wrapper {
  color: var(--colour-neutral-200);
  font-size: 12px;
  line-height: 50px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  line-height: 1;
  display: flex;
  align-items: center;
}

.FormCustomSelect-icon {
  display: inline-block;
}

.FormCustomSelect-filter {
  font-size: 14px;
  height: calc(var(--base-font-size) + 2px);
  line-height: calc(var(--base-font-size) + 2px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 4px;
}

.FormCustomSelect-filter:hover {
  text-decoration: underline;
}

.FormCustomSelect {
  font-family: inherit;
  appearance: none;
  background: rgba(0, 0, 0, 0.001);
  border: rgba(0, 0, 0, 0.001);
  bottom: 0;
  color: rgba(0, 0, 0, 0.001);
  height: 100%;
  left: 0;
  outline: none;
  padding-left: 16px;
  padding-right: 16px;
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
}

.FormCustomSelect-caret {
  line-height: 14px;
}

.FormCustomSelect option {
  color: var(--colour-black);
}

.FormCustomSelect::-ms-expand {
  display: none;
}

.FormCustomSelect-disabled .FormCustomSelect-filter {
  color: var(--colour-black);
}

.FormCustomSelect-disabled svg {
  display: none;
}

.FormCustomSelect-disabled {
  cursor: pointer;
  pointer-events: none;
}
