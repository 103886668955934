.PageFooter-mid.PageFooter-mid {
  background-color: var(--page-footer-background-mid);
}

.PageFooter-mid.PageFooter-mid .PageFooter-link {
  color: var(--page-footer-text-link);
}

.PageFooter-mid.PageFooter-mid .PageFooter-link-alt {
  color: var(--page-footer-text-link-alt);
}

.PageFooter-bottom.PageFooter-bottom {
  background-color: var(--page-footer-background-bottom);
}

.PageFooter-bottom.PageFooter-bottom .PageFooter-link {
  color: var(--page-footer-text-link);
}

.PageFooter-bottom.PageFooter-bottom .PageFooter-link-alt {
  color: var(--page-footer-text-link-alt);
}

.PageFooter-heading,
.PageFooter-text {
  color: var(--page-footer-text);
}

@media (max-width: 767px) {
  .PageFooter-nav {
    display: flex;
  }

  .PageFooter-nav-list {
    flex: 1 1 50%;
  }
}

.PageFooter-form {
  max-width: 408px;
}

.PageFooter-form-input {
  flex: 1 1 auto;
  margin-right: -8px;
  min-width: 160px;
  width: 0;
}

.PageFooter-rating-box {
  display: flex;
  align-items: flex-start;
  color: var(--colour-white);
}

.PageFooter-rating-box svg use {
  fill: var(--colour-white);
}

.PageFooter-rating-box .f-overflow use {
  fill: var(--colour-primary-400);
}

.PageFooter-form-input .FormText-wrapper {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.PageFooter-form-submit {
  min-width: auto;
  padding-left: 32px;
  transform: none !important;
}

@media (min-width: 955px) {
  .PageFooter-copy {
    text-align: right;
  }
}

@media (max-width: 955px) {
  .PageFooter-bottom {
    display: flex;
    flex-direction: column;
  }
}
