.FormError-wrapper {
  max-width: 480px;
  border-radius: var(--border-radius-inputs);
}

.is-full-width + .FormError-wrapper {
  max-width: none;
}

.FormError-wrapper:empty {
  display: none;
}

.FormError-wrapper--full-width {
  width: 100%;
}
