.HLP-hero-im-dropdown__wrapper {
  width: 1160px;
  margin: calc(var(--base-unit) * 22 + var(--navbar-height)) auto
    calc(var(--base-unit) * 22) auto;
  padding: calc(var(--base-unit) * 4);
}

.HLP-hero.HLP-hero.HLP-hero--im-drop-down-widget {
  background-repeat: no-repeat;
  background-size: cover, cover;
  background-position: center, left;
}

.HLP-hero-im-dropdown__heading-dropdown {
  text-align: left;
  font-family: var(--fancy-font-family);
  font-size: 32px;
  max-width: 600px;
  color: var(--text-main-on-dark);
}

.HLP-hero-sub-heading.HLP-hero-sub-heading--drop-down-widget {
  text-align: left;
  font-size: 18px;
  color: var(--text-main-on-dark);
  max-width: 600px;
}

.HLP-hero-im-dropdown {
  background-color: var(--colour-white);
  margin: calc(var(--base-unit) * 4) auto;
  border-radius: var(--border-radius-card-large);
  display: grid;
  grid-template-columns: repeat(2, minmax(235px, 335px)) 1fr;
  gap: 8px;
  min-height: 72px;
}

.HLP-hero-im-dropdown__question {
  grid-area: question;
  font-family: var(--base-font-family);
  font-weight: 700;
}

.HLP-hero-im-dropdown__callout-container {
  display: flex;
  padding: 4px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--border-radius-card-large);
  background: var(--colour-secondary);
}

.HLP-hero-im-dropdown__callout-text {
  text-align: left;
  color: var(--text-on-secondary);
  font-family: var(--base-font-family);
  font-size: 14px;
  font-weight: 700;
}

.HLP-hero-im-dropdown__location-input.LocationFinder .FormText-wrapper {
  border: none;
  padding-bottom: 13px;
  padding-top: 19px;
  font-size: 14px;
}

.HLP-hero-im-dropdown__location-input.LocationFinder
  .FormText-wrapper
  .FormText {
  padding-top: 6px;
}

.HLP-hero-im-dropdown__form_element:not(:last-child) {
  border-right: 1px solid var(--colour-neutral-050);
  padding-top: 12px;
  padding-bottom: 12px;
}

.HLP-hero-im-dropdown__dropdown .FormSelect {
  border: none;
}

.HLP-hero-im-dropdown .FormTextLabel.has-value {
  font-size: 18px;
  top: 1px;
  width: 100%;
}

.HLP-hero-im-dropdown .FormText-wrapper:focus-within .FormTextLabel {
  top: 1px;
  font-size: 18px;
}

.HLP-hero-im-dropdown__dropdown .FormSelect-wrapper > * {
  font-family: var(--base-font-family);
}

.HLP-hero-im-dropdown__dropdown .FormSelect-wrapper .FormSelect-display {
  padding-top: 9px;
  font-size: 14px;
}

.HLP-hero-im-dropdown__dropdown .FormSelect-wrapper {
  overflow: initial;
  box-shadow: none;
  padding-left: var(--space-030);
}

.HLP-hero-im-dropdown__CTA {
  justify-self: start;
  align-self: center;
  padding-right: 8px;
}

.HLP-hero-im-dropdown__CTA > .CTA-block-primary {
  margin-top: 0;
  width: 100%;
  font-family: var(--base-font-family);
  min-height: 56px;
  min-width: 145px;
}

.HLP-hero.HLP-hero--im-drop-down-widget .HLP-hero-trust-elements__text {
  color: var(--colour-black);
}

.HLP-hero-value-ticks-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.HLP-hero-value-ticks-wrapper > * {
  font-family: var(--base-font-family);
}

@media (min-width: 768px) {
  .HLP-hero--im-drop-down-widget .HLP-hero-content.is-main {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .js-quiz-widget:has(.HLP-hero-im-dropdown) {
    width: 100%;
  }

  .HLP-hero.HLP-hero.HLP-hero--im-drop-down-widget {
    background-repeat: no-repeat;
    background-size: cover, cover;
    background-position: center, left;
  }

  .HLP-hero-im-dropdown .LocationFinder .FormTextLabel.has-value {
    top: -5px;
  }

  .HLP-hero-im-dropdown__wrapper {
    margin: 0;
    padding-top: calc(var(--base-unit) * 11);
    padding-left: calc(var(--base-unit) * 2);
    padding-right: calc(var(--base-unit) * 2);
  }

  .HLP-hero-im-dropdown__form_element:not(:last-child) {
    border: none;
    border-bottom: 1px solid var(--colour-neutral-050);
    margin: 0;
    padding: 0;
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .HLP-hero-im-dropdown__location-input.LocationFinder .FormText-wrapper {
    padding-left: 8px;
    padding-right: 8px;
  }

  .HLP-hero-im-dropdown__location-input.LocationFinder
    .FormText-wrapper
    .FormText {
    padding-top: 0;
  }

  .HLP-hero-im-dropdown__dropdown .FormSelect-wrapper {
    padding-left: 8px;
    padding-right: 8px;
  }

  .HLP-hero-im-dropdown__dropdown,
  .HLP-hero-im-dropdown__location {
    min-width: 275px;
  }

  .HLP-hero--im-drop-down-widget .HLP-hero-content {
    margin: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
  }

  .HLP-hero-im-dropdown__heading-dropdown {
    text-align: left;
    font-size: 28px;
    margin-bottom: var(--base-unit);
  }

  .HLP-hero-sub-heading.HLP-hero-sub-heading--drop-down-widget {
    text-align: left;
    font-size: 18px;
    font-family: var(--base-font-family);
  }

  .HLP-hero-im-dropdown {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 8px;
    border-radius: var(--border-radius-card-large);
    grid-template-columns: auto;
    grid-template-rows: repeat(2, minmax(49px, 1fr)) auto;
    gap: 12px;
  }

  .HLP-hero-im-dropdown__CTA {
    min-width: 145px;
    justify-self: revert;
    padding: 0;
  }

  .HLP-hero-im-dropdown .HLP-hero-im-dropdown__CTA > .CTA-block-primary {
    min-height: 48px;
    border-radius: 2px;
  }

  .HLP-hero-value-ticks-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(256, 256, 256, 0.75);
    border-radius: var(--border-radius-card-large);
    padding: var(--base-unit);
  }
}

@media (max-width: 1200px) {
  .HLP-hero-im-dropdown__wrapper {
    width: 100%;
  }
}
