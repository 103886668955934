.Table-block {
  overflow: hidden;
  position: relative;
}

.Table-block-inner {
  overflow-x: auto;
}

.Table-block table {
  border-spacing: 0;
  margin: 0;
  min-width: 100%;
}

.Table-block thead th {
  background: var(--colour-neutral-400);
  color: var(--colour-white);
  font-family: var(--fancy-font-family);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.33;
  min-width: 224px;
  padding: var(--space-030) var(--space-020);
  text-align: left;
}

.Table-block tbody th {
  background: var(--colour-neutral-400);
  border-top: 1px solid var(--colour-neutral-400);
  color: var(--colour-white);
  font-family: var(--fancy-font-family);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.33;
  min-width: 224px;
  padding: var(--space-020) var(--space-020);
  text-align: left;
}

.Table-block td {
  background: var(--colour-neutral-025);
  border-top: 1px solid var(--colour-neutral-400);
  font-size: 1rem;
  line-height: 1.66;
  min-width: 224px;
  padding: var(--space-020) var(--space-020);
  text-align: left;
}

thead > tr > th,
tbody > tr > th {
  background-color: var(--colour-neutral-050);
}

tbody > tr > td {
  background-color: var(--colour-neutral-025);
}
