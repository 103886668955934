.Accordion-block-item {
  box-shadow: 0 2px 50px 0 rgba(34, 85, 136, 0.05);
}

.Accordion-block-item:has(.Accordion-block-item-checkbox:focus-visible) {
  outline: 2px solid var(--colour-primary-600);
}

.Accordion-block-item > label {
  cursor: pointer;
}

.Accordion-block-item-caret {
  float: right;
  transition: transform 0.2s ease;
}

.Accordion-block-item-content {
  display: none;
}

.Accordion-block-item-checkbox:checked ~ .Accordion-block-item-content {
  display: block;
}

.Accordion-block-item-checkbox:checked
  ~ .Accordion-block-item-heading
  .Accordion-block-item-caret {
  transform: rotate(180deg);
}

.Accordion-block-item-content p {
  margin: 0;
}

.Accordion-block-item-content p + p {
  margin-top: var(--space-010);
}
