.QuestionSuccessCard-wrapper {
  border-radius: var(--border-radius-card-medium);
  background-color: white;
  padding: var(--space-020);
  max-width: 360px;
}

.RecommendedFinance_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(360px, 100%), 1fr));
  gap: 16px;
}

.RecommendedFinance_card-container {
  border-radius: var(--border-radius-card-large);
  box-shadow: var(--action-card-box-shadow);
  overflow: hidden;
}

.RecommendedFinance_card-container:hover {
  box-shadow: var(--action-card-box-shadow-hover);
}

.RecommendedFinance_card-header {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  padding: 16px;
  background: white;
}

.RecommendedFinance_partner-logo-wrapper {
  width: 84px;
  height: 84px;
  padding: var(--space-005);
  background-color: white;
  flex-shrink: 0;
  pointer-events: none;
  border-radius: var(--border-radius-card-large);
}

.RecommendedFinance_avatar-description {
  display: -webkit-box;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* Overflow required to work for clamping */
  overflow: hidden;
}

.RecommendedFinance_avatar-wrapper {
  width: 64px;
  height: 64px;
  margin-right: var(--space-020);
  background: var(--colour-white);
  border-radius: 50%;
  overflow: hidden;
  align-self: center;
  flex-shrink: 0;
}

.RecommendedFinance_avatar-wrapper svg {
  height: 64px;
  width: 64px;
}

.RecommendedFinance_elip-1 {
  display: -webkit-box;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* Overflow required to work for clamping */
  overflow: hidden;
}

.RecommendedFinance_description-container {
  padding: 0 var(--space-020) var(--space-020);
  cursor: pointer;
  background: white;
}

.RecommendedFinance_description-wrapper {
  display: grid;
  transition: opacity 0.3s ease-in-out, grid-template-rows 0.3s ease-in-out;
}

.RecommendedFinance_description-wrapper.expanded {
  grid-template-rows: 1fr;
  opacity: 1;
}

.RecommendedFinance_description-wrapper.collapsed {
  grid-template-rows: 0fr;
  opacity: 0;
  padding-top: -var(--space-010);
}

.RecommendedFinance_description {
  overflow: hidden;
}

.FinanceLeadsFound_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.FinanceLeadsFound_cta-wrapper {
  background: white;
  width: 100%;
  position: fixed;
  bottom: 0px;
  margin: auto -16px 0;
  text-align: center;
  box-shadow: var(--action-card-box-shadow-hover);
  z-index: 10;
}

.FinanceLeadsFound_cta-container {
  padding: 16px;
}

@media (min-width: 767px) {
  .FinanceLeadsFound_container {
    flex-direction: row;
  }

  .FinanceLeadsFound_cta-container {
    display: none;
  }
}
