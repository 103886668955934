.ContentBanner-wrapper {
  flex-direction: row-reverse;
}

@media (max-width: 767px) {
  .ContentBanner-wrapper {
    flex-wrap: wrap;
  }
}

.ContentBanner-content {
  flex: 1 1 100%;
}

@media (min-width: 768px) {
  .ContentBanner-content {
    max-width: 32rem;
  }
}

.ContentBanner-image-wrapper {
  flex: 0 1 auto;
}

@media (min-width: 768px) {
  .ContentBanner-image-wrapper {
    margin-left: auto;
  }

  .ContentBanner-image-wrapper:not(.is-small) {
    max-width: 30vw;
    width: 20rem;
  }

  .ContentBanner-image-wrapper.is-small {
    max-width: 12rem;
  }
}

.ContentBanner-image {
  max-width: 25rem;
  width: 100%;
  border-radius: var(--border-radius-card-large);
}
