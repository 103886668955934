.PhoneInput-wrapper {
  position: relative;
}

.PhoneInput-country-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.PhoneInput-inputs {
  background: var(--colour-white);
  display: flex;
  align-items: center;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: var(--border-radius-inputs);
  padding-left: var(--space-020);
  margin-bottom: var(--space-020);
  height: var(--space-060);
}

.PhoneInput-wrapper .FormText {
  --border-offset: 4px;
  padding-left: var(--space-020);
  height: calc(var(--space-060) - var(--border-offset));
  margin-bottom: 0;
  padding-top: var(--space-020);
}

.PhoneInput-country-wrapper .FormPhone-country-select {
  width: var(--space-100);
}

.PhoneInput-country-wrapper .FormPhone-country-select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px var(--colour-white) inset;
}

.PhoneInput-wrapper .FormTextLabel {
  left: var(--space-110);
}

.PhoneInput-phone:focus-within .FormTextLabel {
  top: 4px;
  transform: scale(0.7);
}
