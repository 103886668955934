.FeatureSection-CTA {
  font-size: 1.125rem;
}

.FeatureSection-header,
.FeatureSection-description {
  max-width: 560px;
}

.FeatureSection-img.is-small {
  display: block;
  height: auto;
  margin: calc(var(--space-060) * -1) calc(var(--space-020) * -1)
    var(--space-040);
  max-width: calc(100% + var(--space-040));
  width: calc(100% + var(--space-040));
}

@media (min-width: 768px) {
  .FeatureSection-img.is-large {
    margin-bottom: -8%;
    margin-top: -8%;
  }

  .FeatureSection-img.is-large.on-left {
    transform: translateX(-4%);
  }

  .FeatureSection-img.is-large.on-right {
    transform: translateX(4%);
  }
}
