.value-ticks__badges {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.value-ticks__badges img {
  height: 80px;
  object-fit: scale-down;
}

@media (max-width: 767px) {
  .value-ticks__badges-centered {
    justify-content: center;
    column-gap: var(--space-100);
  }
}

@media (min-width: 768px) {
  .value-ticks__badges {
    margin-left: var(--space-100);
    justify-content: flex-end;
    width: auto;
  }

  .value-ticks__badges.value-ticks__badges-standalone {
    width: 100%;
  }

  .value-ticks__badges > img:first-of-type {
    margin-right: var(--space-050);
  }
}
