.FormDate-wrapper {
  border-radius: var(--border-radius-inputs);
  cursor: text;
  font-size: var(--base-font-size);
  height: 48px;
  max-width: 480px;
}

.FormDate-wrapper ::placeholder {
  color: var(--colour-neutral-400);
}

.FormDate-wrapper ::-ms-input-placeholder {
  color: var(--colour-neutral-400);
}

.FormDate-wrapper :-ms-input-placeholder {
  color: var(--colour-neutral-400);
}

.FormDate-wrapper:focus-within {
  border-color: var(--colour-tertiary-400) !important;
}

.FormDate-wrapper.is-full-width {
  max-width: 100%;
  width: 100%;
}

@media (max-width: 767px) {
  .full-width\:s {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .FormDate-wrapper.is-block\:s {
    max-width: 100%;
    width: 100%;
  }
}

.FormDate-wrapper.has-border {
  border-style: solid;
  border-width: 1px;
}

.FormDate-wrapper.is-disabled {
  cursor: pointer;
  pointer-events: none;
}

.FormDate {
  background: rgba(255, 255, 255, 0.0001);
  border: 0;
  color: inherit;
  cursor: text;
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: -2px 0 -1px; /* Fixes line-height weirdness */
  outline: 0;
  padding: 0;
  text-align: inherit;
  width: 100%;
}

/* Remove autofill blue background on Chrome */
.FormDate:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px white inset;
}

/* Datepicker CSS */
.datepicker {
  display: none;
}
.datepicker.active {
  display: block;
}
.datepicker-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  padding-top: 4px;
}
.datepicker-dropdown.datepicker-orient-top {
  padding-top: 0;
  padding-bottom: 4px;
}
.datepicker-picker {
  display: inline-block;
  border-radius: var(--border-radius-inputs);
  background-color: #fff;
}
.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.datepicker-picker span {
  display: block;
  flex: 1;
  border: 0;
  border-radius: var(--border-radius-inputs);
  cursor: default;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.datepicker-main {
  padding: 2px;
}
.datepicker-footer {
  box-shadow: inset 0 1px 1px rgba(10, 10, 10, 0.1);
  background-color: #f5f5f5;
}
.datepicker-controls,
.datepicker-grid,
.datepicker-view,
.datepicker-view .days-of-week {
  display: flex;
}
.datepicker-grid {
  flex-wrap: wrap;
}
.datepicker-view .days .datepicker-cell,
.datepicker-view .dow {
  flex-basis: 14.28571%;
}
.datepicker-view.datepicker-grid .datepicker-cell {
  flex-basis: 25%;
}
.datepicker-cell,
.datepicker-view .week {
  height: 2.25rem;
  line-height: 2.25rem;
}
.datepicker-title {
  box-shadow: inset 0 -1px 1px rgba(10, 10, 10, 0.1);
  background-color: #f5f5f5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  font-weight: 700;
}
.datepicker-header .datepicker-controls {
  padding: 2px 2px 0;
}
.datepicker-controls .button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 1px solid #dbdbdb;
  border-radius: var(--border-radius-cta);
  box-shadow: none;
  background-color: #fff;
  cursor: pointer;
  padding: calc(0.375em - 1px) 0.75em;
  height: 2.25em;
  vertical-align: top;
  text-align: center;
  line-height: 1.5;
  white-space: nowrap;
  color: #363636;
  font-size: 1rem;
}
.datepicker-controls .button:active,
.datepicker-controls .button:focus {
  outline: none;
}
.datepicker-controls .button:hover {
  border-color: #b5b5b5;
  color: #363636;
}
.datepicker-controls .button:focus {
  border-color: #3273dc;
  color: #363636;
}
.datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}
.datepicker-controls .button:active {
  border-color: #4a4a4a;
  color: #363636;
}
.datepicker-controls .button[disabled] {
  cursor: not-allowed;
}
.datepicker-header .datepicker-controls .button {
  border-color: transparent;
  font-weight: 700;
}
.datepicker-header .datepicker-controls .button:hover {
  background-color: #f9f9f9;
}
.datepicker-header .datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 0.125em hsla(0, 0%, 100%, 0.25);
}
.datepicker-header .datepicker-controls .button:active {
  background-color: #f2f2f2;
}
.datepicker-header .datepicker-controls .button[disabled] {
  box-shadow: none;
}
.datepicker-footer .datepicker-controls .button {
  margin: calc(0.375rem - 1px) 0.375rem;
  border-radius: var(--border-radius-cta);
  width: 100%;
  font-size: 0.75rem;
}
.datepicker-controls .view-switch {
  flex: auto;
}
.datepicker-controls .next-btn,
.datepicker-controls .prev-btn {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  width: 2.25rem;
}
.datepicker-controls .next-btn.disabled,
.datepicker-controls .prev-btn.disabled {
  visibility: hidden;
}
.datepicker-view .dow {
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 700;
}
.datepicker-view .week {
  width: 2.25rem;
  color: #b5b5b5;
  font-size: 0.75rem;
}
@media (max-width: 22.5rem) {
  .datepicker-view .week {
    width: 1.96875rem;
  }
}
.datepicker-grid {
  width: 15.75rem;
}
@media (max-width: 22.5rem) {
  .calendar-weeks + .days .datepicker-grid {
    width: 13.78125rem;
  }
}
.datepicker-cell:not(.disabled):hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
.datepicker-cell.focused:not(.selected) {
  background-color: #e8e8e8;
}
.datepicker-cell.selected,
.datepicker-cell.selected:hover {
  background-color: #3273dc;
  color: #fff;
  font-weight: 600;
}
.datepicker-cell.disabled {
  color: #dbdbdb;
}
.datepicker-cell.next:not(.disabled),
.datepicker-cell.prev:not(.disabled) {
  color: #7a7a7a;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  border-radius: 0;
  background-color: #f5f5f5;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #eee;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e8e8e8;
}
.datepicker-cell.today:not(.selected) {
  background-color: #00d1b2;
}
.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}
.datepicker-cell.today.focused:not(.selected) {
  background-color: #00c4a7;
}
.datepicker-cell.range-end:not(.selected),
.datepicker-cell.range-start:not(.selected) {
  background-color: #b5b5b5;
  color: #fff;
}
.datepicker-cell.range-end.focused:not(.selected),
.datepicker-cell.range-start.focused:not(.selected) {
  background-color: #afafaf;
}
.datepicker-cell.range-start {
  border-radius: var(--border-radius-inputs) 0 0 var(--border-radius-inputs);
}
.datepicker-cell.range-end {
  border-radius: 0 var(--border-radius-inputs) var(--border-radius-inputs) 0;
}
.datepicker-cell.range {
  border-radius: 0;
  background-color: #dbdbdb;
}
.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #d5d5d5;
}
.datepicker-cell.range.disabled {
  color: #c2c2c2;
}
.datepicker-cell.range.focused {
  background-color: #cfcfcf;
}
.datepicker-view.datepicker-grid .datepicker-cell {
  height: 4.5rem;
  line-height: 4.5rem;
}
.datepicker-input.in-edit {
  border-color: #2366d1;
}
.datepicker-input.in-edit:active,
.datepicker-input.in-edit:focus {
  box-shadow: 0 0 0.25em 0.25em rgba(35, 102, 209, 0.2);
}
