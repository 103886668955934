.Statement-block {
  padding-top: 24px; /* 72px of icon element minus 48px of positioning */
  margin-top: 24px;
}

.Statement-block > .Statement-block-icon-wrapper svg {
  height: 44px;
  width: 44px;
  fill: var(--colour-primary-400);
  stroke: var(--colour-primary-400);
}

.Statement-block-icon-wrapper {
  background-color: var(--colour-white);
  border: 0 solid var(--colour-white);
  border-radius: 50%;
  height: 72px;
  left: 50%;
  padding: 14px;
  position: absolute;
  top: -48px;
  transform: translateX(-50%);
  width: 72px;
}

.HLP-block .Statement-block-icon-wrapper {
  border-color: var(--colour-background-010);
  background-color: var(--colour-background-010);
}

.HLP-block:nth-child(2n + 1) .Statement-block-icon-wrapper {
  background-color: var(--colour-background-025);
  border-color: var(--colour-background-025);
}

@media (min-width: 768px) {
  .Statement-block {
    display: flex;
  }
}

.Statement-block a {
  color: var(--colour-tertiary-400);
  text-decoration: none;
}

.Statement-block a:hover {
  text-decoration: underline;
}
