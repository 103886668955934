.RichContent-block {
  overflow: hidden; /* Because we're floating images, and some times we might not have enough text content */
}

.RichContent-block .f-t-paragraph + .f-t-paragraph {
  margin-top: var(--space-020);
}

.RichContent-block h2:not(:first-child),
.RichContent-block h3:not(:first-child) {
  margin-top: var(--space-030);
}

.RichContent-block h2:not(:last-child),
.RichContent-block h3:not(:last-child) {
  margin-bottom: var(--space-030);
}

.RichContent-block h2:last-child,
.RichContent-block h3:last-child {
  margin-bottom: 0;
}

.RichContent-block h2:first-child,
.RichContent-block h3:first-child {
  margin-top: 0;
}

.RichContent-block p {
  word-break: break-word;
}

@media (max-width: 767px) {
  .RichContent-block .richtext-image.left {
    margin-bottom: var(--space-010);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .RichContent-block .richtext-image.left {
    float: left;
    margin-bottom: var(--space-020);
    margin-right: var(--space-020);
    width: calc(50% - var(--space-020));
  }
}

@media (min-width: 1200px) {
  .RichContent-block .richtext-image.left {
    float: left;
    margin-bottom: var(--space-040);
    margin-right: var(--space-040);
    width: calc(50% - var(--space-040));
  }
}

@media (max-width: 767px) {
  .RichContent-block .richtext-image.right {
    margin-bottom: var(--space-010);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .RichContent-block .richtext-image.right {
    float: right;
    margin-bottom: var(--space-020);
    margin-left: var(--space-020);
    width: calc(50% - var(--space-020));
  }
}

@media (min-width: 1200px) {
  .RichContent-block .richtext-image.right {
    float: right;
    margin-bottom: var(--space-040);
    margin-left: var(--space-040);
    width: calc(50% - var(--space-040));
  }
}

/* Responsive Video Element */
.RichContent-block .responsive-object {
  position: relative;
}

.RichContent-block .responsive-object iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}

.RichContent-block iframe {
  border-radius: var(--border-radius-card-large);
  overflow: hidden;
  width: 100%;
}

.RichContent-block .f-t-paragraph:first-of-type:not(:first-child) {
  margin-top: var(--space-020);
}
