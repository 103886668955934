.FormText-wrapper.has-icon {
  padding-left: var(--space-050);
}

.FormSelect-display.has-value.has-icon {
  padding-left: 0;
}

.FormText-icon {
  position: absolute;
  left: var(--space-010);
  top: var(--space-020);
}
