.MariaChat {
  max-width: 480px;
}

.MariaChat:first-child {
  margin-top: 0;
}

.MariaChat-avatar {
  width: 68px;
}

.MariaChat-avatar-img {
  height: 68px;
  width: 68px;
}

.MariaChat-message-wrapper {
  flex: 1;
}

.MariaChat-message {
  border-radius: 0 var(--border-radius-card-large)
    var(--border-radius-card-large) var(--border-radius-card-large);
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
}

.MariaChat-message:after {
  right: 100%;
  top: 0;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #ededed;
  border-top-color: #ededed;
  border-width: 5px;
}
