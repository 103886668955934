@media (max-width: 767px) {
  .StarRating {
    border-radius: 0;
  }
}

.StarRating.has-border {
  border-top-style: solid;
  border-top-width: 1px;
}

@media (min-width: 768px) {
  .StarRating-step1 {
    flex-direction: row;
  }

  .StarRating-stars {
    margin-left: var(--space-060);
  }
}
