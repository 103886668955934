.SalesAgentDashboardPage .f-wrap {
  padding-left: 16px;
  padding-right: 16px;
}

.SalesAgentDashboardPage button.CTA-block-primary.SADB-button-primary {
  color: var(--colour-white);
  background: var(--colour-success-100);
  border-color: var(--colour-success-100);
  box-shadow: 0 6px 18px -15px var(--colour-success-100),
    0 5px 30px -5px rgba(43, 208, 164, 0.6);
}

.SalesAgentDashboardPage,
.SADBSidebar {
  --SADB-green: #1e9575;
  --SADB-orange: #ffa366;
  --SADB-red: #f5375d;
  --SADB-blue: #5187c1;
  --SADB-purple: #a45dff;
  --SADB-pink: #e63bac;
  --SADB-FH-primary: var(--colour-primary-600);
}

.SADB-icon-stroke--green use {
  stroke: var(--SADB-green);
}

.SADB-icon-stroke--orange use {
  stroke: var(--SADB-orange);
}

.SADB-icon-stroke--red use {
  stroke: var(--SADB-red);
}

.SADB-icon-stroke--blue use {
  stroke: var(--SADB-blue);
}

.SADB-icon-stroke--purple use {
  stroke: var(--SADB-purple);
}

.SADB-icon-stroke--pink use {
  stroke: var(--SADB-pink);
}

@media (max-width: 1199px) {
  .SalesAgentDashboardPage {
    min-height: calc(100vh - var(--salesapp-navbar-height));
  }
}

@media (min-width: 1200px) {
  .SalesAgentDashboardPage {
    height: calc(100vh - var(--salesapp-navbar-height));
  }
}

/* This breakpoint handles rare case where we need it to add/remove padding from main content so on smaller desktop screens it still looks good. 1392px comes from 200px (sidenav) + 1160px (main content) + 32px (extra padding). It's done to maximize available space for content. If you find yourself adding more rules in this breakpoint, question yourself and this solution */
@media (min-width: 1392px) {
  .SalesAgentDashboardPage .f-wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .SADB-opportunity-detail-page-header {
    padding: 16px 0 0 0;
  }
  .SADB-opportunity-detail-page-contact-column {
    margin-right: 0;
  }
  .SADB-opportunity-details-highlights {
    margin-left: 0;
    margin-right: 0;
  }
}
