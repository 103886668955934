:root {
  /* colours: START */
  --colour-primary: var(--colour-blackberry-300);
  --colour-accent: var(--colour-mint-500);
  --colour-link: var(--colour-blueberry-text);
  --colour-mute: var(--colour-stone-500);
  --colour-white: #fff;
  --colour-black: #000;
  /* colours: END */

  /* elements/CTA: START */
  --cta-block-border-radius: var(--border-radius-cta);
  --cta-block-font-family: var(--fancy-font-family);
  --cta-block-primary-colour: var(--colour-primary);
  --cta-block-primary-box-shadow-with-opacity: 0 6px 18px -15px var(--colour-primary),
    0 5px 30px -5px rgba(134, 248, 144, 0.6);
  --cta-block-primary-hover-box-shadow: 0 6px 12px -8px var(--colour-black),
    0 5px 30px -5px rgba(134, 248, 144, 0.6);
  --cta-block-primary-hover-background: var(--colour-accent);
  --cta-block-primary-hover-transform: translateY(-1px);
  /* elements/CTA: END */

  /* elements/FormText: START */
  --form-text-border-radius: var(--border-radius-inputs);
  --form-text-border-colour: var(--colour-shades-060);
  /* elements/FormText: END */

  /* elements/FormTextArea: START */
  --form-text-area-border-radius: var(--border-radius-inputs);
  /* elements/FormText: END */

  /* elements/FormSlider: START */
  --form-slider-colour: var(--colour-link);
  /* elements/FormSlider: END */

  /* elements/FormCheckbox: START */
  --form-checkbox-off-border-colour: var(--colour-stone-100);
  --form-checkbox-off-background-colour: var(--colour-white);
  --form-checkbox-off-tick-colour: var(--colour-stone-100);
  --form-checkbox-on-border-colour: var(--colour-grape-500);
  --form-checkbox-on-background-colour: var(--colour-grape-500);
  --form-checkbox-on-tick-colour: var(--colour-stone-100);
  /* elements/FormCheckbox: START */

  /* elements/FormSelect: START */
  --form-select-box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  /* elements/FormSelect: END */

  /* widgets/FooterCTA: START */
  --footer-cta-color: var(--colour-primary);
  /* widgets/FooterCTA: END */

  /* applications/QuizApp: START */
  --progress-bar-step-active: var(--colour-grape-500);
  --progress-bar-step-visited: var(--colour-grape-100);
  --form-slider-value-tooltip-background: var(--colour-blueberry-500);
  --action-card-template-active-border-colour: var(--colour-grape-500);
  /* applications/QuizApp: END */
}
