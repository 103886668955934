.quote-callout__image.quote-callout__image--full {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}

.quote-callout__image.quote-callout__image--reduced {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}

.quote-callout__text-container {
  position: relative;
  border-radius: var(--border-radius-card-large);
}

.quote-callout__text-container.quote-callout__text-container--whi {
  border-color: transparent var(--colour-white) transparent transparent;
  background-color: var(--colour-white);
}

.quote-callout__text-container.quote-callout__text-container--bac015 {
  border-color: transparent var(--colour-background-015) transparent transparent;
  background-color: var(--colour-background-015);
}

.quote-callout__text-container.quote-callout__text-container--bac025 {
  border-color: transparent var(--colour-background-025) transparent transparent;
  background-color: var(--colour-background-025);
}

.quote-callout__text-container.quote-callout__text-container--bac050 {
  border-color: transparent var(--colour-background-050) transparent transparent;
  background-color: var(--colour-background-050);
}

.quote-callout__text-container.quote-callout__text-container--neu025 {
  border-color: transparent var(--colour-neutral-025) transparent transparent;
  background-color: var(--colour-neutral-025);
}

.quote-callout__text-container.quote-callout__text-container--neu050 {
  border-color: transparent var(--colour-neutral-050) transparent transparent;
  background-color: var(--colour-neutral-050);
}

.quote-callout__text-container.quote-callout__text-container--pa015 {
  border-color: transparent var(--colour-paper-015) transparent transparent;
  background-color: var(--colour-paper-015);
}

.quote-callout__text-container.quote-callout__text-container--pa025 {
  border-color: transparent var(--colour-paper-025) transparent transparent;
  background-color: var(--colour-paper-025);
}

.quote-callout__text-container:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 30px 0;
  border-color: inherit;
  border-radius: var(--border-radius-card-large);
  transform: rotate(0deg);
  position: absolute;
  top: 0;
  left: -24px;
}

.quote-callout__signature {
  font-style: italic;
}

@media (min-width: 768px) {
  .quote-callout__image.quote-callout__image--full {
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }

  .quote-callout__image.quote-callout__image--reduced {
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
}
