/* HLP Fertighaus: START */
.AnchorsNavigation-caret {
  margin-top: 6px;
  transition: transform 0.2s ease;
}
/* HLP Fertighaus: END */

/* Planoa and CMS Article pages: START */
.AnchorsNavigation {
  overflow: hidden;
  position: relative;
  min-height: 40px;
}

@media (max-width: 767px) {
  .AnchorsNavigation {
    min-height: 82px;
    padding: var(--space-020);
  }
}

@media (max-width: 767px) {
  .AnchorsNavigation-wrapper {
    flex-direction: column;
    border-radius: var(--border-radius-card-large);
    box-shadow: var(--action-card-box-shadow);
  }
}

.AnchorsNavigation-mobile-button {
  border: 0;
  outline: 0;
  background: none;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
  font-size: 16px;
  line-height: 1.5rem;
}

.AnchorsNavigation-mobile-button.is-visible .AnchorsNavigation-caret {
  transform: rotate(180deg);
}

.AnchorsNavigation-item {
  text-decoration: none;
}

@media (max-width: 767px) {
  .AnchorsNavigation-item {
    display: block;
    font-size: 14px;
    padding: var(--space-010) var(--space-005) var(--space-010) 0;
    text-align: left;
  }

  .AnchorsNavigation-item:last-child {
    margin-bottom: var(--space-010);
  }
}

@media (max-width: 767px) {
  .AnchorsNavigation-items {
    display: none;
  }

  .AnchorsNavigation-items.is-visible {
    display: block;
  }
}
/* Planoa and CMS Article pages: END */
